import React from 'react'
import {
  Button,
  Card,
  CardBody,
  Spinner
} from 'reactstrap'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import ExclamationMarkCircle from '../../../svgs/ExclamationMarkCircle'
import i18n from '../../../config/i18n'
import Header from './Header'
import history from '../../../helpers/History'
import ReferenceLanguages from '../../../components/ReferenceLanguages'
import SynchronizationStatus from './SynchronizationStatus'
import TranslationProgressWidget from './TranslationProgressWidget'

const ProjectCard = ({
  locales,
  onClickDelete,
  onClickEdit,
  onClickForceImport,
  projectId,
  projects,
  userRole
}) => {
  const project = projects.find((item) => item.id === projectId)

  const {
    reference_languages: referenceLanguages,
    state,
    strings_stats: stats,
    to_be_deleted: toBeDeleted
  } = project

  const projectLocales = locales.filter(
    (locale) => referenceLanguages.includes(locale.value)
  )

  return (
    <Card
      className={`mr-3 mb-3 ${toBeDeleted ? 'opacity-50' : ''}`}
      name="project"
      style={{ width: '250px', height: '450px' }}
    >
      <CardBody className="pb-0 flex-grow-0">
        <Header
          project={project}
          onClickDelete={() => onClickDelete(project)}
          onClickEdit={() => onClickEdit(project)}
          onClickForceImport={() => onClickForceImport(project)}
        />
      </CardBody>
      <CardBody className="d-flex flex-column justify-content-between pt-0">
        <div
          className={`d-flex flex-column flex-fill
                      align-items-center justify-content-center`}
        >
          {(['pending', 'importing'].includes(state) || toBeDeleted) && (
            <div className="d-flex flex-column align-items-center">
              <Spinner style={{ width: '5rem', height: '5rem' }} />
              {toBeDeleted ? (
                <div>
                  {i18n.t('ProjectsPage.ProjectCard.deletingProject')}
                </div>
              ) : (
                <SynchronizationStatus importStep={project.import_step} />
              )}
            </div>
          )}

          {state === 'importing_failed' && toBeDeleted === false && (
            <ExclamationMarkCircle opacity="0.4" size={12} />
          )}

          {['pending', 'importing', 'importing_failed'].includes(state) === false
            && (stats.missing_locale_strings > 0 || stats.conflicts > 0)
            && toBeDeleted === false
            && (
              <ExclamationMarkCircle opacity="0.4" size={12} />
            )}

          {state === 'importing_succeeded'
            && stats.missing_locale_strings === 0
            && stats.conflicts === 0
            && toBeDeleted === false
            && (
              <TranslationProgressWidget stats={stats} />
            )}
        </div>

        <ReferenceLanguages locales={projectLocales} />

        <div className="d-flex flex-column mt-3">
          <Button
            color="primary"
            disabled={(state !== 'importing_succeeded' && userRole !== 'admin') || toBeDeleted}
            onClick={() => history.push(`/projects/${project.id}`)}
            outline
          >
            {i18n.t('buttons.open')}
          </Button>
        </div>
      </CardBody>
    </Card>
  )
}

ProjectCard.propTypes = {
  locales: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired
  })).isRequired,
  onClickDelete: PropTypes.func.isRequired,
  onClickEdit: PropTypes.func.isRequired,
  onClickForceImport: PropTypes.func.isRequired,
  projects: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    import_step: PropTypes.string,
    reference_languages: PropTypes.arrayOf(
      PropTypes.string.isRequired
    ).isRequired,
    state: PropTypes.string.isRequired,
    strings_stats: PropTypes.shape().isRequired,
    to_be_deleted: PropTypes.bool
  })),
  projectId: PropTypes.string.isRequired,
  userRole: PropTypes.string
}

ProjectCard.defaultProps = {
  projects: null,
  userRole: null
}

const mapStateToProps = ({
  authentication: {
    role: userRole
  },
  locales: {
    items: locales
  },
  projects: {
    items: projects
  }
}) => ({
  locales,
  projects,
  userRole
})

export default connect(mapStateToProps)(ProjectCard)
