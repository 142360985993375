import React from 'react'
import { Doughnut } from 'react-chartjs-2'
import PropTypes from 'prop-types'

import ExclamationMarkCircle from '../../../svgs/ExclamationMarkCircle'
import i18n from '../../../config/i18n'

const TranslationProgressWidget = ({ stats }) => {
  const totalStrings = () => (
    stats.translated + stats.conflicts + stats.untranslated
  )

  const calcTranslatedPercentage = () => (
    Math.ceil((stats.translated / (totalStrings() / 100)))
  )

  const i18nBase = 'ProjectsPage.ProjectCard.TranslationProgressWidget'

  if (!stats) {
    return (
      <div className="d-flex flex-column align-items-center">
        <ExclamationMarkCircle opacity="0.4" size={6} />
        <span className="text-center text-danger">
          {i18n.t(`${i18nBase}.errors.invalidStrings`)}
        </span>
      </div>
    )
  }

  return (
    <section
      className="d-flex flex-column flex-fill py-4"
      name="translation-progress-widget"
    >
      <div className="d-flex flex-column" style={{ zIndex: 1 }}>
        <Doughnut
          data={{
            datasets: [{
              backgroundColor: [
                '#18bc9c',
                '#f39c12',
                '#eeeeee'
              ],
              borderWidth: [0, 0, 0],
              data: [
                stats.translated,
                stats.conflicts,
                stats.untranslated
              ]
            }],
            labels: [
              i18n.t(`${i18nBase}.labels.translated`),
              i18n.t(`${i18nBase}.labels.conflicts`),
              i18n.t(`${i18nBase}.labels.untranslated`)
            ]
          }}
          height="140px"
          options={{
            cutout: '85%',
            plugins: {
              legend: {
                display: false
              }
            }
          }}
          width="140px"
        />
      </div>
      <article
        className="position-absolute h2 text-center text-muted"
        name="progression-percentage"
        style={{
          top: 'calc(50% - 53px)',
          width: '140px',
          zIndex: 0
        }}
      >
        {totalStrings() === 0 ? (
          <span>{i18n.t(`${i18nBase}.empty`)}</span>
        ) : (
          <span>
            {calcTranslatedPercentage()}
            %
          </span>
        )}
      </article>
      <article className="text-center font-italic" name="total-strings">
        {i18n.t(`${i18nBase}.strings`, { count: totalStrings() })}
      </article>
    </section>
  )
}

TranslationProgressWidget.propTypes = {
  stats: PropTypes.shape({
    conflicts: PropTypes.number.isRequired,
    translated: PropTypes.number.isRequired,
    untranslated: PropTypes.number.isRequired
  }).isRequired
}

export default TranslationProgressWidget
