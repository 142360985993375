import React from 'react'

import ImportIssues from '../../PrivatePages/ProjectsPage/ImportIssues'
import ProjectPage from '../../PrivatePages/ProjectsPage/ProjectPage'
import ProjectsPage from '../../PrivatePages/ProjectsPage'
import LocalesPage from '../../PrivatePages/LocalesPage'
import LogoutPage from '../../PrivatePages/LogoutPage'
import NewProjectExportPage from '../../PrivatePages/ProjectsPage/ProjectExports/New'
import ProjectExportShowPage from '../../PrivatePages/ProjectsPage/ProjectExports/Show'
import ProjectUnusedStrings from '../../PrivatePages/ProjectsPage/ProjectUnusedStrings'
import SettingsPage from '../../PrivatePages/SettingsPage'
import TranslationsPage from '../../PrivatePages/TranslationsPage'
import UsersPage from '../../PrivatePages/UsersPage'

const PrivateRoutes = [
  {
    path: '/',
    component: () => <ProjectsPage />
  },
  {
    path: '/projects',
    component: () => <ProjectsPage />
  },
  {
    path: '/projects/:slug/',
    // eslint-disable-next-line react/jsx-props-no-spreading
    component: (props) => <ProjectPage {... props} />
  },
  {
    path: '/projects/:slug/exports/new',
    // eslint-disable-next-line react/jsx-props-no-spreading
    component: (props) => <NewProjectExportPage {... props} />
  },
  {
    path: '/projects/:slug/exports/:id',
    // eslint-disable-next-line react/jsx-props-no-spreading
    component: (props) => <ProjectExportShowPage {... props} />
  },
  {
    path: '/projects/:slug/issues',
    // eslint-disable-next-line react/jsx-props-no-spreading
    component: (props) => <ImportIssues {... props} />
  },
  {
    path: '/projects/:slug/locales/:locale/project_strings',
    // eslint-disable-next-line react/jsx-props-no-spreading
    component: (props) => <TranslationsPage {... props} />
  },
  {
    path: '/projects/:slug/unused-strings',
    // eslint-disable-next-line react/jsx-props-no-spreading
    component: (props) => <ProjectUnusedStrings {... props} />
  },
  {
    path: '/locales',
    component: () => <LocalesPage />
  },
  {
    path: '/logout',
    component: () => <LogoutPage />
  },
  {
    path: '/settings',
    component: () => <SettingsPage />
  },
  {
    path: '/users',
    component: () => <UsersPage />
  }
]

export default PrivateRoutes
