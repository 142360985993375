import React from 'react'
import PropTypes from 'prop-types'

import FormInputField from './FormInputField'
import i18n from '../config/i18n'

class PasswordField extends React.Component {
  showAsInvalid(message = null) {
    this.componentField.showAsInvalid(message)
  }

  render() {
    const {
      inputId,
      inputName,
      labelText,
      onChange,
      value
    } = this.props

    return (
      <FormInputField
        inputId={inputId}
        inputName={inputName}
        inputType="password"
        labelText={labelText || i18n.t('devise.fields.password')}
        onChange={(event) => onChange(event)}
        ref={(component) => { this.componentField = component }}
        value={value}
      />
    )
  }
}

PasswordField.propTypes = {
  inputId: PropTypes.string.isRequired,
  inputName: PropTypes.string.isRequired,
  labelText: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired
}

PasswordField.defaultProps = {
  labelText: null
}

export default PasswordField
