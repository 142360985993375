import React from 'react'
import {
  Badge,
  CardText,
  Label
} from 'reactstrap'
import PropTypes from 'prop-types'
import { v1 as uuidv1 } from 'uuid'

import i18n from '../config/i18n'

const ReferenceLanguages = ({ badgesColor, locales, withoutLabel }) => (
  <CardText tag="div">
    <div className="d-flex flex-column">
      {withoutLabel || (
        <Label>
          {i18n.t('ReferenceLanguages.locales')}
        </Label>
      )}

      <div className="d-flex flex-row">
        {locales.map((locale) => (
          <Badge
            className="mr-1"
            color={badgesColor}
            key={uuidv1()}
          >
            {locale.label}
          </Badge>
        ))}
      </div>
    </div>
  </CardText>
)

ReferenceLanguages.propTypes = {
  badgesColor: PropTypes.string,
  locales: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired
  })).isRequired,
  withoutLabel: PropTypes.bool
}

ReferenceLanguages.defaultProps = {
  badgesColor: 'primary',
  withoutLabel: false
}

export default ReferenceLanguages
