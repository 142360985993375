import React from 'react'
import {
  Button,
  Form,
  FormFeedback,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from 'reactstrap'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Select from 'react-select'

import FormInputField from '../../components/FormInputField'
import i18n from '../../config/i18n'
import projectsActions from './actions'

class ProjectEditModal extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      id: null,
      name: '',
      nameIsAvailable: false,
      nameUpdated: false,
      noSelectedLanguages: false,
      paths: '',
      referenceLanguages: null
    }
  }

  componentDidUpdate(prevProps) {
    const {
      isOpen: prevIsOpen,
      nameCheckingStatus: prevNameCheckingStatus,
      updateStatus: prevUpdateStatus
    } = prevProps

    const {
      isOpen,
      nameCheckingError,
      nameCheckingStatus,
      updateStatus
    } = this.props

    if (prevIsOpen === false && isOpen) this.updateStateWithProject()

    if (isOpen === false) return

    if (prevNameCheckingStatus === 'checking') {
      if (nameCheckingStatus === 'failed') {
        this.nameField.showAsInvalid(nameCheckingError)
      }

      this.updateNameAvailabilityStatus(nameCheckingStatus)
    }

    if (prevUpdateStatus === 'updating' && updateStatus === 'succeeded') {
      this.toggle()
    }
  }

  handleChange({ target: { name, value } }) {
    this.setState({
      [name]: value,
      nameUpdated: [name] === 'name'
    })
  }

  onLocalesSelect(selected) {
    this.setState({
      noSelectedLanguages: false,
      referenceLanguages: selected
    })
  }

  onSubmit(event) {
    const { dispatch } = this.props

    const {
      id,
      name,
      nameIsAvailable,
      nameUpdated,
      paths,
      referenceLanguages
    } = this.state

    event.preventDefault()

    if (name === '') this.nameField.showAsInvalid()

    if (referenceLanguages.length === 0) {
      this.setState({
        noSelectedLanguages: true
      })
    }

    if (paths === '') this.pathsField.showAsInvalid()

    if (
      ((name && nameIsAvailable) || nameUpdated === false)
      && referenceLanguages.length > 0 && paths
    ) {
      const output = referenceLanguages.flatMap((item) => item.value)

      dispatch(projectsActions.update(id, output, name, paths))
    }
  }

  checkNameAvailablity() {
    const { dispatch, project: { id } } = this.props
    const { name } = this.state

    if (name !== '') dispatch(projectsActions.checkNameAvailablity(name, id))
  }

  toggle() {
    const { toggle } = this.props

    this.setState({
      id: null,
      name: '',
      nameUpdated: false,
      noSelectedLanguages: false,
      paths: '',
      referenceLanguages: null
    }, toggle())
  }

  updateNameAvailabilityStatus(status) {
    const { nameIsAvailable } = this.state

    if (nameIsAvailable === (status === 'succeeded')) return

    this.setState({
      nameIsAvailable: status === 'succeeded'
    })
  }

  updateStateWithProject() {
    const {
      locales,
      project: {
        id,
        name,
        paths,
        reference_languages: referenceLanguages
      }
    } = this.props

    const projectReferenceLanguages = locales.filter(
      (locale) => referenceLanguages.includes(locale.value)
    )

    this.setState({
      id,
      name,
      noSelectedLanguages: false,
      paths,
      referenceLanguages: projectReferenceLanguages
    })
  }

  render() {
    const { isOpen, locales } = this.props
    const {
      name,
      paths,
      noSelectedLanguages,
      referenceLanguages
    } = this.state

    const i18nBase = 'ProjectsPage.ProjectEditModal'

    const noOptionsMessage = () => (
      <p>
        {i18n.t('ProjectsPage.labels.noOptions')}
        <Link
          className="text-secondary font-weight-bold"
          name="create-locales"
          to="/locales"
        >
          {i18n.t('ProjectsPage.labels.noOptionsLink')}
        </Link>
      </p>
    )

    return (
      <Modal isOpen={isOpen} toggle={() => this.toggle()}>
        <Form id="edit">
          <ModalHeader toggle={() => this.toggle()}>
            {i18n.t(`${i18nBase}.title`)}
          </ModalHeader>
          <ModalBody className="d-flex flex-column">
            <FormInputField
              inputId="name"
              inputName="name"
              inputType="text"
              labelText={i18n.t('ProjectsPage.fields.name')}
              onChange={(event) => this.handleChange(event)}
              onBlur={() => this.checkNameAvailablity()}
              ref={(component) => { this.nameField = component }}
              value={name}
            />
            <FormGroup className="position-relative">
              <Label for="locales">
                {i18n.t('ProjectsPage.fields.locales')}
              </Label>
              <Select
                id="locales"
                isMulti
                onChange={(locale) => this.onLocalesSelect(locale)}
                openMenuOnFocus
                options={locales}
                noOptionsMessage={() => noOptionsMessage()}
                placeholder={i18n.t('ProjectsPage.fields.localesPlaceholder')}
                value={referenceLanguages}
              />
              {noSelectedLanguages && (
                <FormFeedback className="d-block z-0" tooltip>
                  {i18n.t('errors.required_field')}
                </FormFeedback>
              )}
            </FormGroup>
            <FormInputField
              inputFormtext={i18n.t('ProjectsPage.fields.pathsExamples')}
              inputId="paths"
              inputName="paths"
              inputPlaceholder={i18n.t('ProjectsPage.fields.pathsPlaceholder')}
              inputType="text"
              labelText={i18n.t('ProjectsPage.fields.paths')}
              onChange={(event) => this.handleChange(event)}
              ref={(component) => { this.pathsField = component }}
              value={paths}
            />
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => this.toggle()}>
              {i18n.t('buttons.cancel')}
            </Button>
            <Button
              color="primary"
              onClick={(event) => this.onSubmit(event)}
            >
              {i18n.t('buttons.save')}
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    )
  }
}

ProjectEditModal.propTypes = {
  dispatch: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  locales: PropTypes.arrayOf(PropTypes.object),
  nameCheckingError: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  project: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    paths: PropTypes.string,
    reference_languages: PropTypes.arrayOf(PropTypes.string)
  }).isRequired,
  nameCheckingStatus: PropTypes.string,
  toggle: PropTypes.func.isRequired,
  updateStatus: PropTypes.string
}

ProjectEditModal.defaultProps = {
  locales: null,
  nameCheckingError: null,
  nameCheckingStatus: 'idle',
  updateStatus: 'idle'
}

const mapStateToProps = ({
  locales: {
    items: locales
  },
  projects: {
    nameCheckingError,
    nameCheckingStatus,
    updateStatus
  }
}) => ({
  locales,
  nameCheckingError,
  nameCheckingStatus,
  updateStatus
})

export default connect(mapStateToProps)(ProjectEditModal)
