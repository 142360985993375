import React from 'react'
import { FormFeedback, FormGroup, Label } from 'reactstrap'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import Select from 'react-select'

import FormInputField from '../../../components/FormInputField'
import PageLoader from '../../../components/PageLoader'
import i18n from '../../../config/i18n'

class Step1 extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      noSelectedLanguages: false
    }
  }

  hideNameAsChecking() {
    this.nameField.hideBusy()
  }

  showNameAsChecking() {
    this.nameField.showAsBusy()
  }

  showNameAsInvalid(message = null) {
    this.nameField.showAsInvalid(message)
  }

  showLocalesAsInvalid() {
    this.setState({
      noSelectedLanguages: true
    })
  }

  showLocalesAsValid() {
    this.setState({
      noSelectedLanguages: false
    })
  }

  render() {
    const {
      localeList,
      locales,
      name,
      onChange,
      onLocalesSelect,
      onNameFieldBlur
    } = this.props

    const { noSelectedLanguages } = this.state

    const i18nBase = 'ProjectsPage.ProjectCreationModal.Step1'

    if (localeList === null) {
      return (
        <PageLoader message={i18n.t(`${i18nBase}.messages.fetchingData`)} />
      )
    }

    const noOptionsMessage = () => (
      <p>
        {i18n.t('ProjectsPage.labels.noOptions')}
        <Link
          className="text-secondary font-weight-bold"
          name="create-locales"
          to="/locales"
        >
          {i18n.t('ProjectsPage.labels.noOptionsLink')}
        </Link>
      </p>
    )

    return (
      <>
        <FormInputField
          inputId="name"
          inputName="name"
          inputType="text"
          labelText={i18n.t('ProjectsPage.fields.name')}
          onChange={(event) => onChange(event)}
          onBlur={() => onNameFieldBlur()}
          inputPlaceholder={i18n.t('ProjectsPage.fields.namePlaceholder')}
          ref={(component) => { this.nameField = component }}
          value={name}
        />
        <FormGroup className="position-relative">
          <Label for="locales">
            {i18n.t('ProjectsPage.fields.locales')}
          </Label>
          <Select
            id="locales"
            isMulti
            onChange={(locale) => onLocalesSelect(locale)}
            openMenuOnFocus
            options={localeList}
            noOptionsMessage={() => noOptionsMessage()}
            placeholder={i18n.t('ProjectsPage.fields.localesPlaceholder')}
            value={locales}
          />
          {noSelectedLanguages && (
            <FormFeedback className="d-block z-0" tooltip>
              {i18n.t('errors.required_field')}
            </FormFeedback>
          )}
        </FormGroup>
      </>
    )
  }
}

Step1.propTypes = {
  localeList: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  })),
  locales: PropTypes.arrayOf(PropTypes.object),
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onLocalesSelect: PropTypes.func.isRequired,
  onNameFieldBlur: PropTypes.func.isRequired
}

Step1.defaultProps = {
  localeList: null,
  locales: null
}

export default Step1
