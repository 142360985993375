import dotProp from 'dot-prop-immutable'

import actionCableConstants from '../../ActionCable/constants'
import projectsConstants from './constants'

const initialState = {
  creationError: null,
  creationStatus: 'idle',

  fetchingError: null,
  fetchingStatus: 'idle',

  fetchingAllError: null,
  fetchingAllStatus: 'idle',

  fixingAlertError: null,
  fixingAlertStatus: 'idle',

  forceImportError: null,
  forceImportStatus: 'idle',

  nameCheckingError: null,
  nameCheckingStatus: 'idle',

  item: null,

  items: null,

  refreshingStatsError: null,
  refreshingStatsStatus: 'idle',

  removingError: null,
  removingStatus: 'idle',

  updateError: null,
  updateStatus: 'idle'
}

const insertOrUpdate = (state, project) => {
  const itemIndex = (state.items || []).findIndex(
    (item) => item.id === project.id
  )
  if (itemIndex === -1) {
    return dotProp.set(state, 'items', [...state.items || [], project])
  }

  return dotProp.set(state, `items.${itemIndex}`, project)
}

export default (state = initialState, action) => {
  const error = action.error ? (action.error.data || action.error.message) : null
  let newState

  switch (action.type) {
    case actionCableConstants.DATA_RECEIVED_FROM_CHANNEL:
      if (action.channelName === 'ProjectsChannel') {
        if (action.data.destroyed_id) {
          const index = state.items.findIndex(
            (item) => item.id === action.data.destroyed_id
          )

          if (index > -1) return dotProp.delete(state, `items.${index}`)
        }

        return insertOrUpdate(state, action.data)
      }

      if (action.channelName === 'ProjectStatsChannel') {
        newState = state

        if (state.items) {
          const index = state.items.findIndex((item) => item.id === action.id)
          if (index > -1) {
            newState = dotProp.set(
              state,
              `items.${index}.strings_stats`,
              action.data
            )
          }
        }

        if (state.item?.id === action.id) {
          newState = dotProp.set(newState, 'item.strings_stats', action.data)
        }

        return dotProp.set(newState, 'refreshingStatsStatus', 'succeeded')
      }

      return state

    case projectsConstants.CHECK_NAME_AVAILABILITY_REQUEST:
      newState = dotProp.set(state, 'nameCheckingError', null)
      return dotProp.set(newState, 'nameCheckingStatus', 'checking')
    case projectsConstants.CHECK_NAME_AVAILABILITY_FAILURE:
      newState = dotProp.set(state, 'nameCheckingError', error)
      return dotProp.set(newState, 'nameCheckingStatus', 'failed')
    case projectsConstants.CHECK_NAME_AVAILABILITY_SUCCESS:
      newState = dotProp.set(state, 'nameCheckingError', null)
      return dotProp.set(newState, 'nameCheckingStatus', 'succeeded')

    case projectsConstants.CREATE_REQUEST:
      newState = dotProp.set(state, 'creationError', null)
      return dotProp.set(newState, 'creationStatus', 'creating')
    case projectsConstants.CREATE_FAILURE:
      newState = dotProp.set(state, 'creationError', error)
      return dotProp.set(newState, 'creationStatus', 'failed')
    case projectsConstants.CREATE_SUCCESS:
      newState = dotProp.set(state, 'creationError', null)
      newState = dotProp.set(newState, 'creationStatus', 'succeeded')
      return insertOrUpdate(newState, action.project)

    case projectsConstants.ENQUEUE_STATS_REFRESH_REQUEST:
      newState = dotProp.set(state, 'refreshingStatsError', null)
      return dotProp.set(newState, 'refreshingStatsStatus', 'refreshing')
    case projectsConstants.ENQUEUE_STATS_REFRESH_FAILURE:
      newState = dotProp.set(state, 'refreshingStatsError', error)
      return dotProp.set(newState, 'refreshingStatsStatus', 'failed')
    case projectsConstants.ENQUEUE_STATS_REFRESH_SUCCESS:
      newState = dotProp.set(state, 'refreshingStatsError', null)
      return dotProp.set(newState, 'refreshingStatsStatus', 'enqueued')

    case projectsConstants.FETCH_REQUEST:
      newState = dotProp.set(state, 'fetchingError', null)
      return dotProp.set(newState, 'fetchingStatus', 'fetching')
    case projectsConstants.FETCH_FAILURE:
      newState = dotProp.set(state, 'fetchingError', error)
      return dotProp.set(newState, 'fetchingAllStatus', 'failed')
    case projectsConstants.FETCH_SUCCESS:
      newState = dotProp.set(state, 'fetchingError', null)
      newState = dotProp.set(newState, 'fetchingStatus', 'succeeded')
      return dotProp.set(newState, 'item', action.project)

    case projectsConstants.FETCH_ALL_REQUEST:
      newState = dotProp.set(state, 'fetchingAllError', null)
      return dotProp.set(newState, 'fetchingAllStatus', 'fetching')
    case projectsConstants.FETCH_ALL_FAILURE:
      newState = dotProp.set(state, 'fetchingAllError', error)
      return dotProp.set(newState, 'fetchingAllStatus', 'failed')
    case projectsConstants.FETCH_ALL_SUCCESS:
      newState = dotProp.set(state, 'fetchingAllError', null)
      newState = dotProp.set(newState, 'fetchingAllStatus', 'succeeded')
      return dotProp.set(newState, 'items', action.projects)

    case projectsConstants.FIXES_ALERT_REQUEST:
      newState = dotProp.set(state, 'fixingAlertError', null)
      return dotProp.set(newState, 'fixingAlertStatus', 'fixing')
    case projectsConstants.FIXES_ALERT_FAILURE:
      newState = dotProp.set(state, 'fixingAlertError', error)
      return dotProp.set(newState, 'fixingAlertStatus', 'failed')
    case projectsConstants.FIXES_ALERT_SUCCESS:
      newState = dotProp.set(state, 'fixingAlertError', null)
      newState = dotProp.set(newState, 'fixingAlertStatus', 'succeeded')
      return dotProp.set(newState, 'item', action.project)

    case projectsConstants.FORCE_IMPORT_REQUEST:
      newState = dotProp.set(state, 'forceImportError', null)
      return dotProp.set(newState, 'forceImportStatus', 'importing')
    case projectsConstants.FORCE_IMPORT_FAILURE:
      newState = dotProp.set(state, 'forceImportError', error)
      return dotProp.set(newState, 'forceImportStatus', 'failed')
    case projectsConstants.FORCE_IMPORT_SUCCESS:
      newState = dotProp.set(state, 'forceImportError', null)
      return dotProp.set(newState, 'forceImportStatus', 'succeeded')

    case projectsConstants.REMOVE_REQUEST:
      newState = dotProp.set(state, 'removingError', null)
      return dotProp.set(newState, 'removingStatus', 'removing')
    case projectsConstants.REMOVE_FAILURE:
      newState = dotProp.set(state, 'removingError', error)
      return dotProp.set(newState, 'removingStatus', 'failed')
    case projectsConstants.REMOVE_SUCCESS:
      newState = dotProp.set(state, 'removingError', null)
      return dotProp.set(newState, 'removingStatus', 'succeeded')

    case projectsConstants.UPDATE_REQUEST:
      newState = dotProp.set(state, 'updateError', null)
      return dotProp.set(newState, 'updateStatus', 'updating')
    case projectsConstants.UPDATE_FAILURE:
      newState = dotProp.set(state, 'updateError', error)
      return dotProp.set(newState, 'updateStatus', 'failed')
    case projectsConstants.UPDATE_SUCCESS: {
      newState = dotProp.set(state, 'updateError', null)
      newState = dotProp.set(newState, 'updateStatus', 'succeeded')

      const { project } = action
      const { id } = project

      const index = state.items.findIndex((item) => item.id === id)
      return dotProp.set(newState, `items.${index}`, project)
    }

    default:
      return state
  }
}
