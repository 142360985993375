import React from 'react'
import i18n from '../../../config/i18n'

const TableHeaderProjectStringToRemove = () => (
  <tr>
    <th>{i18n.t('ProjectsPage.TableHeaderProjectStringToRemove.key')}</th>
    <th>{i18n.t('ProjectsPage.TableHeaderProjectStringToRemove.path')}</th>
    <th>{i18n.t('ProjectsPage.TableHeaderProjectStringToRemove.locale')}</th>
    <th>{i18n.t('ProjectsPage.TableHeaderProjectStringToRemove.value')}</th>
    <th>{i18n.t('ProjectsPage.TableHeaderProjectStringToRemove.action')}</th>
  </tr>
)

export default TableHeaderProjectStringToRemove
