import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import actionCableActions from '../actions'

class ProjectExportsChannel extends React.Component {
  constructor(props) {
    super(props)

    this.CHANNEL_NAME = 'ProjectExportsChannel'
  }

  componentDidMount() {
    const { consumer, id, projectId } = this.props

    this.channel = consumer.subscriptions.create({
      channel: this.CHANNEL_NAME,
      id,
      project_id: projectId
    }, {
      connected: () => this.onConnected(),
      disconnected: () => this.onDisconnected(),
      received: (data) => this.onDataReceived(data)
    })
  }

  componentWillUnmount() {
    if (this.channel) this.channel.unsubscribe()
  }

  onConnected() {
    const { dispatch } = this.props

    dispatch(actionCableActions.connectedTo(this.CHANNEL_NAME))
  }

  onDataReceived(data) {
    const { dispatch, id } = this.props

    dispatch(actionCableActions.dataReceivedFrom(
      this.CHANNEL_NAME,
      data,
      id
    ))
  }

  onDisconnected() {
    const { dispatch } = this.props

    dispatch(actionCableActions.disconnectedFrom(this.CHANNEL_NAME))
  }

  render() {
    return null
  }
}

ProjectExportsChannel.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  consumer: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired
}

export default connect()(ProjectExportsChannel)
