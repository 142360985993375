import React from 'react'
import { connect } from 'react-redux'
import { Button, Container } from 'reactstrap'
import PropTypes from 'prop-types'

import AlertMessage from '../../components/AlertMessage'
import i18n from '../../config/i18n'
import localesActions from '../../localez/actions'
import LocalTranslationTable from './LocalTranslationTable'
import PageHeader from '../../components/PageHeader'
import PageLoader from '../../components/PageLoader'
import projectActions from '../ProjectsPage/actions'
import translationsActions from './actions'

class TranslationsPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      pendingTranslationCount: 0,
      updateStatus: 'idle',
      updateStatusAlertIsOpen: false,
      // Stores the updated strings, sent to the server on clicking the "Save"
      // button.
      updatedStrings: {}
    }
  }

  componentDidMount() {
    const {
      dispatch,
      match: { params: { slug } }
    } = this.props

    dispatch(projectActions.fetch(slug))
    dispatch(localesActions.fetchAll())
  }

  componentDidUpdate(prevProps) {
    const { updatingStatus: prevUpdatingStatus } = prevProps
    const { updatingStatus } = this.props

    if (['idle', 'failed', 'succeeded'].includes(prevUpdatingStatus)
        && updatingStatus === 'updating'
    ) {
      this.updateUpdateStatusTo('updating')
    }

    if (prevUpdatingStatus === 'updating' && updatingStatus === 'failed') {
      this.updateUpdateStatusTo('failed')
    }

    if (prevUpdatingStatus === 'updating' && updatingStatus === 'succeeded') {
      this.updateUpdateStatusTo('succeeded')
    }
  }

  onTranslationBlur() {
    const { translations } = this.props
    const { updatedStrings } = this.state

    /*
    ** Removes untouched strings
    *
    *  When a user updates a translation, `onTranslationChange` will add an
    *  entry in the `state.updatedStrings`. But when the user reverts his change
    *  then this code removes the string from the list of updated strings
    *  avoiding to mark it as updated and therefore excluding it from the export
    *  diff page.
    */
    const nextUpdatedStrings = { ...updatedStrings }

    Object.keys(updatedStrings).forEach((stringId) => {
      const translation = translations.find((item) => item.id === stringId)

      if (translation && translation.value === updatedStrings[stringId]) {
        delete nextUpdatedStrings[stringId]
      }
    })

    this.setState({
      pendingTranslationCount: Object.keys(nextUpdatedStrings).length,
      updatedStrings: nextUpdatedStrings
    })
  }

  onTranslationChange(value, id) {
    this.setState((prevState) => ({
      updatedStrings: {
        ...prevState.updatedStrings,
        [id]: value
      }
    }))
  }

  hideUpdateStatusAlert() {
    this.setState({
      updateStatus: 'idle',
      updateStatusAlertIsOpen: false
    })
  }

  updateTranslations() {
    const { updatedStrings } = this.state

    const {
      dispatch,
      match: { params: { slug, locale } }
    } = this.props

    dispatch(translationsActions.update(updatedStrings, locale, slug))
  }

  updateUpdateStatusTo(updateStatus) {
    this.setState({
      updateStatus,
      updateStatusAlertIsOpen: ['failed', 'succeeded'].includes(updateStatus)
    }, () => {
      if (['failed', 'succeeded'].includes(updateStatus)) {
        setTimeout(() => this.hideUpdateStatusAlert(), 3000)
      }
    })
  }

  render() {
    const {
      localesFetchingError,
      localesFetchingStatus,
      locales,
      location,
      match: { params: { slug, locale: currentLocale } },
      project,
      projectFetchingError,
      projectFetchingStatus,
      updatingError
    } = this.props

    const {
      pendingTranslationCount,
      updatedStrings,
      updateStatus,
      updateStatusAlertIsOpen
    } = this.state

    const i18nBase = 'TranslationsPage'

    if (['idle', 'fetching'].includes(projectFetchingStatus)) {
      return (
        <div className="d-flex flex-column flex-fill justify-content-center align-items-center">
          <PageLoader message={i18n.t('ProjectsPage.fetching')} />
        </div>
      )
    }

    if (localesFetchingStatus === 'failed'
      || projectFetchingStatus === 'failed') {
      return (
        <div className="d-flex flex-column">
          <AlertMessage>
            {
              projectFetchingError
              || localesFetchingError
            }
          </AlertMessage>
        </div>
      )
    }

    return (
      <Container fluid className="d-flex flex-column flex-fill mt-3">
        <div className="d-flex flex-column">
          <div className="d-flex flex-row justify-content-between">
            <div className="d-flex flex-column">
              <PageHeader
                projectSlug={slug}
                title={i18n.t(`${i18nBase}.title`)}
                withoutHr
              />
            </div>

            <Button
              className="w-15"
              color="success"
              disabled={pendingTranslationCount === 0}
              onClick={() => this.updateTranslations()}
              outline
            >
              {i18n.t(`${i18nBase}.buttons.save`, {
                count: pendingTranslationCount
              })}
            </Button>
          </div>

          <hr className="w-100" />
        </div>

        {['idle', 'fetching'].includes(localesFetchingStatus) && (
          <div className="d-flex flex-column flex-fill justify-content-center align-items-center">
            <PageLoader message={i18n.t('ProjectsPage.fetchingLocales')} />
          </div>
        )}

        {localesFetchingStatus === 'succeeded' && (
          <>
            <div className="d-flex flex-row justify-content-between">
              <h3>
                {locales.find((item) => item.value === currentLocale).label}
              </h3>

              {updateStatus === 'updating' && (
                <div className="d-absolute">
                  <PageLoader message={i18n.t(`${i18nBase}.savingTranslations`)} />
                </div>
              )}

              {updateStatus === 'failed' && (
                <AlertMessage
                  isOpen={updateStatusAlertIsOpen}
                  onDismiss={() => this.hideUpdateStatusAlert()}
                >
                  {typeof updatingError === 'string'
                    ? updatingError
                    : i18n.t('errors.somethingWentWrong')}
                </AlertMessage>
              )}

              {updateStatus === 'succeeded' && (
                <AlertMessage
                  color="success"
                  isOpen={updateStatusAlertIsOpen}
                  onDismiss={() => this.hideUpdateStatusAlert()}
                >
                  {i18n.t(`${i18nBase}.stringsSuccessfullySaved`)}
                </AlertMessage>
              )}
            </div>

            <LocalTranslationTable
              currentLocale={currentLocale}
              location={location}
              onTranslationBlur={() => this.onTranslationBlur()}
              onTranslationChange={
                (value, id) => this.onTranslationChange(value, id)
              }
              projectSlug={slug}
              referenceLanguages={project.reference_languages}
              updatedStrings={updatedStrings}
            />
          </>
        )}
      </Container>
    )
  }
}

TranslationsPage.propTypes = {
  dispatch: PropTypes.func.isRequired,
  locales: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired
  })),
  localesFetchingError: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  localesFetchingStatus: PropTypes.string,
  location: PropTypes.shape({}),
  match: PropTypes.shape({
    params: PropTypes.shape({
      locale: PropTypes.string,
      slug: PropTypes.string
    })
  }),
  project: PropTypes.shape({
    id: PropTypes.string.isRequired,
    locales: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    name: PropTypes.string.isRequired,
    reference_languages: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired
  }),
  projectFetchingError: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  projectFetchingStatus: PropTypes.string,
  translations: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.bool,
      PropTypes.number,
      PropTypes.string
    ])
  })),
  updatingError: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  updatingStatus: PropTypes.string
}

TranslationsPage.defaultProps = {
  locales: null,
  localesFetchingError: null,
  localesFetchingStatus: 'idle',
  location: {},
  match: {
    params: {
      locale: null,
      slug: null
    }
  },
  project: null,
  projectFetchingError: null,
  projectFetchingStatus: 'idle',
  translations: null,
  updatingError: null,
  updatingStatus: 'idle'
}

const mapStateToProps = ({
  locales: {
    items: locales,
    fetchingError: localesFetchingError,
    fetchingStatus: localesFetchingStatus
  },
  projects: {
    fetchingError: projectFetchingError,
    fetchingStatus: projectFetchingStatus,
    item: project
  },
  translations: {
    items: translations,
    updatingError,
    updatingStatus
  }
}) => ({
  locales,
  localesFetchingError,
  localesFetchingStatus,
  project,
  projectFetchingError,
  projectFetchingStatus,
  translations,
  updatingError,
  updatingStatus
})

export default connect(mapStateToProps)(TranslationsPage)
