import React from 'react'
import { connect } from 'react-redux'
import {
  Button,
  Form,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner
} from 'reactstrap'
import PropTypes from 'prop-types'

import AlertMessage from '../../../components/AlertMessage'
import projectExportsActions from './actions'
import i18n from '../../../config/i18n'

class ProjectExportRetryConfirmationModal extends React.Component {
  componentDidUpdate(prevProps) {
    const { retryStatus: prevretryStatus } = prevProps
    const { onClose, retryStatus } = this.props

    if (prevretryStatus === 'retrying' && retryStatus === 'succeeded') onClose()
  }

  handleSubmit(event) {
    const { dispatch, projectExportId, projectId } = this.props

    event.preventDefault()

    dispatch(projectExportsActions.retry(projectId, projectExportId))
  }

  render() {
    const {
      isOpen,
      onClose,
      retryError,
      retryStatus
    } = this.props

    const i18nBase = 'ProjectsPage.ProjectExports.ProjectExportRetryConfirmationModal'

    return (
      <Modal isOpen={isOpen} toggle={() => onClose()}>
        <Form>
          <ModalHeader toggle={() => onClose()}>
            {i18n.t(`${i18nBase}.title`)}
          </ModalHeader>
          <ModalBody>
            <div className="d-flex flex-column">
              <p>{i18n.t(`${i18nBase}.bodyMessage`)}</p>
            </div>

            {retryStatus === 'failed' && (
              <AlertMessage>{retryError}</AlertMessage>
            )}
          </ModalBody>
          <ModalFooter>
            <div className="d-flex flex-row flex-fill justify-content-between">
              <div className="d-flex flex-row">
                {retryStatus === 'retrying' && (
                  <>
                    <Spinner className="text-secondary" />
                    <div className="d-flex flex-column justify-content-center">
                      <span className="ml-3">{i18n.t(`${i18nBase}.retrying`)}</span>
                    </div>
                  </>
                )}
              </div>

              <div className="d-flex flex-row">
                <Button
                  className="mr-2"
                  color="secondary"
                  onClick={() => onClose()}
                >
                  {i18n.t('buttons.cancel')}
                </Button>
                <Button
                  color="primary"
                  onClick={(event) => this.handleSubmit(event)}
                  type="submit"
                >
                  {i18n.t('buttons.continue')}
                </Button>
              </div>
            </div>
          </ModalFooter>
        </Form>
      </Modal>
    )
  }
}

ProjectExportRetryConfirmationModal.propTypes = {
  retryError: PropTypes.string,
  retryStatus: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  projectExportId: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired
}

ProjectExportRetryConfirmationModal.defaultProps = {
  retryError: null,
  retryStatus: 'idle',
  isOpen: false
}

const mapStateToProps = ({
  projectExports: {
    retryError,
    retryStatus
  }
}) => ({
  retryError,
  retryStatus
})

export default connect(mapStateToProps)(ProjectExportRetryConfirmationModal)
