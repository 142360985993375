export default {
  ACCEPT_REQUEST: 'PROJECT_STRINGS_ALERTS_ACCEPT_REQUEST',
  ACCEPT_FAILURE: 'PROJECT_STRINGS_ALERTS_ACCEPT_FAILURE',
  ACCEPT_SUCCESS: 'PROJECT_STRINGS_ALERTS_ACCEPT_SUCCESS',

  CHECK_IF_ANY_REQUEST: 'PROJECT_STRINGS_ALERTS_CHECK_IF_ANY_REQUEST',
  CHECK_IF_ANY_FAILURE: 'PROJECT_STRINGS_ALERTS_CHECK_IF_ANY_FAILURE',
  CHECK_IF_ANY_SUCCESS: 'PROJECT_STRINGS_ALERTS_CHECK_IF_ANY_SUCCESS',

  FETCH_ALL_REQUEST: 'PROJECT_STRINGS_ALERTS_FETCH_ALL_REQUEST',
  FETCH_ALL_FAILURE: 'PROJECT_STRINGS_ALERTS_FETCH_ALL_FAILURE',
  FETCH_ALL_SUCCESS: 'PROJECT_STRINGS_ALERTS_FETCH_ALL_SUCCESS',

  FILTER_REQUEST: 'PROJECT_STRINGS_ALERTS_FILTER_REQUEST',
  FILTER_FAILURE: 'PROJECT_STRINGS_ALERTS_FILTER_FAILURE',
  FILTER_SUCCESS: 'PROJECT_STRINGS_ALERTS_FILTER_SUCCESS',

  KEEP_REQUEST: 'PROJECT_STRINGS_ALERTS_KEEP_REQUEST',
  KEEP_FAILURE: 'PROJECT_STRINGS_ALERTS_KEEP_FAILURE',
  KEEP_SUCCESS: 'PROJECT_STRINGS_ALERTS_KEEP_SUCCESS',

  REJECT_REQUEST: 'PROJECT_STRINGS_ALERTS_REJECT_REQUEST',
  REJECT_FAILURE: 'PROJECT_STRINGS_ALERTS_REJECT_FAILURE',
  REJECT_SUCCESS: 'PROJECT_STRINGS_ALERTS_REJECT_SUCCESS'
}
