import dotProp from 'dot-prop-immutable'

import projectLanguageStatsConstants from './constants'

const initialState = {
  fetchingError: null,
  fetchingStatus: 'idle',

  items: null
}

export default (state = initialState, action) => {
  const error = action.error ? (action.error.data || action.error.message) : null
  let newState

  switch (action.type) {
    case projectLanguageStatsConstants.FETCH_REQUEST: {
      const { locale, projectId } = action

      newState = dotProp.set(state, 'fetchingError', null)
      newState = dotProp.set(newState, 'fetchingStatus', 'fetching')

      return dotProp.set(newState, 'items', {
        ...(newState.items || {}),
        [`${projectId}-${locale}`]: { status: 'fetching' }
      })
    }

    case projectLanguageStatsConstants.FETCH_FAILURE: {
      const { locale, projectId } = action

      newState = dotProp.set(state, 'fetchingError', error)
      newState = dotProp.set(newState, 'fetchingStatus', 'failed')

      return dotProp.set(newState, 'items', {
        ...(newState.items || {}),
        [`${projectId}-${locale}`]: Object.assign(
          state[`${projectId}-${locale}`],
          {
            status: 'failed'
          }
        )
      })
    }

    case projectLanguageStatsConstants.FETCH_SUCCESS: {
      const { locale, projectId, stats } = action

      newState = dotProp.set(state, 'fetchingError', null)

      newState = dotProp.set(newState, 'items', {
        ...(newState.items || {}),
        [`${projectId}-${locale}`]: Object.assign(stats, {
          status: 'succeeded'
        })
      })

      // Builds a Set of all the status from each project-locale entries
      const uniqItemStatuses = new Set(
        Object.values(newState.items).map((item) => item.status)
      )

      // When all are in status 'succeeded'
      if (uniqItemStatuses.size === 1 && uniqItemStatuses.has('succeeded')) {
        newState = dotProp.set(newState, 'fetchingStatus', 'succeeded')
      }

      return newState
    }

    default:
      return state
  }
}
