export default {
  ACTIVATE_INTEGRATION_REQUEST: 'INTEGRATIONS_ACTIVATE_INTEGRATION_REQUEST',
  ACTIVATE_INTEGRATION_SUCCESS: 'INTEGRATIONS_ACTIVATE_INTEGRATION_SUCCESS',
  ACTIVATE_INTEGRATION_FAILURE: 'INTEGRATIONS_ACTIVATE_INTEGRATION_FAILURE',

  CHECK_PATHS_REQUEST: 'INTEGRATIONS_CHECK_PATHS_REQUEST',
  CHECK_PATHS_FAILURE: 'INTEGRATIONS_CHECK_PATHS_FAILURE',
  CHECK_PATHS_SUCCESS: 'INTEGRATIONS_CHECK_PATHS_SUCCESS',

  FETCH_ALL_REQUEST: 'INTEGRATIONS_FETCH_ALL_REQUEST',
  FETCH_ALL_FAILURE: 'INTEGRATIONS_FETCH_ALL_FAILURE',
  FETCH_ALL_SUCCESS: 'INTEGRATIONS_FETCH_ALL_SUCCESS',

  FETCH_BRANCHES_REQUEST: 'INTEGRATIONS_FETCH_BRANCHES_REQUEST',
  FETCH_BRANCHES_FAILURE: 'INTEGRATIONS_FETCH_BRANCHES_FAILURE',
  FETCH_BRANCHES_SUCCESS: 'INTEGRATIONS_FETCH_BRANCHES_SUCCESS',

  FETCH_REPOSITORIES_REQUEST: 'INTEGRATIONS_FETCH_REPOSITORIES_REQUEST',
  FETCH_REPOSITORIES_FAILURE: 'INTEGRATIONS_FETCH_REPOSITORIES_FAILURE',
  FETCH_REPOSITORIES_SUCCESS: 'INTEGRATIONS_FETCH_REPOSITORIES_SUCCESS',

  UPDATE_INTEGRATION_REQUEST: 'INTEGRATIONS_UPDATE_INTEGRATION_REQUEST',
  UPDATE_INTEGRATION_SUCCESS: 'INTEGRATIONS_UPDATE_INTEGRATION_SUCCESS',
  UPDATE_INTEGRATION_FAILURE: 'INTEGRATIONS_UPDATE_INTEGRATION_FAILURE'
}
