import React from 'react'
import i18n from '../../../config/i18n'

const TableHeaderLanguage = () => (
  <tr>
    <th>{i18n.t('ProjectsPage.TableHeaderLanguage.language')}</th>
    <th>{i18n.t('ProjectsPage.TableHeaderLanguage.translated')}</th>
  </tr>
)

export default TableHeaderLanguage
