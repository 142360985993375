import React from 'react'
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap'
import PropTypes from 'prop-types'
import { v1 as uuidv1 } from 'uuid'

const TablePagination = ({
  currentPage,
  onPaginationClick,
  totalPages
}) => {
  let pageLimit = 10 // number of page links in pagination
  let start = 0 // starting page
  let end = pageLimit // ending page

  if (totalPages <= pageLimit) {
    pageLimit = totalPages
  }

  // increment start page when current page is greater than 5
  if (currentPage - 5 >= 0) {
    start = currentPage - 4
  }

  // if reaching end of pagination stop increment
  if (start + pageLimit >= totalPages) {
    start = totalPages - pageLimit
  }

  // increment end page when current + 5 exceeds page limit
  if (currentPage + 5 >= pageLimit) {
    end = currentPage + 6
    pageLimit = end
    if (totalPages <= pageLimit) {
      pageLimit = totalPages
    }
  }

  return (
    <Pagination aria-label="Table pagination">
      {currentPage !== 1 && totalPages >= pageLimit && (
        <PaginationItem>
          <PaginationLink
            first
            href="#"
            onClick={() => onPaginationClick(1)}
          />
        </PaginationItem>
      )}

      {Array.from({ length: totalPages }, (element, index) => {
        if (index < start || index > end) return null

        return (
          <PaginationItem
            active={currentPage === (index + 1)}
            disabled={currentPage === (index + 1)}
            key={uuidv1()}
          >
            <PaginationLink
              href="#"
              onClick={() => onPaginationClick(index + 1)}
            >
              {index + 1}
            </PaginationLink>
          </PaginationItem>
        )
      })}

      {totalPages > pageLimit && (
        <PaginationItem>
          <PaginationLink
            href="#"
            last
            onClick={() => onPaginationClick(totalPages)}
          />
        </PaginationItem>
      )}
    </Pagination>
  )
}

TablePagination.propTypes = {
  currentPage: PropTypes.number,
  onPaginationClick: PropTypes.func.isRequired,
  totalPages: PropTypes.number
}

TablePagination.defaultProps = {
  currentPage: 1,
  totalPages: 1
}

export default TablePagination
