import React from 'react'
import PropTypes from 'prop-types'

import i18n from '../../../config/i18n'

const SynchronizationStatus = ({ importStep }) => (
  <div className="mt-3">
    {importStep
      && i18n.t(`ProjectsPage.ProjectCard.SynchronizationStatus.${importStep}`)}
  </div>
)

SynchronizationStatus.propTypes = {
  importStep: PropTypes.string
}

SynchronizationStatus.defaultProps = {
  importStep: null
}

export default SynchronizationStatus
