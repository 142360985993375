import ApiUtils from '../../../../helpers/ApiUtils'
import ProjectLanguageStatsApi from '../../../../apis/ProjectLanguageStatsApi'

let currentRequest = null
let currentFetchCount = 0
let requests = []
const maxParallelFetch = 2

const proceedRequest = (callback) => {
  const {
    locale,
    projectId,
    reject,
    resolve
  } = currentRequest

  currentFetchCount += 1

  new ProjectLanguageStatsApi(projectId, locale).all()
    .then(ApiUtils.handleResponse)
    .then((data) => resolve(data))
    .catch((error) => reject(error))
    .finally(() => {
      currentFetchCount -= 1
      callback()
    })
}
const shiftNextRequest = () => {
  if (requests.length === 0) return

  if (currentFetchCount <= maxParallelFetch) {
    currentRequest = requests.shift()
    proceedRequest(shiftNextRequest)
  } else {
    setTimeout(shiftNextRequest, 100)
  }
}

const fetch = (projectId, locale) => {
  const fetchPromise = new Promise((resolve, reject) => {
    requests = [...requests, {
      locale,
      projectId,
      reject,
      resolve
    }]
  })

  shiftNextRequest()

  return fetchPromise
}

export default {
  fetch
}
