import React from 'react'
import PropTypes from 'prop-types'

const StatusFailedIcon = ({ size }) => (
  <svg
    fill="rgb(237, 98, 76)"
    height={`${size}em`}
    width={`${size}em`}
    viewBox="0 0 14 14"
  >
    <g fillRule="evenodd">
      <path d="M0 7a7 7 0 1 1 14 0A7 7 0 0 1 0 7z" />
      <path d="M13 7A6 6 0 1 0 1 7a6 6 0 0 0 12 0z" fill="var(--svg-status-bg, #fff)" />
      <path d="M7 5.969L5.599 4.568a.29.29 0 0 0-.413.004l-.614.614a.294.294 0 0 0-.004.413L5.968 7l-1.4 1.401a.29.29 0 0 0 .004.413l.614.614c.113.114.3.117.413.004L7 8.032l1.401 1.4a.29.29 0 0 0 .413-.004l.614-.614a.294.294 0 0 0 .004-.413L8.032 7l1.4-1.401a.29.29 0 0 0-.004-.413l-.614-.614a.294.294 0 0 0-.413-.004L7 5.968z" />
    </g>
  </svg>
)

StatusFailedIcon.propTypes = {
  size: PropTypes.number
}

StatusFailedIcon.defaultProps = {
  size: 1
}

export default StatusFailedIcon
