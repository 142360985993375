import React from 'react'
import {
  Button,
  Form,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from 'reactstrap'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import AlertMessage from '../../components/AlertMessage'
import FormInputField from '../../components/FormInputField'
import i18n from '../../config/i18n'
import localesActions from '../../localez/actions'

class LocaleModal extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      code: '',
      englishName: '',
      originalCode: null
    }
  }

  componentDidUpdate(prevProps) {
    const {
      codeCheckingStatus: prevCodeCheckingStatus,
      creatingStatus: prevCreatingStatus,
      id: prevId,
      updatingStatus: prevUpdatingStatus
    } = prevProps

    const {
      codeCheckingError,
      codeCheckingStatus,
      creatingStatus,
      id,
      isOpen,
      updatingStatus
    } = this.props

    if (isOpen === false) return

    if (prevId !== id) this.populateStateWithLocale(id)

    if (prevCodeCheckingStatus === 'checking') {
      this.updateCodeFieldStatusWith(codeCheckingStatus)
      if (codeCheckingStatus === 'failed') {
        this.codeField.showAsInvalid(
          typeof codeCheckingError === 'string'
            ? codeCheckingError
            : codeCheckingError[0]
        )
      }
    }

    if (prevCreatingStatus === 'creating' && creatingStatus === 'succeeded') {
      this.toggle()
    }

    if (prevUpdatingStatus === 'updating' && updatingStatus === 'succeeded') {
      this.toggle()
    }
  }

  onSubmit(event) {
    const { codeCheckingStatus, dispatch, id } = this.props
    const { code, englishName, originalCode } = this.state

    event.preventDefault()

    if (code === '') this.codeField.showAsInvalid()
    if (englishName === '') this.englishNameField.showAsInvalid()

    if (((id && code === originalCode)
        || (id && code !== originalCode && codeCheckingStatus === 'succeeded')
        || (id === null && codeCheckingStatus === 'succeeded'))
      && code
      && englishName
    ) {
      const attributes = { code, name: englishName }

      if (id) {
        dispatch(localesActions.update(id, attributes))
      } else {
        dispatch(localesActions.create(attributes))
      }
    }
  }

  onChange({ target: { name, value } }) {
    this.setState({
      [name]: value
    })
  }

  checkCodeAvailablity() {
    const { dispatch } = this.props
    const { code } = this.state

    this.updateCodeFieldStatusWith(
      'checking',
      () => dispatch(localesActions.checkCodeAvailablity(code))
    )
  }

  populateStateWithLocale(id) {
    const { locales } = this.props

    const locale = locales.find((item) => item.id === id)

    this.setState({
      code: locale?.value || '',
      englishName: locale?.name || '',
      originalCode: locale?.value || null
    })
  }

  toggle() {
    const { toggle } = this.props

    this.setState({
      code: '',
      englishName: ''
    }, () => toggle())
  }

  updateCodeFieldStatusWith(status, callback = null) {
    switch (status) {
      case 'checking':
        this.codeField.showAsBusy()
        break
      case 'failed':
        this.codeField.showAsInvalid()
        break
      case 'succeeded':
        this.codeField.showAsValid()
        break
      default:
        this.codeField.reset()
    }

    if (callback) callback()
  }

  render() {
    const {
      creatingError,
      isOpen
    } = this.props

    const {
      code,
      englishName
    } = this.state

    const i18nBase = 'LocalesPage.LocaleModal'

    return (
      <Modal isOpen={isOpen} toggle={() => this.toggle()}>
        <Form>
          <ModalHeader>
            {i18n.t(`${i18nBase}.title`)}
          </ModalHeader>
          <ModalBody className="d-flex flex-column">
            <FormInputField
              inputId="englishName"
              inputName="englishName"
              inputType="text"
              labelText={i18n.t(`${i18nBase}.fields.englishName`)}
              onChange={(event) => this.onChange(event)}
              ref={(component) => { this.englishNameField = component }}
              value={englishName}
            />
            <FormInputField
              inputId="code"
              inputName="code"
              inputType="text"
              labelText={i18n.t(`${i18nBase}.fields.code`)}
              onBlur={() => this.checkCodeAvailablity()}
              onChange={(event) => this.onChange(event)}
              ref={(component) => { this.codeField = component }}
              value={code}
            />
            {creatingError && (
              <AlertMessage>
                {i18n.t('errors.somethingWentWrong')}
              </AlertMessage>
            )}
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => this.toggle()}>
              {i18n.t('buttons.cancel')}
            </Button>
            <Button
              color="primary"
              onClick={(event) => this.onSubmit(event)}
            >
              {i18n.t('buttons.save')}
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    )
  }
}

LocaleModal.propTypes = {
  dispatch: PropTypes.func.isRequired,
  codeCheckingError: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  codeCheckingStatus: PropTypes.string,
  creatingError: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  creatingStatus: PropTypes.string,
  id: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  locales: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired
  })),
  toggle: PropTypes.func.isRequired,
  updatingStatus: PropTypes.string
}

LocaleModal.defaultProps = {
  creatingError: null,
  creatingStatus: 'idle',
  codeCheckingError: null,
  codeCheckingStatus: 'idle',
  id: null,
  locales: null,
  updatingStatus: 'idle'
}

const mapStateToProps = ({
  locales: {
    codeCheckingError,
    codeCheckingStatus,
    creatingError,
    creatingStatus,
    items: locales,
    updatingStatus
  }
}) => ({
  codeCheckingError,
  codeCheckingStatus,
  creatingError,
  creatingStatus,
  locales,
  updatingStatus
})

export default connect(mapStateToProps)(LocaleModal)
