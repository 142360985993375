import projectExportsConstants from './constants'
import projectExportsService from './services'

const checkIfAny = (projectId) => {
  const request = () => ({
    type: projectExportsConstants.CHECK_IF_ANY_REQUEST
  })
  const success = (data) => ({
    data,
    type: projectExportsConstants.CHECK_IF_ANY_SUCCESS
  })
  const failure = (error) => ({
    error,
    type: projectExportsConstants.CHECK_IF_ANY_FAILURE
  })

  return (dispatch) => {
    dispatch(request())

    projectExportsService.checkIfAny(projectId)
      .then((data) => dispatch(success(data)))
      .catch((error) => dispatch(failure(error)))
  }
}

const create = (projectId) => {
  const request = () => ({ type: projectExportsConstants.CREATE_REQUEST })
  const success = (projectExport) => ({
    projectExport,
    type: projectExportsConstants.CREATE_SUCCESS
  })
  const failure = (error) => ({
    error,
    type: projectExportsConstants.CREATE_FAILURE
  })

  return (dispatch) => {
    dispatch(request())

    projectExportsService.create(projectId)
      .then((projectExport) => dispatch(success(projectExport)))
      .catch((error) => dispatch(failure(error)))
  }
}

const fetch = (projectId, id) => {
  const request = () => ({
    type: projectExportsConstants.FETCH_REQUEST
  })
  const success = (projectExport) => ({
    projectExport,
    type: projectExportsConstants.FETCH_SUCCESS
  })
  const failure = (error) => ({
    error,
    type: projectExportsConstants.FETCH_FAILURE
  })

  return (dispatch) => {
    dispatch(request())

    projectExportsService.fetch(projectId, id)
      .then((projectExport) => dispatch(success(projectExport)))
      .catch((error) => dispatch(failure(error)))
  }
}

const fetchAll = (projectId, page = null) => {
  const request = () => ({
    type: projectExportsConstants.FETCH_ALL_REQUEST
  })
  const success = (projectExports) => ({
    projectExports,
    type: projectExportsConstants.FETCH_ALL_SUCCESS
  })
  const failure = (error) => ({
    error,
    type: projectExportsConstants.FETCH_ALL_FAILURE
  })

  return (dispatch) => {
    dispatch(request())

    projectExportsService.fetchAll(projectId, page)
      .then((projectExports) => dispatch(success(projectExports)))
      .catch((error) => dispatch(failure(error)))
  }
}

const fetchDiff = (projectId, page = null) => {
  const request = () => ({ type: projectExportsConstants.FETCH_DIFF_REQUEST })
  const success = (diff) => ({
    diff,
    type: projectExportsConstants.FETCH_DIFF_SUCCESS
  })
  const failure = (error) => ({
    error,
    type: projectExportsConstants.FETCH_DIFF_FAILURE
  })

  return (dispatch) => {
    dispatch(request())

    projectExportsService.fetchDiff(projectId, page)
      .then((diff) => dispatch(success(diff)))
      .catch((error) => dispatch(failure(error)))
  }
}

const fetchExportDiff = (projectId, id, page = null) => {
  const request = () => ({ type: projectExportsConstants.FETCH_EXPORT_DIFF_REQUEST })
  const success = (diff) => ({
    diff,
    id,
    type: projectExportsConstants.FETCH_EXPORT_DIFF_SUCCESS
  })
  const failure = (error) => ({
    error,
    type: projectExportsConstants.FETCH_EXPORT_DIFF_FAILURE
  })

  return (dispatch) => {
    dispatch(request())

    projectExportsService.fetchExportDiff(projectId, id, page)
      .then((diff) => dispatch(success(diff)))
      .catch((error) => dispatch(failure(error)))
  }
}

const retry = (projectId, id) => {
  const request = () => ({ type: projectExportsConstants.RETRY_REQUEST })
  const success = (diff) => ({
    diff,
    type: projectExportsConstants.RETRY_SUCCESS
  })
  const failure = (error) => ({
    error,
    type: projectExportsConstants.RETRY_FAILURE
  })

  return (dispatch) => {
    dispatch(request())

    projectExportsService.retry(projectId, id)
      .then((diff) => dispatch(success(diff)))
      .catch((error) => dispatch(failure(error)))
  }
}

export default {
  checkIfAny,
  create,
  fetch,
  fetchAll,
  fetchDiff,
  fetchExportDiff,
  retry
}
