import React, { useState } from 'react'
import {
  Collapse,
  Nav,
  NavItem,
  NavbarToggler
} from 'reactstrap'
import { Link } from 'react-router-dom'

import ActionCableMounter from '../../ActionCable/Mounter'
import i18n from '../../config/i18n'
import UserDropdownMenu from './UserDropdownMenu'

const ConnectedUserNavbar = () => {
  const [isOpen, setIsOpen] = useState(false)
  const toggle = () => setIsOpen(!isOpen)

  return (
    <>
      <NavbarToggler onClick={toggle} />
      <Collapse isOpen={isOpen} navbar>
        <Nav
          className="d-flex flex-fill mr-auto justify-content-between"
          navbar
        >
          <div className="d-flex">
            <NavItem>
              <Link
                className="nav-link active"
                to="/"
              >
                {i18n.t('ApplicationLayout.AppNavbar.links.projects')}
              </Link>
            </NavItem>

            <NavItem>
              <Link
                className="nav-link"
                to="/settings"
              >
                {i18n.t('ApplicationLayout.AppNavbar.links.settings')}
              </Link>
            </NavItem>

            <NavItem>
              <Link
                className="nav-link"
                to="/users"
              >
                {i18n.t('ApplicationLayout.AppNavbar.links.users')}
              </Link>
            </NavItem>
            <NavItem>
              <Link
                className="nav-link"
                to="/locales"
              >
                {i18n.t('ApplicationLayout.AppNavbar.links.locales')}
              </Link>
            </NavItem>
          </div>

          <UserDropdownMenu />
        </Nav>
      </Collapse>
      <ActionCableMounter />
    </>
  )
}

ConnectedUserNavbar.propTypes = {
}

export default ConnectedUserNavbar
