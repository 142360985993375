import React, { useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Table } from 'reactstrap'
import { v1 as uuidv1 } from 'uuid'

import AlertMessage from '../../components/AlertMessage'
import FilterHelpers from '../../helpers/FilterHelpers'
import i18n from '../../config/i18n'
import LocalTranslationTableFilter from './LocalTranslationTableFilter'
import PageLoader from '../../components/PageLoader'
import SearchingOverlay from '../../components/SearchingOverlay'
import TablePagination from '../../components/TablePagination'
import TranslationRow from './TranslationRow'
import translationsActions from './actions'

const LocalTranslationTable = ({
  currentLocale,
  dispatch,
  fetchingAllTranslationsError,
  fetchingAllTranslationsStatus,
  locales,
  location: { pathname, search },
  onTranslationBlur,
  onTranslationChange,
  paginationCurrentPage,
  paginationTotalPages,
  projectSlug,
  referenceLanguages,
  translations,
  translationsFilteringStatus,
  updatedStrings
}) => {
  const pageParams = new URLSearchParams(search)
  const page = pageParams.get('page')
  const pageFilterColumn = pageParams.get('filterColumn')
  const pageFilterValue = pageParams.get('filterValue')
  const pageSort = pageParams.get('sort')

  const columnObjectFor = (column) => (
    FilterHelpers.filterOptions.find((item) => item.value === column)
      || FilterHelpers.defaultFilterOptions
  )

  const sortObjectFor = (column) => {
    switch (column) {
      case 'key':
        return { label: i18n.t('TableSorting.options.alpha'), value: 'alpha' }
      case 'newests':
        return {
          label: i18n.t('TableSorting.options.newests'),
          value: 'newests'
        }
      default:
        return { label: i18n.t('TableSorting.options.alpha'), value: 'alpha' }
    }
  }

  if (fetchingAllTranslationsStatus === 'failed') {
    return (
      <div className="d-flex flex-column">
        <AlertMessage>
          {typeof fetchingAllTranslationsError === 'string'
            ? fetchingAllTranslationsError
            : i18n.t('errors.somethingWentWrong')}
        </AlertMessage>
      </div>
    )
  }

  const [currentPage, setCurrentPage] = useState(page || null)
  const [filter, setFilter] = useState(pageFilterValue || null)
  const [filterColumn, setFilterColumn] = useState(
    pageFilterColumn === null
      ? columnObjectFor('path')
      : columnObjectFor(pageFilterColumn)
  )
  const [filterOnEmptyOnly, setFilterOnEmptyOnly] = useState(false)
  const [sortingOption, setSortingOption] = useState(
    pageSort === null
      ? sortObjectFor('alpha')
      : sortObjectFor(pageSort)
  )

  const finalFilter = {
    column: filterColumn.value,
    value: filter
  }

  const buildPageParams = (params = {}) => {
    let nextSearch = ''

    if (Object.keys(params).length > 0
        && Object.values(params).every((value) => value === null) === false
    ) {
      nextSearch = new URLSearchParams({
        filterColumn: params.finalFilter?.column || '',
        filterValue: params.finalFilter?.value || '',
        page: params.page || currentPage,
        sort: params.sort
      })
    }

    return nextSearch.toString()
  }

  const updatePageUrl = (searchParams) => (
    window.history.pushState(
      null,
      '',
      `${pathname}?${buildPageParams(searchParams)}`
    )
  )

  const fetchAll = (params = {}) => {
    dispatch(translationsActions.fetchAll({
      projectId: projectSlug,
      language: currentLocale,
      emptyOnly: filterOnEmptyOnly,
      page: params.page || currentPage,
      sort: sortingOption.value
    }))

    updatePageUrl({ page: params.page || currentPage, sort: sortingOption.value })
  }

  const filterOn = (params = {}) => {
    dispatch(translationsActions.filter({
      projectId: projectSlug,
      language: currentLocale,
      filter: params.finalFilter || finalFilter,
      emptyOnly: Object.keys(params).includes('emptyOnly')
        ? params.emptyOnly
        : filterOnEmptyOnly,
      page: params.page || currentPage || 1,
      sort: params.sort || sortingOption.value
    }))

    updatePageUrl({
      finalFilter: params.finalFilter || finalFilter,
      page: params.page || currentPage || 1,
      sort: params.sort || sortingOption.value
    })
  }

  const handleEmptyOnlyChange = (checked) => {
    setFilterOnEmptyOnly(checked)

    filterOn({ emptyOnly: checked })
  }

  const moveToPage = (selected) => {
    setCurrentPage(selected)
    if (filter) {
      filterOn({ page: selected })
    } else {
      fetchAll({ page: selected })
    }
  }

  const onFilterSubmit = () => {
    filterOn({ finalFilter })
  }

  const updateFilter = (event) => {
    if (Object.keys(event).includes('target')) {
      const { target: { value } } = event
      setFilter(value)
    } else {
      setFilterColumn(event)
      const { value } = event
      finalFilter.column = value
    }
  }

  const updateSortingAndRefresh = (selected) => {
    setSortingOption(selected)

    filterOn({ finalFilter, sort: selected.value })
  }

  if (pageFilterColumn && pageFilterValue) {
    if (translationsFilteringStatus === 'idle') filterOn()
  } else if (fetchingAllTranslationsStatus === 'idle') fetchAll()

  return (
    <div className="d-flex flex-column">
      <LocalTranslationTableFilter
        filterColumn={filterColumn}
        filterOnEmptyOnly={filterOnEmptyOnly}
        filterQuery={filter}
        onColumnChange={updateFilter}
        onEmptyOnlyClick={handleEmptyOnlyChange}
        onQueryChange={updateFilter}
        onSortChange={updateSortingAndRefresh}
        onSubmit={onFilterSubmit}
        sortingOption={sortingOption}
      />

      <div className="position-relative mt-3">
        <Table
          bordered
          className="table-header-dark"
        >
          <thead>
            <tr>
              <th>{i18n.t('TranslationsPage.tableColumns.key')}</th>
              {referenceLanguages.filter((item) => item !== currentLocale).map(
                (item) => (
                  <th key={uuidv1()}>
                    {locales.find((locale) => locale.value === item).label}
                  </th>
                )
              )}
              <th>
                {locales.find((locale) => locale.value === currentLocale).label}
              </th>
            </tr>
          </thead>
          <tbody>
            {fetchingAllTranslationsStatus !== 'fetching'
              && (translations || []).map((translation) => (
                <TranslationRow
                  currentLocale={currentLocale}
                  key={translation.id}
                  locales={locales}
                  onBlur={() => onTranslationBlur()}
                  onChange={(eventValue, id) => onTranslationChange(eventValue, id)}
                  translation={translation}
                  value={
                    updatedStrings[translation.id] || translation.value || ''
                  }
                />
              ))}
          </tbody>
        </Table>

        {fetchingAllTranslationsStatus === 'fetching' && (
          <div className="d-flex flex-column flex-fill mb-4 justify-content-center align-items-center">
            <PageLoader message={i18n.t('TranslationsPage.fetchingStrings')} />
          </div>
        )}

        {translationsFilteringStatus === 'filtering' && (
          <SearchingOverlay />
        )}
      </div>

      <div className="d-flex flex-row justify-content-center">
        <TablePagination
          currentPage={paginationCurrentPage}
          onPaginationClick={(selected) => moveToPage(selected)}
          totalPages={paginationTotalPages}
        />
      </div>
    </div>
  )
}

LocalTranslationTable.propTypes = {
  currentLocale: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  fetchingAllTranslationsError: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  fetchingAllTranslationsStatus: PropTypes.string,
  locales: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired
  })),
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string
  }),
  onTranslationBlur: PropTypes.func,
  onTranslationChange: PropTypes.func,
  paginationCurrentPage: PropTypes.number,
  paginationTotalPages: PropTypes.number,
  projectSlug: PropTypes.string.isRequired,
  referenceLanguages: PropTypes.arrayOf(PropTypes.string).isRequired,
  translations: PropTypes.arrayOf(PropTypes.shape({
    locale: PropTypes.string.isRequired
  })),
  translationsFilteringStatus: PropTypes.string,
  updatedStrings: PropTypes.shape()
}

LocalTranslationTable.defaultProps = {
  fetchingAllTranslationsStatus: 'idle',
  fetchingAllTranslationsError: null,
  locales: null,
  location: {
    pathname: null
  },
  onTranslationBlur: () => {},
  onTranslationChange: () => {},
  paginationCurrentPage: null,
  paginationTotalPages: null,
  translations: null,
  translationsFilteringStatus: 'idle',
  updatedStrings: {}
}

const mapStateToProps = ({
  locales: {
    items: locales
  },
  translations: {
    fetchingAllError: fetchingAllTranslationsError,
    fetchingAllStatus: fetchingAllTranslationsStatus,
    filteringStatus: translationsFilteringStatus,
    itemsCurrentPage: paginationCurrentPage,
    items: translations,
    itemsTotalPages: paginationTotalPages
  }
}) => ({
  fetchingAllTranslationsError,
  fetchingAllTranslationsStatus,
  locales,
  paginationCurrentPage,
  paginationTotalPages,
  translations,
  translationsFilteringStatus
})

export default connect(mapStateToProps)(LocalTranslationTable)
