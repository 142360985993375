import projectStringsAlertsConstants from './constants'
import projectStringsAlertsServices from './services'

const accept = (slug, id) => {
  const request = () => ({ type: projectStringsAlertsConstants.ACCEPT_REQUEST })
  const success = () => ({
    id,
    type: projectStringsAlertsConstants.ACCEPT_SUCCESS
  })
  const failure = (error) => ({
    error,
    type: projectStringsAlertsConstants.ACCEPT_FAILURE
  })

  return (dispatch) => {
    dispatch(request())

    projectStringsAlertsServices.accept(slug, id)
      .then(() => dispatch(success()))
      .catch((error) => dispatch(failure(error)))
  }
}

const checkIfAny = (slug) => {
  const request = () => ({
    type: projectStringsAlertsConstants.CHECK_IF_ANY_REQUEST
  })
  const success = (data) => ({
    data,
    type: projectStringsAlertsConstants.CHECK_IF_ANY_SUCCESS
  })
  const failure = (error) => ({
    error,
    type: projectStringsAlertsConstants.CHECK_IF_ANY_FAILURE
  })

  return (dispatch) => {
    dispatch(request())

    projectStringsAlertsServices.checkIfAny(slug)
      .then((data) => dispatch(success(data)))
      .catch((error) => dispatch(failure(error)))
  }
}

const fetchAll = (slug, page = null) => {
  const request = () => ({
    type: projectStringsAlertsConstants.FETCH_ALL_REQUEST
  })
  const success = (stringsAlerts) => ({
    stringsAlerts,
    type: projectStringsAlertsConstants.FETCH_ALL_SUCCESS
  })
  const failure = (error) => ({
    error,
    type: projectStringsAlertsConstants.FETCH_ALL_FAILURE
  })

  return (dispatch) => {
    dispatch(request())

    projectStringsAlertsServices.fetchAll(slug, null, page)
      .then((stringsAlerts) => dispatch(success(stringsAlerts)))
      .catch((error) => dispatch(failure(error)))
  }
}

const filter = (slug, query = null, page = null) => {
  const request = () => ({ type: projectStringsAlertsConstants.FILTER_REQUEST })
  const success = (stringsAlerts) => ({
    stringsAlerts,
    type: projectStringsAlertsConstants.FILTER_SUCCESS
  })
  const failure = (error) => ({
    error,
    type: projectStringsAlertsConstants.FILTER_FAILURE
  })

  return (dispatch) => {
    dispatch(request())

    projectStringsAlertsServices.fetchAll(slug, query, page)
      .then((stringsAlerts) => dispatch(success(stringsAlerts)))
      .catch((error) => dispatch(failure(error)))
  }
}

const keep = (slug, id) => {
  const request = () => ({
    keepingId: id,
    type: projectStringsAlertsConstants.KEEP_REQUEST
  })
  const success = (response) => ({
    response,
    type: projectStringsAlertsConstants.KEEP_SUCCESS
  })
  const failure = (error) => ({
    error,
    type: projectStringsAlertsConstants.KEEP_FAILURE
  })

  return (dispatch) => {
    dispatch(request())

    projectStringsAlertsServices.keep(slug, id)
      .then((response) => dispatch(success(response)))
      .catch((error) => dispatch(failure(error)))
  }
}

const reject = (slug, id) => {
  const request = () => ({
    type: projectStringsAlertsConstants.REJECT_REQUEST
  })
  const success = () => ({
    id,
    type: projectStringsAlertsConstants.REJECT_SUCCESS
  })
  const failure = (error) => ({
    error,
    type: projectStringsAlertsConstants.REJECT_FAILURE
  })

  return (dispatch) => {
    dispatch(request())

    projectStringsAlertsServices.reject(slug, id)
      .then(() => dispatch(success()))
      .catch((error) => dispatch(failure(error)))
  }
}

export default {
  accept,
  checkIfAny,
  fetchAll,
  filter,
  keep,
  reject
}
