import React from 'react'

import i18n from '../../config/i18n'

const NoConfiguredIntegration = () => (
  <div id="no-integration">
    <p className="lead">{i18n.t('NoConfiguredIntegration.title')}</p>
    <p className="lead">{i18n.t('NoConfiguredIntegration.message')}</p>
  </div>
)

export default NoConfiguredIntegration
