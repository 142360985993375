import ApiUtils from '../../../helpers/ApiUtils'
import ProjectUnusedStringsApi from '../../../apis/ProjectUnusedStringsApi'

const checkIfAny = (slug) => (
  new ProjectUnusedStringsApi(slug).get({
    query: { check: true }
  }).then(ApiUtils.handleResponse)
)

const destroy = (projectId, language, id) => (
  new ProjectUnusedStringsApi(projectId, language).destroy(id)
    .then(ApiUtils.handleResponse)
)

const fetchAll = (slug, page = null) => (
  new ProjectUnusedStringsApi(slug).get({
    query: {
      page
    }
  }).then(ApiUtils.handleResponse)
)

export default {
  checkIfAny,
  destroy,
  fetchAll
}
