/*
** General Application Router
*
*  You can read more about React router here:
*  https://reacttraining.com/react-router/web/guides/philosophy
*/
import React from 'react'
import {
  Router,
  Route,
  Switch
} from 'react-router-dom'
import { v1 as uuidv1 } from 'uuid'

// Layouts
import ApplicationLayout from '../ApplicationLayout'

// Routes
import AuthRoute from './AuthRoute'
import DeviseRoutes from './routes/DeviseRoutes'
import FourOFour from './FourOFour'
import PrivateRoutes from './routes/PrivateRoutes'

import history from '../helpers/History'

const AppRouter = () => (
  <Router history={history}>
    <ApplicationLayout>
      <Switch>
        {DeviseRoutes.map((route) => (
          <Route
            component={route.component}
            exact
            key={uuidv1()}
            path={route.path}
            render={(props) => route.component(props)}
          />
        ))}

        {PrivateRoutes.map((route) => (
          <AuthRoute
            component={route.component()}
            exact
            key={uuidv1()}
            path={route.path}
          />
        ))}

        <Route component={FourOFour} />
      </Switch>
    </ApplicationLayout>
  </Router>
)

export default AppRouter
