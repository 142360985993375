import React from 'react'
import { connect } from 'react-redux'
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from 'reactstrap'
import PropTypes from 'prop-types'

import AlertMessage from '../../components/AlertMessage'
import GitOptions from '../../components/GitOptions'
import i18n from '../../config/i18n'
import PageLoader from '../../components/PageLoader'
import projectsActions from './actions'

class ProjectForceImportConfirmModal extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      // Force to import all files, even if their MD5 sum has not changed.
      allFiles: false,
      commitSha: null,
      gitOptionsIsOpen: false,
      selectedBranch: null,
      selectedBranchName: null
    }
  }

  componentDidUpdate(prevProps) {
    const {
      branchesFetchingStatus: prevBranchesFetchingStatus,
      fetchingProjectsStatus: prevFetchingProjectsStatus,
      forceImportStatus: prevForceImportStatus,
      repositoryId: prevRepositoryId
    } = prevProps

    const {
      branchesFetchingStatus,
      fetchingProjectsStatus,
      forceImportStatus,
      onClose,
      repositoryId
    } = this.props

    if (prevRepositoryId !== repositoryId) {
      this.resetGitOptions()
    }

    if ((prevBranchesFetchingStatus === 'fetching'
         && branchesFetchingStatus === 'succeeded')
     || (prevFetchingProjectsStatus === 'fetching'
        && fetchingProjectsStatus === 'succeeded')
    ) {
      this.preSelectProjectBranch()
    }

    if (prevForceImportStatus === 'importing'
        && forceImportStatus === 'succeeded') {
      onClose()
    }
  }

  handleSubmit(event) {
    const { projectId, dispatch } = this.props
    const { allFiles, commitSha, selectedBranch } = this.state

    event.preventDefault()

    dispatch(projectsActions.forceImport(projectId, allFiles, {
      commitSha,
      selectedBranch: selectedBranch?.label
    }))
  }

  preSelectProjectBranch() {
    const { projectId, projects } = this.props

    const project = projects.find((item) => item.id === projectId)

    if (!project) return

    this.setState({
      selectedBranchName: project.repository_branch_name
    })
  }

  resetGitOptions() {
    this.setState({
      commitSha: null,
      selectedBranch: null
    })
  }

  toggleGitOptionsIsOpen() {
    const { gitOptionsIsOpen } = this.state

    this.setState({
      gitOptionsIsOpen: !gitOptionsIsOpen
    })
  }

  updateAllFiles(checked) {
    this.setState({
      allFiles: checked
    })
  }

  updateCommitSha(commitSha) {
    this.setState({
      commitSha
    })
  }

  updateSelectedGitBranch(selectedBranch) {
    this.setState({
      selectedBranch
    })
  }

  render() {
    const {
      forceImportError,
      forceImportStatus,
      isOpen,
      onClose,
      projectId,
      repositoryId
    } = this.props

    const {
      commitSha,
      gitOptionsIsOpen,
      selectedBranch,
      selectedBranchName
    } = this.state

    const i18nBase = 'ProjectsPage.ProjectForceImportConfirmModal'

    return (
      <Modal isOpen={isOpen} toggle={() => onClose()}>
        <Form>
          <ModalHeader toggle={() => onClose()}>
            {i18n.t(`${i18nBase}.title`)}
          </ModalHeader>
          <ModalBody>
            <div className="d-flex flex-column">
              <p className="lead">{i18n.t(`${i18nBase}.headerMessage`)}</p>
              <ul>
                <li>{i18n.t(`${i18nBase}.bodyMessage.newKeys`)}</li>
                <li>{i18n.t(`${i18nBase}.bodyMessage.deletedKeys`)}</li>
                <li>{i18n.t(`${i18nBase}.bodyMessage.updatedKeys`)}</li>
              </ul>
            </div>

            {(forceImportStatus === 'importing') && (
              <PageLoader message={i18n.t(`${i18nBase}.importing`)} />
            )}

            {forceImportStatus === 'failed' && (
              <AlertMessage>{forceImportError}</AlertMessage>
            )}

            <FormGroup check className="mb-4">
              <Label check>
                <Input
                  onChange={
                    ({ target: { checked } }) => this.updateAllFiles(checked)
                  }
                  type="checkbox"
                />
                {i18n.t(`${i18nBase}.allFiles`)}
              </Label>
            </FormGroup>

            <GitOptions
              commitSha={commitSha}
              isOpen={gitOptionsIsOpen}
              onCommitShaChange={(value) => this.updateCommitSha(value)}
              onBranchChange={
                (selected) => this.updateSelectedGitBranch(selected)
              }
              repositoryId={repositoryId}
              selectedBranch={selectedBranch}
              selectedBranchName={selectedBranchName}
              toggle={() => this.toggleGitOptionsIsOpen()}
            />
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => onClose()}>
              {i18n.t('buttons.cancel')}
            </Button>
            <Button
              color="primary"
              disabled={projectId === null}
              onClick={(event) => this.handleSubmit(event)}
              type="submit"
            >
              {i18n.t('buttons.continue')}
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    )
  }
}

ProjectForceImportConfirmModal.propTypes = {
  branchesFetchingStatus: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  fetchingProjectsStatus: PropTypes.string,
  forceImportError: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  forceImportStatus: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  projectId: PropTypes.string,
  projects: PropTypes.arrayOf(PropTypes.shape()),
  repositoryId: PropTypes.number
}

ProjectForceImportConfirmModal.defaultProps = {
  branchesFetchingStatus: 'idle',
  fetchingProjectsStatus: 'idle',
  forceImportError: null,
  forceImportStatus: 'idle',
  projectId: null,
  projects: null,
  repositoryId: null
}

const mapStateToProps = ({
  integrations: {
    branchesFetchingStatus
  },
  projects: {
    fetchingAllStatus: fetchingProjectsStatus,
    forceImportError,
    forceImportStatus,
    items: projects
  }
}) => ({
  branchesFetchingStatus,
  fetchingProjectsStatus,
  forceImportError,
  forceImportStatus,
  projects
})

export default connect(mapStateToProps)(ProjectForceImportConfirmModal)
