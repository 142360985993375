import dotProp from 'dot-prop-immutable'

import actionCableConstants from '../ActionCable/constants'
import integrationsConstants from './constants'

const initialState = {
  activationError: null,
  activationPlatform: null,
  activationStatus: 'idle',

  branchesFetchingError: null,
  branchesFetchingStatus: 'idle',
  branches: {},

  checkingPathsError: null,
  checkingPathsStatus: 'idle',

  fetchingError: null,
  fetchingStatus: 'idle',

  items: null,

  repositoriesFetchingError: null,
  repositoriesFetchingStatus: 'idle',
  repositories: {},

  updateStatus: 'idle',
  updateError: null
}

export default (state = initialState, action) => {
  const error = action.error ? (action.error.data || action.error.message) : null
  let newState

  switch (action.type) {
    case integrationsConstants.ACTIVATE_INTEGRATION_REQUEST:
      newState = dotProp.set(state, 'activationError', null)
      newState = dotProp.set(newState, 'activationPlatform', action.platform)
      return dotProp.set(newState, 'activationStatus', 'activating')
    case integrationsConstants.ACTIVATE_INTEGRATION_FAILURE:
      newState = dotProp.set(state, 'activationError', error)
      return dotProp.set(newState, 'activationStatus', 'failed')
    case integrationsConstants.ACTIVATE_INTEGRATION_SUCCESS:
      newState = dotProp.set(state, 'activationError', null)
      newState = dotProp.set(newState, 'activationStatus', 'succeeded')
      return dotProp.set(newState, 'items', [...state.items || [], action.integration])

    case integrationsConstants.CHECK_PATHS_REQUEST:
      newState = dotProp.set(state, 'checkingPathsError', null)
      return dotProp.set(newState, 'checkingPathsStatus', 'checking')
    case integrationsConstants.CHECK_PATHS_FAILURE:
      newState = dotProp.set(state, 'checkingPathsError', error)
      return dotProp.set(newState, 'checkingPathsStatus', 'failed')
    case integrationsConstants.CHECK_PATHS_SUCCESS:
      newState = dotProp.set(state, 'checkingPathsError', null)
      return dotProp.set(newState, 'checkingPathsStatus', 'succeeded')

    case integrationsConstants.FETCH_ALL_REQUEST:
      newState = dotProp.set(state, 'fetchingAllError', null)
      return dotProp.set(newState, 'fetchingStatus', 'fetching')
    case integrationsConstants.FETCH_ALL_FAILURE:
      newState = dotProp.set(state, 'fetchingAllError', error)
      return dotProp.set(newState, 'fetchingStatus', 'failed')
    case integrationsConstants.FETCH_ALL_SUCCESS:
      newState = dotProp.set(state, 'fetchingAllError', null)
      newState = dotProp.set(newState, 'fetchingStatus', 'succeeded')
      return dotProp.set(newState, 'items', action.integrations)

    case integrationsConstants.FETCH_BRANCHES_REQUEST:
      newState = dotProp.set(state, 'branchesFetchingError', null)
      return dotProp.set(newState, 'branchesFetchingStatus', 'fetching')
    case integrationsConstants.FETCH_BRANCHES_FAILURE:
      newState = dotProp.set(state, 'branchesFetchingError', error)
      return dotProp.set(newState, 'branchesFetchingStatus', 'failed')
    case integrationsConstants.FETCH_BRANCHES_SUCCESS:
      newState = dotProp.set(state, 'branchesFetchingError', null)
      newState = dotProp.set(newState, 'branchesFetchingStatus', 'succeeded')
      return dotProp.set(
        newState,
        `branches.${action.platform}`,
        action.branches
      )

    case integrationsConstants.FETCH_REPOSITORIES_REQUEST:
      newState = dotProp.set(state, 'repositoriesFetchingError', null)
      return dotProp.set(newState, 'repositoriesFetchingStatus', 'fetching')
    case integrationsConstants.FETCH_REPOSITORIES_FAILURE:
      newState = dotProp.set(state, 'repositoriesFetchingError', error)
      return dotProp.set(newState, 'repositoriesFetchingStatus', 'failed')
    case integrationsConstants.FETCH_REPOSITORIES_SUCCESS:
      newState = dotProp.set(state, 'repositoriesFetchingError', null)
      newState = dotProp.set(
        newState,
        'repositoriesFetchingStatus',
        'succeeded'
      )
      return dotProp.set(
        newState,
        `repositories.${action.platform}`,
        action.repositories
      )

    case integrationsConstants.UPDATE_INTEGRATION_REQUEST:
      newState = dotProp.set(state, 'updateError', null)
      return dotProp.set(state, 'updateStatus', 'updating')
    case integrationsConstants.UPDATE_INTEGRATION_FAILURE:
      newState = dotProp.set(state, 'updatingError', error)
      return dotProp.set(newState, 'updatingStatus', 'failed')
    case integrationsConstants.UPDATE_INTEGRATION_SUCCESS: {
      newState = dotProp.set(state, 'updateError', null)
      newState = dotProp.set(newState, 'updateStatus', 'succeeded')
      const index = state.items.findIndex(
        (item) => item.id === action.integration.id
      )
      return dotProp.set(newState, `items.${index}`, action.integration)
    }

    case actionCableConstants.DATA_RECEIVED_FROM_CHANNEL:
      if (action.channelName === 'IntegrationsChannel') {
        const itemIndex = state.items.findIndex(
          (item) => item.id === action.data.id
        )
        if (itemIndex === -1) {
          return dotProp.set(state, 'items', [...state.items || [], action.data])
        }

        return dotProp.set(state, `items.${itemIndex}`, action.data)
      }
      return state

    default:
      return state
  }
}
