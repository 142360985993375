import RestfulClient from 'restful-json-api-client'

import deviseConstants from '../constants'
import i18n from '../../config/i18n'
import history from '../../helpers/History'
import sessionService from './services'

const login = (email, password) => {
  const request = () => ({ type: deviseConstants.LOGIN_REQUEST })
  const success = (user) => ({ type: deviseConstants.LOGIN_SUCCESS, user })
  const failure = (error) => ({ error, type: deviseConstants.LOGIN_FAILURE })

  return (dispatch) => {
    RestfulClient.clearToken()

    dispatch(request())

    sessionService.login(email, password)
      .then((user) => {
        dispatch(success(user))

        i18n.changeLanguage(user.locale || 'en')

        history.push('/')
      })
      .catch((error) => dispatch(failure(error)))
  }
}

const logout = () => {
  const request = () => ({ type: deviseConstants.LOGOUT_REQUEST })
  const success = () => ({ type: deviseConstants.LOGOUT_SUCCESS })
  const failure = (error) => ({ error, type: deviseConstants.LOGOUT_FAILURE })

  return (dispatch) => {
    dispatch(request())

    sessionService.logout()
      .then(() => {
        RestfulClient.clearToken()
        dispatch(success())
      })
      .catch((error) => dispatch(failure(error)))
  }
}

const reset = () => (dispatch) => {
  dispatch({ type: deviseConstants.RESET_LOGGING })
}

export default {
  login,
  logout,
  reset
}
