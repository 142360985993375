import React from 'react'
import { Badge } from 'reactstrap'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Select from 'react-select'

import AlertMessage from '../AlertMessage'
import i18n from '../../config/i18n'
import integrationsActions from '../../integrations/actions'
import PageLoader from '../PageLoader'

class GitOptionImportBranch extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      platformBranches: []
    }
  }

  componentDidMount() {
    this.refereshBranches()
  }

  componentDidUpdate(prevProps) {
    const {
      branchesFetchingStatus: prevBranchesFetchingStatus,
      repositoryId: prevRepositoryId
    } = prevProps
    const {
      branchesFetchingStatus,
      repositoryId
    } = this.props

    if (prevRepositoryId !== repositoryId) this.refereshBranches()

    if (prevBranchesFetchingStatus === 'fetching'
        && branchesFetchingStatus === 'succeeded'
    ) {
      this.updatePlatformBranches()
    }
  }

  refereshBranches() {
    const { dispatch, repositoryId } = this.props

    if (repositoryId) {
      dispatch(integrationsActions.fetchBranches(repositoryId, 'gitlab'))
    }
  }

  updatePlatformBranches() {
    const { branches } = this.props

    this.setState({
      platformBranches: [].concat(branches?.gitlab).sort(
        (a, b) => a.label.localeCompare(b.label)
      )
    })
  }

  render() {
    const {
      branchesFetchingError,
      branchesFetchingStatus,
      onChange,
      repositoryId,
      selectedBranch,
      selectedBranchName
    } = this.props

    const { platformBranches } = this.state

    const i18nBase = 'ProjectsPage.GitOptions.options.branch'

    const BranchOption = (branch) => (
      <div className="d-flex flex-row">
        {branch.label}
        {branch.default && (
          <div className="d-flex flex-column justify-content-center ml-2">
            <Badge color="primary">
              {i18n.t(`${i18nBase}.badges.default`)}
            </Badge>
          </div>
        )}
        {branch.protected && (
          <div className="d-flex flex-column justify-content-center ml-2">
            <Badge color="success">
              {i18n.t(`${i18nBase}.badges.protected`)}
            </Badge>
          </div>
        )}
      </div>
    )

    const defaultBranch = platformBranches?.find((item) => item.default)
    let preSelectedBranch

    if (selectedBranch === null && selectedBranchName !== null) {
      preSelectedBranch = platformBranches?.find((item) => item.label === selectedBranchName)
    }

    return (
      <div className="d-flex flex-row justify-content-between mb-2">
        <div className="d-flex flex-column justify-content-center">
          {i18n.t(`${i18nBase}.heading`)}
        </div>

        {branchesFetchingStatus === 'fetching' && (
          <PageLoader
            message={i18n.t(`${i18nBase}.fetching`)}
          />
        )}

        {branchesFetchingStatus === 'failed' && (
          <AlertMessage>
            {typeof branchesFetchingError === 'string'
              ? branchesFetchingError
              : i18n.t('errors.somethingWentWrong')}
          </AlertMessage>
        )}

        {branchesFetchingStatus === 'succeeded' && repositoryId
          && (platformBranches === null || platformBranches.length === 0)
          && (
            <AlertMessage>
              {i18n.t(`${i18nBase}.errors.noBranchesFound`)}
            </AlertMessage>
          )}

        {branchesFetchingStatus === 'succeeded' && repositoryId
          && (platformBranches || platformBranches.length > 0)
          && (
            <Select
              formatOptionLabel={(option) => BranchOption(option)}
              id="git-branch"
              name="gitBranch"
              onChange={(selected) => onChange(selected)}
              options={platformBranches}
              styles={{
                control: (css) => ({
                  ...css,
                  width: 300
                })
              }}
              value={preSelectedBranch || selectedBranch || defaultBranch}
            />
          )}
      </div>
    )
  }
}

GitOptionImportBranch.propTypes = {
  branches: PropTypes.shape({
    gitlab: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    }))
  }),
  branchesFetchingError: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  branchesFetchingStatus: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  repositoryId: PropTypes.number,
  selectedBranch: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired
  }),
  selectedBranchName: PropTypes.string
}

GitOptionImportBranch.defaultProps = {
  branches: null,
  branchesFetchingError: null,
  branchesFetchingStatus: 'idle',
  repositoryId: null,
  selectedBranch: null,
  selectedBranchName: null
}

const mapStateToProps = ({
  integrations: {
    branchesFetchingError,
    branchesFetchingStatus,
    branches
  }
}) => ({
  branchesFetchingError,
  branchesFetchingStatus,
  branches
})

export default connect(mapStateToProps)(GitOptionImportBranch)
