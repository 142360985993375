import React from 'react'
import { Alert } from 'reactstrap'
import PropTypes from 'prop-types'

const AlertMessage = ({
  alertClassName,
  centered,
  className,
  children,
  color,
  onDismiss,
  isOpen
}) => {
  let classNames = [className]

  if (centered) classNames = [...classNames, 'justify-content-center']

  return (
    <div className={`d-flex ${classNames.join(' ')}`}>
      <Alert
        className={alertClassName}
        color={color}
        isOpen={onDismiss ? isOpen : true}
        toggle={onDismiss ? () => onDismiss() : null}
      >
        <span>
          {children}
        </span>
      </Alert>
    </div>
  )
}

AlertMessage.propTypes = {
  alertClassName: PropTypes.string,
  centered: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.element,
    PropTypes.string
  ]).isRequired,
  className: PropTypes.string,
  color: PropTypes.string,
  isOpen: PropTypes.bool,
  onDismiss: PropTypes.func
}

AlertMessage.defaultProps = {
  alertClassName: '',
  centered: true,
  className: '',
  color: 'danger',
  isOpen: false,
  onDismiss: null
}

export default AlertMessage
