import React from 'react'
import {
  Button,
  Card,
  CardBody
} from 'reactstrap'
import PropTypes from 'prop-types'

import PlusIcon from '../../svgs/PlusIcon'

const ProjectCreationCard = ({ onClick, title }) => (
  <Card name="create" style={{ width: '250px', height: '450px' }}>
    <CardBody className="d-flex flex-column justify-content-center align-items-center">
      <Button
        color="link"
        onClick={(event) => onClick(event)}
        title={title}
      >
        <PlusIcon size={5} />
      </Button>
    </CardBody>
  </Card>
)

ProjectCreationCard.propTypes = {
  onClick: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired
}

export default ProjectCreationCard
