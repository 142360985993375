import React, { useState } from 'react'
import { Container } from 'reactstrap'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import FilterHelpers from '../../../helpers/FilterHelpers'
import i18n from '../../../config/i18n'
import PageHeader from '../../../components/PageHeader'
import projectActions from '../actions'
import projectStringsAlertsActions from '../ProjectStringsAlerts/actions'
import StringIssuesTable from './StringIssuesTable'
import UpdateStringLocale from './UpdateStringLocale'

const ImportIssues = ({
  dispatch,
  location: { pathname, search },
  match: { params: { slug } },
  project,
  projectFetchingStatus,
  projectStringsAlerts,
  projectStringsAlertsCurrentPage,
  projectStringsAlertsFetchingStatus,
  projectStringsAlertsFilteringStatus,
  projectStringsAlertsTotalCount,
  projectStringsAlertsTotalPages
}) => {
  const pageParams = new URLSearchParams(search)
  const page = pageParams.get('page')
  const pageFilterColumn = pageParams.get('filterColumn')
  const pageFilterValue = pageParams.get('filterValue')

  const columnObjectFor = (column) => (
    FilterHelpers.filterOptions.find((item) => item.value === column)
      || FilterHelpers.defaultFilterOptions
  )

  const [allSelected, setAllSelected] = useState(false)
  const [currentPage, setCurrentPage] = useState(page || null)
  const [filter, setFilter] = useState(pageFilterValue || null)
  const [filterColumn, setFilterColumn] = useState(
    pageFilterColumn === null
      ? columnObjectFor('path')
      : columnObjectFor(pageFilterColumn)
  )
  const [locale, setLocale] = useState(null)
  const [selectedProjectStrings, setSelectedProjectStrings] = useState([])

  const finalFilter = {
    column: filterColumn.value,
    value: filter
  }

  const buildPageParams = (params = {}) => {
    let nextSearch = ''

    if (Object.keys(params).length > 0
        && Object.values(params).every((value) => value === null) === false
    ) {
      nextSearch = new URLSearchParams({
        filterColumn: params.finalFilter?.column || '',
        filterValue: params.finalFilter?.value || '',
        page: params.page || currentPage
      })
    }

    return nextSearch.toString()
  }

  const updatePageUrl = (searchParams) => (
    window.history.pushState(
      null,
      '',
      `${pathname}?${buildPageParams(searchParams)}`
    )
  )

  const fetchAll = (params = {}) => {
    dispatch(projectStringsAlertsActions.fetchAll(
      slug,
      params.page || currentPage
    ))

    updatePageUrl({ page: params.page || currentPage })
  }

  const filterOn = (params = {}) => {
    dispatch(projectStringsAlertsActions.filter(
      slug,
      finalFilter,
      params.page || currentPage || 1
    ))

    updatePageUrl({
      finalFilter: params.finalFilter || finalFilter,
      page: params.page || currentPage || 1
    })
  }

  const updateFilter = (event) => {
    if (Object.keys(event).includes('target')) {
      const { target: { value } } = event
      setFilter(value)
    } else {
      setFilterColumn(event)
      const { value } = event
      finalFilter.column = value
    }
  }

  const moveToPage = (selected) => {
    setCurrentPage(selected)
    if (filter) {
      filterOn({ page: selected })
    } else {
      fetchAll({ page: selected })
    }
  }

  const onFilterSubmit = () => {
    filterOn({ finalFilter })
  }

  const toggleAllSelect = (selected) => {
    if (allSelected) {
      setAllSelected(false)
      setSelectedProjectStrings([])
    } else {
      setAllSelected(true)
      setSelectedProjectStrings(selected.map((item) => item.id))
    }
  }

  const toggleSelectProjectString = (id) => {
    if (allSelected === false) {
      if (selectedProjectStrings.includes(id)) {
        const arr = selectedProjectStrings.filter((item) => item !== id)
        setSelectedProjectStrings(arr)
      } else {
        setSelectedProjectStrings([...selectedProjectStrings, id])
      }
    }
  }

  if (projectFetchingStatus === 'idle') dispatch(projectActions.fetch(slug))

  if (pageFilterColumn && pageFilterValue) {
    if (projectStringsAlertsFilteringStatus === 'idle') filterOn()
  } else if (projectStringsAlertsFetchingStatus === 'idle') fetchAll()

  const i18nBase = 'ProjectsPage.ImportIssues'

  return (
    <Container fluid className="d-flex flex-column flex-fill mt-3">
      <PageHeader
        projectSlug={slug}
        title={i18n.t(`${i18nBase}.title`)}
      />

      <div className="d-flex flex-column flex-fill mt-3">
        <div className="d-flex flex-row flex-fill">
          <StringIssuesTable
            filterByText={filter}
            filteredList={projectStringsAlerts}
            filterColumn={filterColumn}
            moveToPage={(selected) => moveToPage(selected)}
            onFilterSubmit={(selected) => onFilterSubmit(selected)}
            paginationCurrentPage={projectStringsAlertsCurrentPage}
            paginationTotalPages={projectStringsAlertsTotalPages}
            projectFetchingStatus={projectFetchingStatus}
            projectSlug={slug}
            projectStringsAlertsFetchingStatus={projectStringsAlertsFetchingStatus}
            projectStringsAlertsFilteringStatus={projectStringsAlertsFilteringStatus}
            setFilterByText={(event) => updateFilter(event)}
            setFilterColumn={(selected) => updateFilter(selected)}
            selectedProjectStrings={selectedProjectStrings}
            toggleAllSelect={(selected) => toggleAllSelect(selected)}
            toggleSelectProjectString={(selected) => toggleSelectProjectString(selected)}
            totalCount={projectStringsAlertsTotalCount}
          />

          {project && (
            <UpdateStringLocale
              onLocaleChange={(selected) => setLocale(selected)}
              projectId={project.id}
              selectedProjectStrings={selectedProjectStrings}
              value={locale}
            />
          )}
        </div>
      </div>
    </Container>
  )
}

ImportIssues.propTypes = {
  dispatch: PropTypes.func.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      slug: PropTypes.string
    })
  }),
  project: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  }),
  projectFetchingStatus: PropTypes.string,
  projectStringsAlerts: PropTypes.arrayOf(PropTypes.shape()),
  projectStringsAlertsCurrentPage: PropTypes.number,
  projectStringsAlertsFetchingStatus: PropTypes.string,
  projectStringsAlertsFilteringStatus: PropTypes.string,
  projectStringsAlertsTotalCount: PropTypes.number,
  projectStringsAlertsTotalPages: PropTypes.number
}

ImportIssues.defaultProps = {
  location: {
    pathname: null
  },
  match: {
    params: {
      slug: null
    }
  },
  project: null,
  projectFetchingStatus: 'idle',
  projectStringsAlerts: null,
  projectStringsAlertsCurrentPage: null,
  projectStringsAlertsFetchingStatus: 'idle',
  projectStringsAlertsFilteringStatus: 'idle',
  projectStringsAlertsTotalCount: null,
  projectStringsAlertsTotalPages: null
}

const mapStateToProps = ({
  projects: {
    fetchingStatus: projectFetchingStatus,
    item: project
  },
  projectStringsAlerts: {
    fetchingStatus: projectStringsAlertsFetchingStatus,
    filteringStatus: projectStringsAlertsFilteringStatus,
    itemsCurrentPage: projectStringsAlertsCurrentPage,
    items: projectStringsAlerts,
    itemsTotalCount: projectStringsAlertsTotalCount,
    itemsTotalPages: projectStringsAlertsTotalPages
  }
}) => ({
  project,
  projectFetchingStatus,
  projectStringsAlerts,
  projectStringsAlertsCurrentPage,
  projectStringsAlertsFetchingStatus,
  projectStringsAlertsFilteringStatus,
  projectStringsAlertsTotalCount,
  projectStringsAlertsTotalPages
})

export default connect(mapStateToProps)(ImportIssues)
