import RestfulClient from 'restful-json-api-client'

export default class ProjectStringsAlertsApi extends RestfulClient {
  constructor(slug) {
    super(`/api/projects/${slug}`, { resource: 'strings_alerts' })
  }

  keep(id) {
    return this.request('POST', { path: `${id}/keep` })
  }
}
