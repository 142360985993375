import dotProp from 'dot-prop-immutable'

import projectsConstants from '../constants'
import projectStringsAlertsConstants from './constants'

const initialState = {
  acceptingError: null,
  acceptingStatus: 'idle',

  checkingError: null,
  checkingStatus: 'idle',

  count: null, // Amount of ProjectStrings with an alert

  fetchingError: null,
  fetchingStatus: 'idle',

  filteringError: null,
  filteringStatus: 'idle',

  // This becomes `true` when there's at least one ProjectStrings with an alert
  hasAny: false,

  // Pagination current page number
  itemsCurrentPage: null,
  items: null,
  // Number of items, no matter the filter query
  itemsTotalCount: null,
  // Pagination total page count
  itemsTotalPages: null,

  keepingError: null,
  keepingStatus: 'idle',

  rejectingError: null,
  rejectingStatus: 'idle'
}

export default (state = initialState, action) => {
  const error = action.error ? (action.error.data || action.error.message) : null
  let newState

  switch (action.type) {
    case projectStringsAlertsConstants.ACCEPT_REQUEST:
      newState = dotProp.set(state, 'acceptingError', null)
      return dotProp.set(newState, 'acceptingStatus', 'accepting')
    case projectStringsAlertsConstants.ACCEPT_FAILURE:
      newState = dotProp.set(state, 'acceptingError', error)
      return dotProp.set(newState, 'acceptingStatus', 'failed')
    case projectStringsAlertsConstants.ACCEPT_SUCCESS: {
      newState = dotProp.set(state, 'acceptingError', null)
      newState = dotProp.set(newState, 'acceptingStatus', 'succeeded')

      const index = state.items.findIndex((item) => item.id === action.id)

      if (index > -1) return dotProp.delete(newState, `items.${index}`)

      return newState
    }

    case projectsConstants.FIXES_ALERT_SUCCESS: {
      newState = { ...state }
      action.selectedIds.forEach((selectedId) => {
        const index = newState.items.findIndex((item) => item.id === selectedId)
        if (index > -1) newState = dotProp.delete(newState, `items.${index}`)
      })

      return newState
    }

    case projectStringsAlertsConstants.CHECK_IF_ANY_REQUEST:
      newState = dotProp.set(state, 'checkingError', null)
      return dotProp.set(newState, 'checkingStatus', 'checking')
    case projectStringsAlertsConstants.CHECK_IF_ANY_FAILURE:
      newState = dotProp.set(state, 'checkingError', error)
      return dotProp.set(newState, 'checkingStatus', 'failed')
    case projectStringsAlertsConstants.CHECK_IF_ANY_SUCCESS:
      newState = dotProp.set(state, 'checkingError', null)
      newState = dotProp.set(newState, 'checkingStatus', 'succeeded')
      newState = dotProp.set(newState, 'count', action.data.count)
      return dotProp.set(newState, 'hasAny', action.data.has_any)

    case projectStringsAlertsConstants.FETCH_ALL_REQUEST:
      newState = dotProp.set(state, 'fetchingError', null)
      return dotProp.set(newState, 'fetchingStatus', 'fetching')
    case projectStringsAlertsConstants.FETCH_ALL_FAILURE:
      newState = dotProp.set(state, 'fetchingError', error)
      return dotProp.set(newState, 'fetchingStatus', 'failed')
    case projectStringsAlertsConstants.FETCH_ALL_SUCCESS:
      newState = dotProp.set(state, 'fetchingError', null)
      newState = dotProp.set(newState, 'fetchingStatus', 'succeeded')
      newState = dotProp.set(
        newState,
        'itemsCurrentPage',
        action.stringsAlerts.current_page
      )
      newState = dotProp.set(
        newState,
        'items',
        action.stringsAlerts.data
      )
      newState = dotProp.set(
        newState,
        'itemsTotalCount',
        action.stringsAlerts.total_count
      )
      return dotProp.set(
        newState,
        'itemsTotalPages',
        action.stringsAlerts.total_pages
      )

    case projectStringsAlertsConstants.FILTER_REQUEST:
      newState = dotProp.set(state, 'filteringError', null)
      return dotProp.set(newState, 'filteringStatus', 'filtering')
    case projectStringsAlertsConstants.FILTER_FAILURE:
      newState = dotProp.set(state, 'filteringError', error)
      return dotProp.set(newState, 'filteringStatus', 'failed')
    case projectStringsAlertsConstants.FILTER_SUCCESS:
      newState = dotProp.set(state, 'filteringError', null)
      newState = dotProp.set(newState, 'filteringStatus', 'succeeded')
      newState = dotProp.set(
        newState,
        'itemsCurrentPage',
        action.stringsAlerts.current_page
      )
      newState = dotProp.set(
        newState,
        'items',
        action.stringsAlerts.data
      )
      newState = dotProp.set(
        newState,
        'itemsTotalCount',
        action.stringsAlerts.total_count
      )
      return dotProp.set(
        newState,
        'itemsTotalPages',
        action.stringsAlerts.total_pages
      )

    case projectStringsAlertsConstants.KEEP_REQUEST:
      newState = dotProp.set(state, 'keepingError', null)
      return dotProp.set(newState, 'keepingStatus', 'keeping')
    case projectStringsAlertsConstants.KEEP_FAILURE:
      newState = dotProp.set(state, 'keepingError', error)
      return dotProp.set(newState, 'keepingStatus', 'failed')
    case projectStringsAlertsConstants.KEEP_SUCCESS: {
      newState = dotProp.set(state, 'keepingError', null)
      newState = dotProp.set(newState, 'keepingStatus', 'succeeded')

      const { kept_id: keptId, destroyed_ids: destroyedIds } = action.response

      destroyedIds.forEach((id) => {
        const index = newState.items.findIndex((item) => item.id === id)
        if (index > -1) {
          newState = dotProp.delete(newState, `items.${index}`)
        }
      })

      console.log('keptId', keptId)

      const string = newState.items.find((item) => item.id === keptId)
      const index = newState.items.findIndex((item) => item.id === keptId)

      // When the ProjectString had `duplicated: true` and has `conflict: true`
      // now that the duplicate has been fixed, show the line in conflict.
      if (string.conflict) {
        newState = dotProp.set(newState, `items.${index}.duplicated`, false)
      } else {
        // Otherwise we're done with that ProjectString
        newState = dotProp.delete(newState, `items.${index}`)
      }

      return newState
    }

    case projectStringsAlertsConstants.REJECT_REQUEST:
      newState = dotProp.set(state, 'rejectingError', null)
      return dotProp.set(newState, 'rejectingStatus', 'rejecting')
    case projectStringsAlertsConstants.REJECT_FAILURE:
      newState = dotProp.set(state, 'rejectingError', error)
      return dotProp.set(newState, 'rejectingStatus', 'failed')
    case projectStringsAlertsConstants.REJECT_SUCCESS: {
      newState = dotProp.set(state, 'rejectingError', null)
      newState = dotProp.set(newState, 'rejectingStatus', 'succeeded')

      const index = state.items.findIndex((item) => item.id === action.id)

      if (index > -1) return dotProp.delete(newState, `items.${index}`)

      return newState
    }

    default:
      return state
  }
}
