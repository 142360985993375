import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import i18n from '../config/i18n'
import sessionsActions from '../devise/sessions/actions'

const LogoutPage = ({ dispatch, loggingStatus }) => {
  if (loggingStatus !== 'logout') {
    dispatch(sessionsActions.logout())
  }

  return (
    <div className="d-flex flex-column flex-fill justify-content-center align-items-center">
      {i18n.t('LogoutPage.message')}
    </div>
  )
}

LogoutPage.propTypes = {
  dispatch: PropTypes.func.isRequired,
  loggingStatus: PropTypes.string
}

LogoutPage.defaultProps = {
  loggingStatus: 'idle'
}

const mapStateToProps = ({
  authentication: {
    loggingStatus
  }
}) => ({
  loggingStatus
})

export default connect(mapStateToProps)(LogoutPage)
