import React, { useState } from 'react'
import PropTypes from 'prop-types'
import i18n from '../../../config/i18n'

const TableHeader = ({ onClickToggleAllSelect }) => {
  const [isOpen, setIsOpen] = useState(false)
  const toggle = () => {
    onClickToggleAllSelect()
    setIsOpen(!isOpen)
  }

  return (
    <tr>
      <th>
        <input
          name="select-all-alerts"
          type="checkbox"
          checked={isOpen}
          onChange={() => toggle()}
        />
      </th>
      <th>{i18n.t('ProjectsPage.TableHeader.issueType')}</th>
      <th>{i18n.t('ProjectsPage.TableHeader.key')}</th>
      <th>{i18n.t('ProjectsPage.TableHeader.value')}</th>
      <th>{i18n.t('ProjectsPage.TableHeader.newValue')}</th>
      <th>{i18n.t('ProjectsPage.TableHeader.locale')}</th>
      <th aria-label="actions" />
    </tr>
  )
}

TableHeader.propTypes = {
  onClickToggleAllSelect: PropTypes.func.isRequired
}

export default TableHeader
