import React from 'react'
import { connect } from 'react-redux'
import { List } from 'reactstrap'
import PropTypes from 'prop-types'
import moment from 'moment'
import TextareaAutosize from 'react-textarea-autosize'
import { v1 as uuidv1 } from 'uuid'

import i18n from '../../config/i18n'

const TranslationRow = ({
  currentLocale,
  onBlur,
  onChange,
  project,
  translation: {
    created_at: createdAt,
    id,
    key,
    relative_key: relativeKey,
    relative_path: relativePath
  },
  translationsFromReferenceLanguages,
  value
}) => {
  const projectReferenceLanguages = project?.reference_languages.filter(
    (item) => item !== currentLocale
  )

  const valueFromReferenceLanguage = (locale) => (
    translationsFromReferenceLanguages.find(
      (item) => item.locale === locale && item.relative_key === relativeKey
    )?.value
  )

  const EmptyValueComponent = (
    <i className="text-muted">{i18n.t('global.empty')}</i>
  )

  const SpacedValueComponent = (
    <i className="text-muted">{i18n.t('global.space')}</i>
  )

  const NullValueComponent = (
    <i className="text-muted">{i18n.t('global.null')}</i>
  )

  const UnknownValueComponent = (type) => (
    <i className="text-muted">{`${i18n.t('global.unknown')} ${type}`}</i>
  )

  const displayValueContentFrom = (stringValue) => {
    if (Array.isArray(stringValue)) {
      return (
        <List className="mb-0" type="unstyled">
          {stringValue.map((item) => <li key={uuidv1()}>{item}</li>)}
        </List>
      )
    }

    if (typeof stringValue === 'string' && stringValue !== null) {
      if (stringValue.match(/^\s+$/)) return SpacedValueComponent

      return stringValue || EmptyValueComponent
    }

    if (typeof stringValue === 'number') return stringValue.toString()

    if (typeof stringValue === 'boolean') return stringValue.toString()

    if (stringValue === null) return NullValueComponent

    return UnknownValueComponent(typeof stringValue)
  }

  const i18nBase = 'TranslationsPage'

  return (
    <tr>
      <td>
        <div className="d-flex flex-column">
          {key}
          <small className="text-muted">
            {i18n.t(`${i18nBase}.keyPathAndDate`, {
              relativePath,
              since: moment(createdAt).format('LL')
            })}
          </small>
        </div>
      </td>
      {projectReferenceLanguages.map((language) => (
        <td key={uuidv1()}>
          {displayValueContentFrom(valueFromReferenceLanguage(language)) || (
            <div className="font-italic text-muted">
              {i18n.t(`${i18nBase}.emptyValue`)}
            </div>
          )}
        </td>
      ))}

      <td>
        <TextareaAutosize
          className="form-control"
          onBlur={() => onBlur()}
          onChange={
            ({ target: { value: eventValue } }) => onChange(eventValue, id)
          }
          placeholder={i18n.t(`${i18nBase}.notYetTranslated`)}
          value={value}
        />

        {Array.isArray(value) && (
          <small className="text-muted">
            {i18n.t(`${i18nBase}.arrayInstructions`)}
          </small>
        )}
      </td>
    </tr>
  )
}

TranslationRow.propTypes = {
  currentLocale: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  translation: PropTypes.shape({
    created_at: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
    relative_key: PropTypes.string.isRequired,
    relative_path: PropTypes.string.isRequired
  }).isRequired,
  project: PropTypes.shape({
    reference_languages: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired
  }).isRequired,
  translationsFromReferenceLanguages: PropTypes.arrayOf(PropTypes.shape({
    relative_key: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.bool,
      PropTypes.number,
      PropTypes.string
    ]).isRequired
  })),
  value: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.bool,
    PropTypes.number,
    PropTypes.string
  ])
}

TranslationRow.defaultProps = {
  onBlur: () => {},
  onChange: () => {},
  translationsFromReferenceLanguages: null,
  value: ''
}

const mapStateToProps = ({
  projects: {
    item: project
  },
  translations: {
    itemsReferenceLanguages: translationsFromReferenceLanguages
  }
}) => ({
  project,
  translationsFromReferenceLanguages
})

export default connect(mapStateToProps)(TranslationRow)
