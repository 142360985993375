import React from 'react'
import {
  Card,
  CardBody,
  CardHeader,
  Collapse
} from 'reactstrap'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import GitOptionCommitSha from './GitOptionCommitSha'
import GitOptionImportBranch from './GitOptionImportBranch'
import i18n from '../../config/i18n'

const GitOptions = ({
  commitSha,
  isOpen,
  onBranchChange,
  onCommitShaChange,
  repositoryId,
  selectedBranch,
  selectedBranchName,
  toggle
}) => {
  const i18nBase = 'ProjectsPage.GitOptions'

  return (
    <Card className={isOpen ? '' : 'border-0'}>
      <CardHeader
        className={`p-2 ${isOpen ? '' : 'border'}`}
        onClick={() => toggle()}
        style={{
          cursor: 'pointer'
        }}
      >
        <div className="text-muted font-weight-bold">
          {i18n.t(`${i18nBase}.title`)}
        </div>
      </CardHeader>
      <CardBody className={isOpen ? '' : 'p-0'}>
        <Collapse
          className={isOpen ? '' : 'border-0'}
          isOpen={isOpen}
        >
          <GitOptionImportBranch
            onChange={(selected) => onBranchChange(selected)}
            repositoryId={repositoryId}
            selectedBranch={selectedBranch}
            selectedBranchName={selectedBranchName}
          />
          <GitOptionCommitSha
            onChange={(selected) => onCommitShaChange(selected)}
            commitSha={commitSha}
          />
        </Collapse>
      </CardBody>
    </Card>
  )
}

GitOptions.propTypes = {
  commitSha: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  onBranchChange: PropTypes.func.isRequired,
  onCommitShaChange: PropTypes.func.isRequired,
  repositoryId: PropTypes.number,
  selectedBranch: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired
  }),
  selectedBranchName: PropTypes.string,
  toggle: PropTypes.func.isRequired
}

GitOptions.defaultProps = {
  commitSha: null,
  repositoryId: null,
  selectedBranch: null,
  selectedBranchName: null
}

const mapStateToProps = ({
  integrations: {
    branchesFetchingError,
    branchesFetchingStatus,
    branches
  }
}) => ({
  branchesFetchingError,
  branchesFetchingStatus,
  branches
})

export default connect(mapStateToProps)(GitOptions)
