import ApiUtils from '../helpers/ApiUtils'
import IntegrationsApi from '../apis/IntegrationsApi'
import ProjectBranchesApi from '../apis/ProjectBranchesApi'
import RepositoriesApi from '../apis/RepositoriesApi'

const activate = (platform, token) => (
  new IntegrationsApi().create({
    platform,
    token
  }).then(ApiUtils.handleResponse)
)

const checkPaths = (platform, repositoryId, paths) => (
  new IntegrationsApi().checkPaths(
    platform,
    repositoryId,
    paths
  ).then(ApiUtils.handleResponse)
)

const fetchAll = () => (
  new IntegrationsApi().all().then(ApiUtils.handleResponse)
)

const fetchBranches = (projectId, platform) => (
  new ProjectBranchesApi(projectId).get({
    query: { platform }
  }).then(ApiUtils.handleResponse)
)

const fetchRepositories = (platform) => (
  new RepositoriesApi().get({
    query: { platform }
  }).then(ApiUtils.handleResponse)
)

const update = (id, token) => (
  new IntegrationsApi().update(id, {
    token
  }).then(ApiUtils.handleResponse)
)

export default {
  activate,
  checkPaths,
  fetchAll,
  fetchBranches,
  fetchRepositories,
  update
}
