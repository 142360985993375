import React from 'react'
import { Table } from 'reactstrap'
import PropTypes from 'prop-types'

import i18n from '../../../config/i18n'
import IssuesTableFilter from './IssuesTableFilter'
import PageLoader from '../../../components/PageLoader'
import ProjectStringAlertRow from './ProjectStringAlertRow'
import SearchingOverlay from '../../../components/SearchingOverlay'
import TableHeader from './TableHeader'
import TablePagination from '../../../components/TablePagination'
import WellDone from '../../../components/WellDone'

const StringIssuesTable = ({
  filterByText,
  filteredList,
  filterColumn,
  moveToPage,
  onFilterSubmit,
  paginationCurrentPage,
  paginationTotalPages,
  projectFetchingStatus,
  projectSlug,
  projectStringsAlertsFetchingStatus,
  projectStringsAlertsFilteringStatus,
  setFilterByText,
  setFilterColumn,
  selectedProjectStrings,
  toggleAllSelect,
  toggleSelectProjectString,
  totalCount
}) => (
  <div className="d-flex flex-column flex-fill">
    {projectStringsAlertsFetchingStatus === 'fetching' && (
      <div className="d-flex flex-column flex-fill justify-content-center align-items-center">
        <PageLoader
          message={i18n.t('ProjectsPage.ProjectStringsAlerts.fetching')}
        />
      </div>
    )}

    {[projectStringsAlertsFetchingStatus,
      projectStringsAlertsFilteringStatus].includes('succeeded')
      && totalCount === 0
      && (
        <WellDone />
      )}

    {[projectStringsAlertsFetchingStatus,
      projectStringsAlertsFilteringStatus].includes('succeeded')
      && totalCount > 0
      && (
        <IssuesTableFilter
          filterByText={filterByText}
          filterColumn={filterColumn}
          onSubmit={() => onFilterSubmit()}
          setFilterByText={(event) => setFilterByText(event)}
          setFilterColumn={(selected) => setFilterColumn(selected)}
        />
      )}

    {projectStringsAlertsFilteringStatus === 'filtering'
      && (projectFetchingStatus !== 'succeeded'
          || [projectStringsAlertsFetchingStatus,
            projectStringsAlertsFilteringStatus].includes('succeeded') === false)
      && (
        <div className="d-flex flex-column flex-fill justify-content-center align-items-center">
          <PageLoader
            message={i18n.t('ProjectsPage.ProjectStringsAlerts.fetching')}
          />
        </div>
      )}

    {projectFetchingStatus === 'succeeded'
      && [projectStringsAlertsFetchingStatus,
        projectStringsAlertsFilteringStatus].includes('succeeded')
      && totalCount > 0
      && (
        <div className="position-relative">
          <Table
            bordered
          >
            <thead>
              <TableHeader
                onClickToggleAllSelect={() => toggleAllSelect(filteredList)}
              />
            </thead>
            <tbody>
              {filteredList.map((projectString, index) => (
                <ProjectStringAlertRow
                  rowNumber={index}
                  key={projectString.id}
                  projectSlug={projectSlug}
                  projectString={projectString}
                  checkedInList={(selectedProjectStrings || []).includes(projectString.id)}
                  toggleProjectString={(id) => toggleSelectProjectString(id)}
                />
              ))}
            </tbody>
          </Table>

          {projectStringsAlertsFilteringStatus === 'filtering' && (
            <SearchingOverlay />
          )}
        </div>
      )}

    <div className="d-flex flex-row justify-content-center">
      <TablePagination
        currentPage={paginationCurrentPage}
        onPaginationClick={(page) => moveToPage(page)}
        totalPages={paginationTotalPages}
      />
    </div>
  </div>
)

StringIssuesTable.propTypes = {
  filterByText: PropTypes.string,
  filterColumn: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired
  }),
  filteredList: PropTypes.arrayOf(PropTypes.shape()),
  moveToPage: PropTypes.func.isRequired,
  onFilterSubmit: PropTypes.func,
  paginationCurrentPage: PropTypes.number,
  paginationTotalPages: PropTypes.number,
  projectFetchingStatus: PropTypes.string,
  projectSlug: PropTypes.string.isRequired,
  projectStringsAlertsFetchingStatus: PropTypes.string,
  projectStringsAlertsFilteringStatus: PropTypes.string,
  setFilterByText: PropTypes.func.isRequired,
  setFilterColumn: PropTypes.func.isRequired,
  selectedProjectStrings: PropTypes.arrayOf(PropTypes.string),
  toggleAllSelect: PropTypes.func.isRequired,
  toggleSelectProjectString: PropTypes.func.isRequired,
  totalCount: PropTypes.number
}

StringIssuesTable.defaultProps = {
  filterByText: null,
  filterColumn: null,
  filteredList: null,
  onFilterSubmit: () => {},
  paginationCurrentPage: 1,
  paginationTotalPages: 1,
  projectFetchingStatus: 'idle',
  projectStringsAlertsFetchingStatus: 'idle',
  projectStringsAlertsFilteringStatus: 'idle',
  selectedProjectStrings: null,
  totalCount: null
}

export default StringIssuesTable
