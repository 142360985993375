import ApiUtils from '../../../helpers/ApiUtils'
import ProjectExportsApi from '../../../apis/ProjectExportsApi'

const checkIfAny = (projectId) => (
  new ProjectExportsApi(projectId).diff({
    check: true
  }).then(ApiUtils.handleResponse)
)

const create = (projectId) => (
  new ProjectExportsApi(projectId).create().then(ApiUtils.handleResponse)
)

const fetch = (projectId, id) => (
  new ProjectExportsApi(projectId).get({ id }).then(ApiUtils.handleResponse)
)

const fetchAll = (projectId, page = null) => (
  new ProjectExportsApi(projectId).get({
    query: {
      page
    }
  }).then(ApiUtils.handleResponse)
)

const fetchDiff = (projectId, page = null) => (
  new ProjectExportsApi(projectId).diff({ page }).then(ApiUtils.handleResponse)
)

const fetchExportDiff = (projectId, id, page = null) => (
  new ProjectExportsApi(projectId).getDiff(id, { page }).then(
    ApiUtils.handleResponse
  )
)

const retry = (projectId, id) => (
  new ProjectExportsApi(projectId).retry(id).then(ApiUtils.handleResponse)
)

export default {
  checkIfAny,
  create,
  fetch,
  fetchAll,
  fetchDiff,
  fetchExportDiff,
  retry
}
