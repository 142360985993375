import React from 'react'
import PropTypes from 'prop-types'

import TableFiler from '../../../components/TableFiler'
import i18n from '../../../config/i18n'

const IssuesTableFilter = ({
  filterByText,
  filterColumn,
  onSubmit,
  setFilterByText,
  setFilterColumn
}) => {
  const i18nBase = 'ProjectsPage.ImportIssues'

  return (
    <div className="d-flex flex-row align-items-center px-3 py-2 bg-light">
      <div className="d-flex flex-column mr-3">
        {i18n.t(`${i18nBase}.ProjectStringIssues.filter.title`)}
      </div>

      <TableFiler
        filterColumn={filterColumn}
        filterQuery={filterByText}
        onColumnChange={(selected) => setFilterColumn(selected)}
        onQueryChange={(event) => setFilterByText(event)}
        onSubmit={() => onSubmit()}
      />
    </div>
  )
}

IssuesTableFilter.propTypes = {
  filterByText: PropTypes.string,
  filterColumn: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired
  }),
  onSubmit: PropTypes.func,
  setFilterByText: PropTypes.func.isRequired,
  setFilterColumn: PropTypes.func.isRequired
}

IssuesTableFilter.defaultProps = {
  filterByText: null,
  filterColumn: null,
  onSubmit: () => {}
}

export default IssuesTableFilter
