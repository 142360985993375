import React from 'react'
import PropTypes from 'prop-types'

import FormInputField from '../FormInputField'
import i18n from '../../config/i18n'

const GitOptionCommitSha = ({ commitSha, onChange }) => {
  const i18nBase = 'ProjectsPage.GitOptions.options.commitSha'

  return (
    <div className="d-flex flex-row justify-content-between">
      <div className="d-flex flex-column justify-content-center">
        {i18n.t(`${i18nBase}.heading`)}
      </div>

      <FormInputField
        inputId="commit-sha"
        inputName="commitSha"
        intputStyle={{
          width: '300px'
        }}
        onChange={({ target: { value } }) => onChange(value)}
        value={commitSha || ''}
        withoutFormGroup
      />
    </div>
  )
}

GitOptionCommitSha.propTypes = {
  commitSha: PropTypes.string,
  onChange: PropTypes.func.isRequired
}

GitOptionCommitSha.defaultProps = {
  commitSha: null
}

export default GitOptionCommitSha
