import React, { useState } from 'react'
import {
  Button,
  Container
} from 'reactstrap'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { useStateWithCallbackLazy } from 'use-state-with-callback'

import i18n from '../../config/i18n'
import RemoveConfirmationModal from './RemoveConfirmationModal'
import UserModal from './UserModal'
import UsersTable from './UsersTable'

const UsersPage = ({ role }) => {
  const [editModalOpen, setEditModalOpen] = useState(false)
  const [userId, setUserId] = useStateWithCallbackLazy(null)
  const editModalToggle = () => setEditModalOpen(!editModalOpen)
  const editModalToggleAndResetUserId = () => {
    setUserId(null, () => setEditModalOpen(!editModalOpen))
  }

  const [invitationModalOpen, setInvitationModalOpen] = useState(false)
  const invitationModalToggle = () => {
    if (userId === null) {
      setInvitationModalOpen(!invitationModalOpen)
    } else {
      setUserId(null, () => setInvitationModalOpen(!invitationModalOpen))
    }
  }

  const [removeModalOpen, setRemoveModalOpen] = useState(false)
  const [removeUserId, setRemoveUserId] = useState('')
  const removeModalToggle = () => setRemoveModalOpen(!removeModalOpen)

  return (
    <Container className="mt-3 d-flex flex-column flex-fill">
      <div className="d-flex flex-row justify-content-between">
        <h2>{i18n.t('UsersPage.title')}</h2>
        {role === 'admin' && (
          <div className="d-flex flex-column justify-content-center">
            <Button
              color="primary"
              id="invite-new-user"
              onClick={() => setInvitationModalOpen(true)}
            >
              {i18n.t('UsersPage.buttons.invite')}
            </Button>
          </div>
        )}
      </div>

      <div className="d-flex flex-column flex-fill">
        <UsersTable
          onUserEdit={(id) => {
            setUserId(id)
            editModalToggle()
          }}
          onUserRemove={(id) => {
            setRemoveUserId(id)
            removeModalToggle()
          }}
        />
      </div>

      <UserModal
        id={userId}
        modalIsOpen={editModalOpen || invitationModalOpen}
        onToggle={() => {
          if (editModalOpen) editModalToggleAndResetUserId()
          if (invitationModalOpen) invitationModalToggle()
        }}
      />

      <RemoveConfirmationModal
        id={removeUserId}
        modalIsOpen={removeModalOpen}
        onToggle={() => removeModalToggle()}
      />
    </Container>
  )
}

UsersPage.propTypes = {
  role: PropTypes.string.isRequired
}

const mapStateToProps = ({
  authentication: {
    role
  }
}) => ({
  role
})

export default connect(mapStateToProps)(UsersPage)
