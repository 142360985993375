import RestfulClient from 'restful-json-api-client'

export default class ProjectsApi extends RestfulClient {
  constructor() {
    super('/api', { resource: 'projects' })
  }

  checkNameAvailablity(name, excludeProjectId = null) {
    const body = { name }

    if (excludeProjectId) body.project_id = excludeProjectId

    return this.request('POST', { path: 'check', body })
  }

  enqueueStatsRefresh(slug) {
    return this.request('POST', { path: `${slug}/refresh-stats` })
  }

  fixesAlert(projectId, selectedIds, locale) {
    return this.request('PUT', {
      path: `${projectId}/fixes_alert_project`,
      body: {
        ids: selectedIds,
        locale
      }
    })
  }

  forceImport(id, allFiles, gitOptions) {
    return this.request('POST', {
      path: 'force_import',
      body: {
        all_files: allFiles,
        git_options: {
          branch_name: gitOptions.selectedBranch,
          commit_sha: gitOptions.commitSha
        },
        id
      }
    })
  }
}
