import dotProp from 'dot-prop-immutable'

import translationsConstants from './constants'

const initialState = {
  fetchingAllError: null,
  fetchingAllStatus: 'idle',

  filteringError: null,
  filteringStatus: 'idle',

  // Pagination current page number
  itemsCurrentPage: null,
  items: null,
  itemsReferenceLanguages: null,
  // Pagination total page count
  itemsTotalPages: null,

  updatingError: null,
  updatingStatus: 'idle'
}

const sorted = (items) => items.sort((a, b) => a.key.localeCompare(b.key))

export default (state = initialState, action) => {
  const error = action.error ? (action.error.data || action.error.message) : null
  let newState

  switch (action.type) {
    case translationsConstants.FETCH_ALL_REQUEST:
      newState = dotProp.set(state, 'fetchingAllError', null)
      return dotProp.set(newState, 'fetchingAllStatus', 'fetching')
    case translationsConstants.FETCH_ALL_FAILURE:
      newState = dotProp.set(state, 'fetchingAllError', error)
      return dotProp.set(newState, 'fetchingAllStatus', 'failed')
    case translationsConstants.FETCH_ALL_SUCCESS:
      newState = dotProp.set(state, 'fetchingAllError', null)
      newState = dotProp.set(newState, 'fetchingAllStatus', 'succeeded')
      newState = dotProp.set(
        newState,
        'itemsCurrentPage',
        action.translations.current_page
      )
      newState = dotProp.set(
        newState,
        'items',
        action.translations.project_strings
      )
      newState = dotProp.set(
        newState,
        'itemsReferenceLanguages',
        sorted(action.translations.reference_languages_strings)
      )
      return dotProp.set(
        newState,
        'itemsTotalPages',
        action.translations.total_pages
      )

    case translationsConstants.FILTER_REQUEST:
      newState = dotProp.set(state, 'filteringError', null)
      return dotProp.set(newState, 'filteringStatus', 'filtering')
    case translationsConstants.FILTER_FAILURE:
      newState = dotProp.set(state, 'filteringError', error)
      return dotProp.set(newState, 'filteringStatus', 'failed')
    case translationsConstants.FILTER_SUCCESS:
      newState = dotProp.set(state, 'filteringError', null)
      newState = dotProp.set(newState, 'filteringStatus', 'succeeded')
      newState = dotProp.set(
        newState,
        'itemsCurrentPage',
        action.translations.current_page
      )
      newState = dotProp.set(
        newState,
        'items',
        action.translations.project_strings
      )
      newState = dotProp.set(
        newState,
        'itemsReferenceLanguages',
        sorted(action.translations.reference_languages_strings)
      )
      return dotProp.set(
        newState,
        'itemsTotalPages',
        action.translations.total_pages
      )

    case translationsConstants.UPDATE_REQUEST:
      newState = dotProp.set(state, 'updatingError', null)
      return dotProp.set(newState, 'updatingStatus', 'updating')
    case translationsConstants.UPDATE_FAILURE:
      newState = dotProp.set(state, 'updatingError', error)
      return dotProp.set(newState, 'updatingStatus', 'failed')
    case translationsConstants.UPDATE_SUCCESS: {
      newState = dotProp.set(state, 'updatingError', null)
      newState = dotProp.set(newState, 'updatingStatus', 'succeeded')

      action.updatedStrings.forEach((updatedString) => {
        const index = state.items.findIndex(
          (item) => item.id === updatedString.id
        )

        if (index > -1) {
          newState = dotProp.set(newState, `items.${index}`, updatedString)
        }
      })

      return newState
    }

    default:
      return state
  }
}
