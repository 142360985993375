import React, { useState } from 'react'
import { Button, Container } from 'reactstrap'
import { connect } from 'react-redux'
import { createConsumer } from '@rails/actioncable'
import PropTypes from 'prop-types'

import AlertMessage from '../../../components/AlertMessage'
import ProjectExportRetryConfirmationModal from './ProjectExportRetryConfirmationModal'
import ProjectExportsChannel from '../../../ActionCable/channels/ProjectExportsChannel'
import i18n from '../../../config/i18n'
import PageHeader from '../../../components/PageHeader'
import PageLoader from '../../../components/PageLoader'
import projectExportsActions from './actions'
import { Step, Stepper } from '../../../components/Stepper'
import TranslationsUpdatesDiffTable from './TranslationsUpdatesDiffTable'

const ProjectExportShowPage = ({
  diff,
  diffCurrentPage,
  diffFetchingError,
  diffFetchingStatus,
  diffTotalPages,
  dispatch,
  fetchingError,
  fetchingStatus,
  match: { params: { id, slug } },
  projectExport,
  userRole
}) => {
  const [page, setPage] = useState(null)
  const [showRetryConfirmationModal, setShowRetryConfirmationModal] = useState(false)

  const i18nBase = 'ProjectsPage.ProjectExports.Show'

  if (fetchingStatus === 'idle') dispatch(projectExportsActions.fetch(slug, id))
  if (diffFetchingStatus === 'idle') {
    dispatch(projectExportsActions.fetchExportDiff(slug, id, page))
  }

  const moveToPage = (selected) => {
    setPage(selected)
    dispatch(projectExportsActions.fetchExportDiff(slug, id, selected))
  }

  const prevStateScore = () => {
    switch (projectExport?.prev_state) {
      case null: return 0
      case 'pending': return 1
      case 'started': return 2
      case 'branch_cleaning': return 3
      case 'generating': return 4
      case 'pushing': return 5
      default: return -1
    }
  }

  const nextStateScore = () => {
    switch (projectExport?.state) {
      case 'pending': return 1
      case 'started': return 2
      case 'branch_cleaning': return 3
      case 'generating': return 4
      case 'pushing': return 5
      case 'exporting_succeeded': return 6
      case 'exporting_failed': return 100
      default: return -1
    }
  }

  /*
  ** prev_state: null + state: 'pending'       => 1
  *              (+0)          (+1)
  *  [BOOM] prev_state: 'pending' + state: 'exporting_failed'  => 101
  *                     (+1)               (+100)
  *
  *  prev_state: 'pending' + state: 'started'  => 3
  *              (+1)               (+2)
  *  [BOOM] prev_state: 'started' + state: 'exporting_failed'  => 102
  *                     (+2)               (+100)
  *
  *  prev_state: 'started' + state: 'branch_cleaning'  => 5
  *              (+2)               (+3)
  *  [BOOM] prev_state: 'branch_cleaning' + state: 'exporting_failed'  => 103
  *                     (+3)                        (+100)
  *
  *  prev_state: 'branch_cleaning' + state: 'generating'  => 7
  *              (+3)                       (+4)
  *  [BOOM] prev_state: 'generating' + state: 'exporting_failed'  => 104
  *                     (+4)                  (+100)
  *
  *  prev_state: 'generating' + state: 'pushing'  => 9
  *              (+4)                   (+5)
  *  [BOOM] prev_state: 'pushing' + state: 'exporting_failed'  => 105
  *                     (+5)               (+100)
  *
  *  prev_state: 'pushing' + state: 'exporting_succeeded'  => 11
  *              (+5)               (+6)
  */

  const exportStateScore = prevStateScore() + nextStateScore()

  return (
    <Container fluid className="d-flex flex-column flex-fill mt-3">
      <div className="d-flex flex-column">
        <div className="d-flex flex-row justify-content-between">
          <div className="d-flex flex-column">
            <PageHeader
              projectSlug={slug}
              title={i18n.t(`${i18nBase}.title`)}
              withoutHr
            />
          </div>

          {projectExport && projectExport.state !== 'exporting_succeeded' && (
            <Button
              className="w-15"
              color="success"
              disabled={exportStateScore !== 11
                        && exportStateScore > 100 === false}
              onClick={() => setShowRetryConfirmationModal(true)}
              outline
            >
              {i18n.t(`${i18nBase}.buttons.retry`)}
            </Button>
          )}
        </div>

        <hr className="w-100" />
      </div>

      {['idle', 'fetching'].includes(fetchingStatus) && (
        <div className="d-flex flex-row justify-content-center">
          <PageLoader message={i18n.t(`${i18nBase}.statuses.fetching`)} />
        </div>
      )}

      {fetchingStatus === 'failed' && (
        <div className="d-flex flex-row justify-content-center">
          <AlertMessage>
            {fetchingError || i18n.t('errors.somethingWentWrong')}
          </AlertMessage>
        </div>
      )}

      {fetchingStatus === 'succeeded' && (
        <div className="d-flex flex-row justify-content-center">
          <Stepper>
            <Step
              active={exportStateScore === 1}
              success={exportStateScore > 1}
            >
              {i18n.t(`${i18nBase}.Stepper.steps.pending`)}
            </Step>
            <Step
              active={exportStateScore === 3 || exportStateScore === 102}
              success={
                (exportStateScore > 3 && exportStateScore < 100)
                || exportStateScore > 102
              }
            >
              {i18n.t(`${i18nBase}.Stepper.steps.started`)}
            </Step>
            <Step
              active={exportStateScore === 5 || exportStateScore === 103}
              success={
                (exportStateScore > 5 && exportStateScore < 100)
                || exportStateScore > 103
              }
            >
              {i18n.t(`${i18nBase}.Stepper.steps.cleanup`)}
            </Step>
            <Step
              active={exportStateScore === 7 || exportStateScore === 104}
              success={
                (exportStateScore > 7 && exportStateScore < 100)
                || exportStateScore > 104
              }
            >
              {i18n.t(`${i18nBase}.Stepper.steps.exporting`)}
            </Step>
            <Step
              active={exportStateScore === 9 || exportStateScore === 105}
              success={exportStateScore > 9 && exportStateScore < 100}
            >
              {i18n.t(`${i18nBase}.Stepper.steps.pushing`)}
            </Step>
            <Step
              error={exportStateScore > 100}
              success={exportStateScore === 11}
            >
              {i18n.t(`${i18nBase}.Stepper.steps.finished`)}
            </Step>
          </Stepper>
        </div>
      )}

      {userRole === 'admin' && projectExport?.state === 'exporting_failed' && (
        <Container>
          <div className="d-flex flex-column mt-5">
            <p className="mb-0">{i18n.t(`${i18nBase}.failureReason.title`)}</p>
            <hr className="w-100" />
            <AlertMessage className="d-flex flex-column">
              <pre>
                <strong>
                  {i18n.t(`${i18nBase}.failureReason.message`)}
                  :&nbsp;
                </strong>
                {projectExport.export_failure_reason}
                <br />
                <br />
                <strong>
                  {i18n.t(`${i18nBase}.failureReason.trace`)}
                  :&nbsp;
                </strong>
                {projectExport.export_failure_trace}
              </pre>
            </AlertMessage>
          </div>
        </Container>
      )}

      <Container className="mt-5">
        <h4>{i18n.t(`${i18nBase}.diff.title`)}</h4>
        <hr className="w-100" />

        {diffFetchingStatus === 'fetching' && (
          <div className="d-flex flex-row justify-content-center">
            <PageLoader message={i18n.t(`${i18nBase}.statuses.fetchingDiff`)} />
          </div>
        )}

        {diffFetchingStatus === 'failed' && (
          <div name="error" className="d-flex flex-row justify-content-center mt-3">
            <AlertMessage>
              {typeof diffFetchingError === 'string'
                ? diffFetchingError
                : i18n.t('errors.somethingWentWrong')}
            </AlertMessage>
          </div>
        )}

        {diffFetchingStatus === 'succeeded'
          && diff.length > 0
          && (
            <TranslationsUpdatesDiffTable
              diff={diff}
              moveToPage={(selected) => moveToPage(selected)}
              paginationCurrentPage={diffCurrentPage}
              paginationTotalPages={diffTotalPages}
            />
          )}
      </Container>

      <ProjectExportsChannel
        consumer={createConsumer(undefined)}
        projectId={slug}
        id={id}
      />

      <ProjectExportRetryConfirmationModal
        isOpen={showRetryConfirmationModal}
        onClose={() => setShowRetryConfirmationModal(false)}
        projectExportId={id}
        projectId={slug}
      />
    </Container>
  )
}

ProjectExportShowPage.propTypes = {
  diff: PropTypes.arrayOf(PropTypes.shape()),
  diffCurrentPage: PropTypes.number,
  diffFetchingError: PropTypes.string,
  diffFetchingStatus: PropTypes.string,
  diffTotalPages: PropTypes.number,
  dispatch: PropTypes.func.isRequired,
  fetchingError: PropTypes.string,
  fetchingStatus: PropTypes.string,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
      slug: PropTypes.string
    })
  }),
  projectExport: PropTypes.shape({
    created_at: PropTypes.string.isRequired,
    export_failure_reason: PropTypes.string,
    export_failure_trace: PropTypes.string,
    prev_state: PropTypes.string,
    state: PropTypes.string.isRequired
  }),
  userRole: PropTypes.string
}

ProjectExportShowPage.defaultProps = {
  diff: null,
  diffCurrentPage: null,
  diffFetchingError: null,
  diffFetchingStatus: 'idle',
  diffTotalPages: null,
  fetchingError: null,
  fetchingStatus: 'idle',
  match: {
    params: {
      id: null,
      slug: null
    }
  },
  projectExport: null,
  userRole: null
}

const mapStateToProps = ({
  authentication: {
    role: userRole
  },
  projectExports: {
    diffFetchingError,
    diffFetchingStatus,
    diffItems: diff,
    fetchingError,
    fetchingStatus,
    item: projectExport,
    itemsCurrentPage: diffCurrentPage,
    itemsTotalPages: diffTotalPages
  }
}) => ({
  diff,
  diffCurrentPage,
  diffFetchingError,
  diffFetchingStatus,
  diffTotalPages,
  fetchingError,
  fetchingStatus,
  projectExport,
  userRole
})

export default connect(mapStateToProps)(ProjectExportShowPage)
