import React from 'react'
import {
  Table
} from 'reactstrap'
import PropTypes from 'prop-types'
import { v1 as uuidv1 } from 'uuid'

import i18n from '../../../config/i18n'
import PageLoader from '../../../components/PageLoader'
import ProjectStringToRemoveRow from './ProjectStringToRemoveRow'
import TableHeaderProjectStringToRemove from './TableHeaderProjectStringToRemove'
import TablePagination from '../../../components/TablePagination'
import WellDone from '../../../components/WellDone'

const UnusedStringsTable = ({
  moveToPage,
  paginationCurrentPage,
  paginationTotalPages,
  projectUnusedStrings,
  projectUnusedStringsFetchingStatus
}) => {
  const i18nBase = 'ProjectsPage.ProjectUnusedStrings'

  if (['idle', 'fetching'].includes(projectUnusedStringsFetchingStatus)) {
    return (
      <div className="d-flex flex-column flex-fill justify-content-center align-items-center">
        <PageLoader message={i18n.t(`${i18nBase}.fetching`)} />
      </div>
    )
  }

  if (projectUnusedStringsFetchingStatus === 'succeeded'
      && projectUnusedStrings.length === 0) {
    return (
      <WellDone />
    )
  }

  return (
    <>
      <Table>
        <thead>
          <TableHeaderProjectStringToRemove />
        </thead>
        <tbody>
          {(projectUnusedStrings || []).map((projectStringToRemove) => (
            <ProjectStringToRemoveRow
              key={uuidv1()}
              projectString={projectStringToRemove}
            />
          ))}
        </tbody>
      </Table>

      <div className="d-flex flex-row justify-content-center">
        <TablePagination
          currentPage={paginationCurrentPage}
          onPaginationClick={(page) => moveToPage(page)}
          totalPages={paginationTotalPages}
        />
      </div>
    </>
  )
}

UnusedStringsTable.propTypes = {
  moveToPage: PropTypes.func.isRequired,
  paginationCurrentPage: PropTypes.number,
  paginationTotalPages: PropTypes.number,
  projectUnusedStrings: PropTypes.arrayOf(PropTypes.shape()),
  projectUnusedStringsFetchingStatus: PropTypes.string
}

UnusedStringsTable.defaultProps = {
  paginationCurrentPage: 1,
  paginationTotalPages: 1,
  projectUnusedStrings: null,
  projectUnusedStringsFetchingStatus: 'idle'
}

export default UnusedStringsTable
