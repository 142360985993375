import React from 'react'
import {
  Button,
  Form,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from 'reactstrap'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import AlertMessage from '../../../components/AlertMessage'
import i18n from '../../../config/i18n'
import integrationsActions from '../../../integrations/actions'
import localesActions from '../../../localez/actions'
import projectsActions from '../actions'
import Step1 from './Step1'
import Step2 from './Step2'
import Step3 from './Step3'

class ProjectCreationModal extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      /*
      ** Step 1
      */
      // Project name
      name: '',
      // The project name is checked against the server
      nameIsAvailable: false,
      // List of selectable locales to select the reference languages
      locales: null,

      /*
      ** Step 2
      */
      // Commit SHA to be used to fetch translation files
      commitSha: null,
      // Paths where to look for locale files
      paths: '',
      // Repository ID
      repository: null,
      // The branch to be used to clone the project when the user doesn't want
      // to use the project's default branch
      selectedBranch: null,

      /*
      ** Modal attributes
      */
      step: 1
    }
  }

  componentDidUpdate(prevProps) {
    const {
      checkingPathsStatus: prevCheckingPathsStatus,
      creationStatus: prevCreationStatus,
      isOpen: prevIsOpen,
      nameCheckingStatus: prevNameCheckingStatus
    } = prevProps

    const {
      checkingPathsStatus,
      creationStatus,
      isOpen,
      nameCheckingError,
      nameCheckingStatus
    } = this.props

    if (prevIsOpen === false && isOpen === true) this.fetchData()

    if (isOpen === false) return

    if (prevCheckingPathsStatus === 'checking'
        && checkingPathsStatus === 'succeeded') {
      this.createProject()
    }

    if (prevCreationStatus === 'creating' && creationStatus === 'succeeded') {
      this.toggle()
    }

    if (prevNameCheckingStatus === 'idle' && nameCheckingStatus === 'checking') {
      this.step1.showNameAsChecking()
    }

    if (prevNameCheckingStatus === 'checking') {
      if (nameCheckingStatus === 'failed') {
        this.step1.showNameAsInvalid(nameCheckingError)
      }

      this.updateNameAvailabilityStatus(nameCheckingStatus)
    }
  }

  onSubmit(event) {
    const { step } = this.state

    event.preventDefault()

    if (step === 1) this.validateStep1ThenMoveNext()
    if (step === 2) this.validateStep2ThenMoveNext()
  }

  onBranchChange(selectedBranch) {
    this.setState({
      selectedBranch
    })
  }

  onChange({ target: { name, value } }) {
    this.setState({
      [name]: value
    })
  }

  onCommitShaChange(commitSha) {
    this.setState({
      commitSha
    })
  }

  onLocalesSelect(locales) {
    this.setState({
      locales
    })
  }

  onRepositorySelected(repository) {
    if (repository) this.step2.showRepositoryAsValid()

    this.setState({
      repository
    })
  }

  setModalStep(step) {
    this.setState({
      step
    })
  }

  checkNameAvailablity() {
    const { dispatch } = this.props
    const { name } = this.state

    if (name !== '') dispatch(projectsActions.checkNameAvailablity(name))
  }

  createProject() {
    const { dispatch, integrations } = this.props
    const {
      commitSha,
      locales,
      name,
      paths,
      repository,
      selectedBranch
    } = this.state

    const localeCodes = locales.map((locale) => locale.value)
    const { platform } = integrations[0]

    dispatch(projectsActions.create({
      branch_name: selectedBranch?.label,
      commit_sha: commitSha,
      locales: localeCodes,
      name,
      paths,
      platform,
      repository_id: repository.value
    }))
  }

  disableNextButtons() {
    const { localeList } = this.props
    const { step } = this.state

    if (localeList === null) return true
    if (step === 3) return true

    return false
  }

  disablePreviousButtons() {
    const { checkingPathsStatus, creationStatus, localeList } = this.props
    const { step } = this.state

    if (step === 1) return true
    if (localeList === null) return true
    if (step === 3 && (checkingPathsStatus === 'checking'
                       || creationStatus === 'creating')) {
      return true
    }

    return false
  }

  fetchData() {
    const { dispatch, integrations } = this.props

    dispatch(localesActions.fetchAll())

    const { platform } = integrations[0]
    dispatch(integrationsActions.fetchRepositories(platform))
  }

  moveToNextStep() {
    const { step } = this.state

    this.setModalStep(Math.min(3, (step + 1)))
  }

  moveToPreviousStep() {
    const { step } = this.state

    this.setModalStep(Math.max(1, (step - 1)))
  }

  toggle() {
    const { toggle } = this.props

    this.setState({
      name: '',
      locales: null,
      step: 1
    }, () => toggle())
  }

  updateNameAvailabilityStatus(status) {
    const nameIsAvailable = status === 'succeeded'

    if (nameIsAvailable) this.step1.hideNameAsChecking()

    this.setState({
      nameIsAvailable
    })
  }

  validateStep1ThenMoveNext() {
    const { locales, name, nameIsAvailable } = this.state

    if (name === '') this.step1.showNameAsInvalid()
    if (locales === null) this.step1.showLocalesAsInvalid()

    if (name && nameIsAvailable && locales.length) this.moveToNextStep()
  }

  validateStep2ThenMoveNext() {
    const { paths, repository } = this.state

    if (repository === null) this.step2.showRepositoryAsInvalid()
    if (paths === '') this.step2.showPathsAsInvalid()

    if (repository && paths.length) this.moveToNextStep()
  }

  render() {
    const {
      fetchingError,
      isOpen,
      localeList,
      repositories
    } = this.props

    const {
      commitSha,
      locales,
      name,
      paths,
      repository,
      selectedBranch,
      step
    } = this.state

    if (fetchingError) {
      return (
        <AlertMessage>
          {i18n.t('errors.somethingWentWrong')}
        </AlertMessage>
      )
    }

    return (
      <Modal isOpen={isOpen} toggle={() => this.toggle()}>
        <Form id={`step-${step}`}>
          <ModalHeader toggle={() => this.toggle()}>
            {i18n.t(`ProjectsPage.ProjectCreationModal.Step${step}.title`)}
          </ModalHeader>
          <ModalBody className="d-flex flex-column">
            {step === 1 && (
              <Step1
                localeList={localeList}
                locales={locales}
                name={name}
                onChange={(event) => this.onChange(event)}
                onLocalesSelect={
                  (selectedlocales) => this.onLocalesSelect(selectedlocales)
                }
                onNameFieldBlur={() => this.checkNameAvailablity()}
                ref={(component) => { this.step1 = component }}
              />
            )}
            {step === 2 && (
              <Step2
                commitSha={commitSha}
                localeFilesPath={paths}
                platform="gitlab"
                onBranchChange={(value) => this.onBranchChange(value)}
                onChange={(event) => this.onChange(event)}
                onCommitShaChange={(value) => this.onCommitShaChange(value)}
                onRepositorySelect={
                  (selected) => this.onRepositorySelected(selected)
                }
                ref={(component) => { this.step2 = component }}
                repository={repository}
                repositories={repositories}
                selectedBranch={selectedBranch}
              />
            )}
            {step === 3 && (
              <Step3
                paths={paths}
                platform="gitlab"
                repository={repository}
              />
            )}
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => this.toggle()}>
              {i18n.t('buttons.cancel')}
            </Button>
            <Button
              color="primary"
              disabled={this.disablePreviousButtons()}
              onClick={() => this.moveToPreviousStep()}
            >
              {i18n.t('ProjectsPage.ProjectCreationModal.buttons.previous')}
            </Button>
            <Button
              color="primary"
              disabled={this.disableNextButtons()}
              onClick={(event) => this.onSubmit(event)}
            >
              {i18n.t('ProjectsPage.ProjectCreationModal.buttons.next')}
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    )
  }
}

ProjectCreationModal.propTypes = {
  dispatch: PropTypes.func.isRequired,
  fetchingError: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  checkingPathsStatus: PropTypes.string,
  creationStatus: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  integrations: PropTypes.arrayOf(PropTypes.shape({
    platform: PropTypes.string.isRequired
  })),
  localeList: PropTypes.arrayOf(PropTypes.object),
  nameCheckingError: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  nameCheckingStatus: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  repositories: PropTypes.object,
  toggle: PropTypes.func.isRequired
}

ProjectCreationModal.defaultProps = {
  checkingPathsStatus: 'idle',
  creationStatus: 'idle',
  fetchingError: null,
  integrations: null,
  localeList: null,
  nameCheckingError: null,
  nameCheckingStatus: 'idle',
  repositories: null
}

const mapStateToProps = ({
  locales: {
    items: localeList
  },
  projects: {
    nameCheckingError,
    creationStatus,
    nameCheckingStatus
  },
  integrations: {
    checkingPathsStatus,
    fetchinError,
    items: integrations,
    repositories
  }
}) => ({
  checkingPathsStatus,
  nameCheckingError,
  creationStatus,
  fetchinError,
  integrations,
  localeList,
  nameCheckingStatus,
  repositories
})

export default connect(mapStateToProps)(ProjectCreationModal)
