import React, { useState } from 'react'
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap'
import PropTypes from 'prop-types'

import i18n from '../../config/i18n'

const Menu = ({
  onClickEdit
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const toggle = () => setIsOpen(!isOpen)

  return (
    <Dropdown
      isOpen={isOpen}
      name="settings-menu"
      toggle={toggle}
    >
      <DropdownToggle nav className="p-0">
        <div className="vertical-triple-dots" />
      </DropdownToggle>
      <DropdownMenu right>
        <DropdownItem
          name="edit"
          onClick={(event) => onClickEdit(event)}
        >
          {i18n.t('buttons.edit')}
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  )
}

Menu.propTypes = {
  onClickEdit: PropTypes.func.isRequired
}

export default Menu
