import RestfulClient from 'restful-json-api-client'

export default class IntegrationsApi extends RestfulClient {
  constructor() {
    super('/api', { resource: 'integrations' })
  }

  checkPaths(platform, repositoryId, paths) {
    return this.request('POST', {
      path: 'check_paths',
      body: {
        paths,
        platform,
        repository_id: repositoryId
      }
    })
  }
}
