import RestfulClient from 'restful-json-api-client'

export default class UserApi extends RestfulClient {
  constructor() {
    super('/', { resource: 'users' })
  }

  logout() {
    return this.request('DELETE', { path: 'sign_out' })
  }

  signIn(email, password) {
    return this.request('POST', {
      path: 'sign_in',
      body: { user: { email, password } }
    })
  }
}
