import React from 'react'
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  CustomInput,
  Row
} from 'reactstrap'
import PropTypes from 'prop-types'

import TableFiler from '../../components/TableFiler'
import TableSorting from '../../components/TableSorting'
import i18n from '../../config/i18n'

const LocalTranslationTableFilter = ({
  filterColumn,
  filterOnEmptyOnly,
  filterQuery,
  onColumnChange,
  onEmptyOnlyClick,
  onQueryChange,
  onSortChange,
  onSubmit,
  sortingOption
}) => {
  const i18nBase = 'TranslationsPage.LocalTranslationTableFilter'

  return (
    <Row>
      <Col
        className="mb-3 mb-md-0"
        md="8"
      >
        <Card color="light">
          <CardBody>
            <CardTitle tag="h5">
              {i18n.t(`${i18nBase}.filterTitle`)}
            </CardTitle>

            <TableFiler
              filterColumn={filterColumn}
              filterQuery={filterQuery}
              onColumnChange={onColumnChange}
              onQueryChange={onQueryChange}
              onSubmit={onSubmit}
            />

            <CustomInput
              checked={filterOnEmptyOnly}
              className="z-0"
              id="empty-strings-only-btn"
              label={i18n.t(`${i18nBase}.emptyOnlyButton`)}
              name="customSwitch"
              onChange={({ target: { checked } }) => onEmptyOnlyClick(checked)}
              type="switch"
            />
          </CardBody>
        </Card>
      </Col>

      <Col md="4">
        <Card
          className="h-100"
          color="light"
        >
          <CardBody>
            <CardTitle tag="h5">
              {i18n.t(`${i18nBase}.sortTitle`)}
            </CardTitle>

            <TableSorting
              sortingOption={sortingOption}
              onSortChange={onSortChange}
              onSubmit={onSubmit}
            />
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

LocalTranslationTableFilter.propTypes = {
  filterColumn: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired
  }),
  filterOnEmptyOnly: PropTypes.bool,
  filterQuery: PropTypes.string,
  onColumnChange: PropTypes.func.isRequired,
  onEmptyOnlyClick: PropTypes.func.isRequired,
  onQueryChange: PropTypes.func.isRequired,
  onSortChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  sortingOption: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired
  })
}

LocalTranslationTableFilter.defaultProps = {
  filterColumn: null,
  filterOnEmptyOnly: false,
  filterQuery: null,
  onSubmit: () => {},
  sortingOption: null
}

export default LocalTranslationTableFilter
