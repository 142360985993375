import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { v1 as uuidv1 } from 'uuid'

import AlertMessage from '../../components/AlertMessage'
import i18n from '../../config/i18n'
import PageLoader from '../../components/PageLoader'
import localesActions from '../../localez/actions'
import ProjectCard from './ProjectCard'
import ProjectCreationCard from './ProjectCreationCard'
import ProjectCreationModal from './ProjectCreationModal'
import ProjectDeleteConfirmModal from './ProjectDeleteConfirmModal'
import ProjectEditModal from './ProjectEditModal'
import ProjectForceImportConfirmModal from './ProjectForceImportConfirmModal'
import projectsActions from './actions'

class ProjectDeck extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      target: null,
      showProjectCreationModal: false,
      showProjectDeleteConfirmModal: false,
      showProjectEditModal: false,
      showProjectForceImportModal: false
    }
  }

  componentDidMount() {
    const { dispatch } = this.props

    dispatch(localesActions.fetchAll())
    dispatch(projectsActions.fetchAll())
  }

  setShowProjectCreationModal(showProjectCreationModal) {
    this.setState({
      showProjectCreationModal
    })
  }

  setShowProjectEditModal(showProjectEditModal) {
    this.setState({
      showProjectEditModal
    })
  }

  hideForceImportConfirmModal() {
    this.setState({
      target: null,
      showProjectForceImportModal: false
    })
  }

  hideDeleteConfirmModal() {
    this.setState({
      target: null,
      showProjectDeleteConfirmModal: false
    })
  }

  showDeleteConfirmFor(project) {
    this.setState({
      target: project,
      showProjectDeleteConfirmModal: true
    })
  }

  showEditModalFor(project) {
    this.setState({
      target: project,
      showProjectEditModal: true
    })
  }

  showForceImportModalFor(project) {
    this.setState({
      target: project,
      showProjectForceImportModal: true
    })
  }

  render() {
    const {
      locales,
      projectsAllFetchingError,
      projectsAllFetchingStatus,
      projects
    } = this.props

    const {
      target,
      showProjectCreationModal,
      showProjectDeleteConfirmModal,
      showProjectEditModal,
      showProjectForceImportModal
    } = this.state

    if (projectsAllFetchingStatus === 'fetching') {
      return (
        <div className="d-flex flex-column">
          <PageLoader
            message={i18n.t('ProjectsPage.ProjectDeck.loading')}
          />
        </div>
      )
    }

    if (projectsAllFetchingStatus === 'failed') {
      return (
        <div className="d-flex flex-column">
          <AlertMessage>
            {typeof projectsAllFetchingError === 'string'
              ? projectsAllFetchingError
              : i18n.t('errors.somethingWentWrong')}
          </AlertMessage>
        </div>
      )
    }

    return (
      <div className="d-flex flex-wrap">
        {locales && (projects || []).map((project) => (
          <ProjectCard
            key={uuidv1()}
            onClickDelete={(selected) => this.showDeleteConfirmFor(selected)}
            onClickEdit={(selected) => this.showEditModalFor(selected)}
            onClickForceImport={(selected) => this.showForceImportModalFor(selected)}
            projectId={project.id}
          />
        ))}

        <ProjectCreationCard
          onClick={() => this.setShowProjectCreationModal(true)}
          title={i18n.t('ProjectsPage.ProjectDeck.buttons.createTitle')}
        />

        <ProjectCreationModal
          isOpen={showProjectCreationModal}
          toggle={() => this.setShowProjectCreationModal(false)}
        />

        <ProjectEditModal
          isOpen={showProjectEditModal}
          project={showProjectEditModal ? target : {}}
          toggle={() => this.setShowProjectEditModal(false)}
        />

        <ProjectDeleteConfirmModal
          id={target?.id}
          isOpen={showProjectDeleteConfirmModal}
          onClose={() => this.hideDeleteConfirmModal()}
        />

        <ProjectForceImportConfirmModal
          isOpen={showProjectForceImportModal}
          project={showProjectForceImportModal ? target : {}}
          onClose={() => this.hideForceImportConfirmModal()}
          projectId={target?.id}
          repositoryId={target?.repository_id}
        />
      </div>
    )
  }
}

ProjectDeck.propTypes = {
  dispatch: PropTypes.func.isRequired,
  locales: PropTypes.arrayOf(PropTypes.object),
  projectsAllFetchingError: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  projectsAllFetchingStatus: PropTypes.string,
  projects: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired
  }))
}

ProjectDeck.defaultProps = {
  locales: null,
  projectsAllFetchingError: null,
  projectsAllFetchingStatus: 'idle',
  projects: null
}

const mapStateToProps = ({
  locales: {
    items: locales
  },
  projects: {
    fetchingAllError: projectsAllFetchingError,
    fetchingAllStatus: projectsAllFetchingStatus,
    items: projects
  }
}) => ({
  locales,
  projectsAllFetchingError,
  projectsAllFetchingStatus,
  projects
})

export default connect(mapStateToProps)(ProjectDeck)
