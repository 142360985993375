import React from 'react'
import { connect } from 'react-redux'
import { Button } from 'reactstrap'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import Select from 'react-select'

import i18n from '../../../config/i18n'
import localesActions from '../../../localez/actions'
import PageLoader from '../../../components/PageLoader'
import projectActions from '../actions'

const UpdateStringLocale = ({
  dispatch,
  locales,
  localesFetchingStatus,
  onLocaleChange,
  projectId,
  selectedProjectStrings,
  value
}) => {
  const i18nBase = 'ProjectsPage.ImportIssues'

  if (localesFetchingStatus === 'idle') {
    dispatch(localesActions.fetchAll())
  }

  const noOptionsMessage = () => (
    <p>
      {i18n.t('ProjectsPage.labels.noOptions')}
      <Link
        className="text-secondary font-weight-bold"
        name="create-locales"
        to="/locales"
      >
        {i18n.t('ProjectsPage.labels.noOptionsLink')}
      </Link>
    </p>
  )

  const onUpdateAlertProjectStringClick = () => (
    dispatch(projectActions.fixingAlert(
      projectId,
      selectedProjectStrings,
      value.value
    ))
  )

  return (
    <div className="d-flex flex-column mx-4">
      <div className="d-flex flex-column mb-3">
        <h5>{i18n.t(`${i18nBase}.UpdateProjectStringLocale.title`)}</h5>
        <small>
          {i18n.t(`${i18nBase}.UpdateProjectStringLocale.subtitle`)}
        </small>
      </div>

      {['idle', 'fetching'].includes(localesFetchingStatus) && (
        <div className="d-flex flex-row justify-content-center">
          <PageLoader message={i18n.t('ProjectsPage.fetchingLocales')} />
        </div>
      )}

      {localesFetchingStatus === 'succeeded' && (
        <Select
          id="locales"
          noOptionsMessage={() => noOptionsMessage()}
          onChange={(selected) => onLocaleChange(selected)}
          openMenuOnFocus
          options={locales}
          placeholder={i18n.t(`${i18nBase}.ProjectStringIssues.filter.localesPlaceholder`)}
          value={value}
        />
      )}

      {localesFetchingStatus === 'succeeded' && (
        <Button
          className="mt-2"
          color="primary"
          disabled={value === null || (selectedProjectStrings || []).length === 0}
          onClick={() => onUpdateAlertProjectStringClick()}
          outline
        >
          {i18n.t('buttons.update')}
        </Button>
      )}
    </div>
  )
}

UpdateStringLocale.propTypes = {
  dispatch: PropTypes.func.isRequired,
  onLocaleChange: PropTypes.func.isRequired,
  locales: PropTypes.arrayOf(PropTypes.shape()),
  localesFetchingStatus: PropTypes.string,
  projectId: PropTypes.string.isRequired,
  selectedProjectStrings: PropTypes.arrayOf(PropTypes.string),
  value: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired
  })
}

UpdateStringLocale.defaultProps = {
  locales: null,
  localesFetchingStatus: 'idle',
  selectedProjectStrings: null,
  value: null
}

const mapStateToProps = ({
  locales: {
    fetchingStatus: localesFetchingStatus,
    items: locales
  }
}) => ({
  locales,
  localesFetchingStatus
})

export default connect(mapStateToProps)(UpdateStringLocale)
