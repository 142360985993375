import React from 'react'
import { Spinner } from 'reactstrap'
import PropTypes from 'prop-types'

const Stepper = ({ children }) => (
  <ul className="steps">
    <ul className="steps">
      {children}
    </ul>
  </ul>
)

Stepper.propTypes = {
  children: PropTypes.arrayOf(
    PropTypes.element.isRequired
  ).isRequired
}

const Step = ({
  active,
  children,
  error,
  success
}) => {
  let stepClass

  if (active) stepClass = 'step-active'
  if (error) stepClass = 'step-error'
  if (success) stepClass = 'step-success'

  return (
    <li className={`step ${stepClass}`}>
      <div className="step-content">
        <span className="step-circle">
          {active && (<Spinner color="info" />)}
        </span>
        <span className="step-text">
          {children}
        </span>
      </div>
    </li>
  )
}

Step.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.string.isRequired,
  error: PropTypes.bool,
  success: PropTypes.bool
}

Step.defaultProps = {
  active: null,
  error: null,
  success: null
}

export { Step, Stepper }
