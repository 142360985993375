import React from 'react'
import {
  FormFeedback,
  FormGroup,
  Label
} from 'reactstrap'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import Select from 'react-select'

import FormInputField from '../../../components/FormInputField'
import GitOptions from '../../../components/GitOptions'
import i18n from '../../../config/i18n'

class Step2 extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      gitOptionsIsOpen: false,
      noSelectedRepository: false
    }
  }

  showPathsAsInvalid(message = null) {
    this.pathsField.showAsInvalid(message)
  }

  showRepositoryAsInvalid() {
    this.setState({
      noSelectedRepository: true
    })
  }

  showRepositoryAsValid() {
    this.setState({
      noSelectedRepository: false
    })
  }

  toggleGitOptionsIsOpen() {
    const { gitOptionsIsOpen } = this.state

    this.setState({
      gitOptionsIsOpen: !gitOptionsIsOpen
    })
  }

  render() {
    const {
      commitSha,
      localeFilesPath,
      platform,
      onBranchChange,
      onChange,
      onCommitShaChange,
      onRepositorySelect,
      repositories,
      repository,
      selectedBranch
    } = this.props

    const { gitOptionsIsOpen, noSelectedRepository } = this.state

    const noOptionsMessage = () => (
      <p>
        {i18n.t('ProjectsPage.ProjectCreationModal.Step2.noOptions')}
        <Link
          className="text-secondary font-weight-bold"
          name="verify-settings"
          to="/settings"
        >
          {i18n.t('ProjectsPage.ProjectCreationModal.Step2.noOptionsLink')}
        </Link>
      </p>
    )

    const platformRepositories = repositories[platform]?.sort(
      (a, b) => a.label.localeCompare(b.label)
    )

    return (
      <>
        <FormGroup className="position-relative">
          <Label for="repositories">
            {i18n.t('ProjectsPage.fields.repository')}
          </Label>
          <Select
            id="repositories"
            noOptionsMessage={() => noOptionsMessage()}
            onChange={(selected) => onRepositorySelect(selected)}
            options={platformRepositories}
            placeholder={
              i18n.t('ProjectsPage.fields.repositoryPlaceholder', { platform })
            }
            value={repository}
          />
          {noSelectedRepository && (
            <FormFeedback className="d-block z-0" tooltip>
              {i18n.t('errors.required_field')}
            </FormFeedback>
          )}
        </FormGroup>

        <FormGroup>
          <GitOptions
            commitSha={commitSha}
            isOpen={gitOptionsIsOpen}
            onCommitShaChange={(value) => onCommitShaChange(value)}
            onBranchChange={(selected) => onBranchChange(selected)}
            repositoryId={repository?.value}
            selectedBranch={selectedBranch}
            toggle={() => this.toggleGitOptionsIsOpen()}
          />
        </FormGroup>

        <FormInputField
          inputFormtext={i18n.t('ProjectsPage.fields.pathsExamples')}
          inputId="paths"
          inputName="paths"
          inputPlaceholder={i18n.t('ProjectsPage.fields.pathsPlaceholder')}
          inputType="text"
          labelText={i18n.t('ProjectsPage.fields.paths')}
          onChange={(event) => onChange(event)}
          ref={(component) => { this.pathsField = component }}
          value={localeFilesPath}
        />
      </>
    )
  }
}

Step2.propTypes = {
  commitSha: PropTypes.string,
  localeFilesPath: PropTypes.string,
  platform: PropTypes.string.isRequired,
  onBranchChange: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onCommitShaChange: PropTypes.func.isRequired,
  onRepositorySelect: PropTypes.func.isRequired,
  repository: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired
  }),
  repositories: PropTypes.shape({
    gitlab: PropTypes.arrayOf(PropTypes.object)
  }).isRequired,
  selectedBranch: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired
  })
}

Step2.defaultProps = {
  commitSha: null,
  localeFilesPath: '',
  repository: null,
  selectedBranch: null
}

export default Step2
