import React from 'react'
import {
  FormFeedback,
  FormGroup,
  FormText,
  Input,
  Label,
  Spinner
} from 'reactstrap'
import PropTypes from 'prop-types'

import i18n from '../config/i18n'

class FormInputField extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      busy: false,
      errorMessage: null,
      valid: false,
      value: props.value
    }
  }

  // Update component state in order to save user's input, also reset error
  // message if any.
  handleChange(event) {
    const { target: { value } } = event
    const { onChange } = this.props

    this.setState({
      errorMessage: null,
      invalid: false,
      value
    })
    onChange(event)
  }

  hideBusy() {
    this.setState({
      busy: false
    })
  }

  // Removes error message if any
  hideInvalid() {
    this.setState({
      errorMessage: null,
      invalid: false
    })
  }

  reset() {
    this.hideBusy()
    this.hideInvalid()
  }

  showAsBusy() {
    this.setState({
      busy: true
    })
  }

  // Shows the given error message or errors.required_field error message
  showAsInvalid(message = null) {
    const { value } = this.state

    this.setState({
      busy: false,
      errorMessage: message,
      invalid: true,
      value: value === null ? '' : value
    })
  }

  showAsValid() {
    this.setState({
      busy: false,
      valid: true
    })
  }

  render() {
    const {
      disabled,
      inputFormtext,
      inputId,
      inputName,
      inputPlaceholder,
      intputStyle,
      inputType,
      labelText,
      onBlur,
      withoutFormGroup
    } = this.props

    const {
      busy,
      errorMessage,
      failed,
      invalid,
      valid,
      value
    } = this.state

    const content = (
      <>
        {labelText.length > 0 && (
          <Label for={inputId}>{labelText}</Label>
        )}
        <Input
          className="form-control"
          disabled={disabled}
          id={inputId}
          invalid={invalid}
          name={inputName}
          onBlur={(event) => onBlur(event)}
          onChange={(event) => this.handleChange(event)}
          placeholder={inputPlaceholder}
          style={intputStyle}
          type={inputType}
          valid={valid}
          value={value}
        />
        {busy && (
          <div className="position-absolute input-spinner">
            <Spinner />
          </div>
        )}
        {failed && (
          <div className="position-absolute input-spinner">
            <Spinner />
          </div>
        )}
        {invalid && (
          <FormFeedback tooltip>
            {errorMessage || i18n.t('errors.required_field')}
          </FormFeedback>
        )}
        {inputFormtext && (
          <FormText className="position-absolute" color="muted">
            {inputFormtext}
          </FormText>
        )}
      </>
    )

    if (withoutFormGroup) return content

    return (
      <FormGroup className="position-relative">
        {content}
      </FormGroup>
    )
  }
}

FormInputField.propTypes = {
  disabled: PropTypes.bool,
  inputFormtext: PropTypes.string,
  inputId: PropTypes.string.isRequired,
  inputName: PropTypes.string.isRequired,
  inputType: PropTypes.string,
  inputPlaceholder: PropTypes.string,
  intputStyle: PropTypes.shape(),
  labelText: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  value: PropTypes.string,
  withoutFormGroup: PropTypes.bool
}

FormInputField.defaultProps = {
  disabled: false,
  inputFormtext: '',
  inputPlaceholder: '',
  inputType: 'text',
  intputStyle: {},
  labelText: '',
  onBlur: () => {},
  value: '',
  withoutFormGroup: false
}

export default FormInputField
