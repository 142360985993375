import React from 'react'
import { connect } from 'react-redux'
import {
  Button,
  FormGroup
} from 'reactstrap'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

import AlertMessage from '../../components/AlertMessage'
import DeviseLayout from '../DeviseLayout'
import history from '../../helpers/History'
import i18n from '../../config/i18n'
import PageLoader from '../../components/PageLoader'
import PasswordField from '../../components/PasswordField'
import invitationActions from './actions'

class DeviseInvitationAccept extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      invitationToken: null,
      password: '',
      passwordConfirmation: ''
    }
  }

  componentDidMount() {
    const { location: { search } } = this.props

    const urlParams = new URLSearchParams(search)
    const invitationToken = urlParams.get('invitation_token')

    this.setState({
      invitationToken
    })
  }

  componentDidUpdate(prevProps) {
    const { acceptingStatus: prevAcceptingStatus } = prevProps
    const { acceptingStatus } = this.props

    if (prevAcceptingStatus === 'accepting') {
      if (acceptingStatus === 'failed') {
        this.showFormErrors()
      } else {
        history.push('/users/sign_in')
      }
    }
  }

  handleSubmit(event) {
    const { dispatch } = this.props
    const { password, passwordConfirmation, invitationToken } = this.state

    event.preventDefault()

    if (password === '') {
      this.passwordField.showAsInvalid()
    }

    if (passwordConfirmation === '') {
      this.passwordConfirmationField.showAsInvalid()
    }

    if (password && passwordConfirmation) {
      dispatch(invitationActions.accept({
        invitationToken,
        password,
        passwordConfirmation
      }))
    }
  }

  handlePasswordChange({ target: { name, value } }) {
    this.setState({
      [name]: value
    })
  }

  showFormErrors() {
    const {
      acceptingError: {
        password,
        password_confirmation: passwordConfirmation
      }
    } = this.props

    if (password) {
      this.passwordField.showAsInvalid(password)
    }

    if (passwordConfirmation) {
      this.passwordConfirmationField.showAsInvalid(passwordConfirmation)
    }
  }

  render() {
    const {
      acceptingStatus
    } = this.props
    const {
      invitationToken
    } = this.state

    const { password, passwordConfirmation } = this.state

    return (
      <DeviseLayout
        id="edit-user"
        onSubmit={(event) => this.handleSubmit(event)}
        title={i18n.t('devise.DeviseInvitationAccept.title')}
      >
        <PasswordField
          inputId="user-password"
          inputName="password"
          onChange={(event) => this.handlePasswordChange(event)}
          ref={(component) => { this.passwordField = component }}
          value={password}
        />
        <PasswordField
          inputId="user-password-confirmation"
          inputName="passwordConfirmation"
          labelText={i18n.t('devise.fields.passwordConfirmation')}
          onChange={(event) => this.handlePasswordChange(event)}
          ref={(component) => { this.passwordConfirmationField = component }}
          value={passwordConfirmation}
        />
        {!invitationToken && (
          <AlertMessage color="warning">
            {i18n.t('devise.DeviseInvitationAccept.noToken')}
          </AlertMessage>
        )}
        <FormGroup>
          <div className="d-flex flex-column">
            {acceptingStatus === 'accepting' && (
              <PageLoader
                message={i18n.t('devise.DeviseInvitationAccept.accepting')}
              />
            )}

            <Button
              className="mb-5"
              color="primary"
              disabled={acceptingStatus === 'accepting'}
              name="commit"
            >
              {i18n.t('devise.DeviseInvitationAccept.setMyPassword')}
            </Button>
            <Link
              className="mb-3 btn btn-secondary"
              disabled={acceptingStatus === 'accepting'}
              to="/users/sign_in"
            >
              {i18n.t('buttons.login')}
            </Link>
          </div>
        </FormGroup>
      </DeviseLayout>
    )
  }
}

DeviseInvitationAccept.propTypes = {
  acceptingError: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  acceptingStatus: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired
  }).isRequired
}

DeviseInvitationAccept.defaultProps = {
  acceptingError: null,
  acceptingStatus: 'idle'
}

const mapStateToProps = ({
  invitations: {
    acceptingError,
    acceptingStatus
  }
}) => ({
  acceptingError,
  acceptingStatus
})

export default connect(mapStateToProps)(DeviseInvitationAccept)
