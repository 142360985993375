import { combineReducers } from 'redux'

import authentication from '../../devise/sessions/reducers'
import {
  confirmation,
  resendConfirmation
} from '../../devise/confirmations/reducers'
import integrations from '../../integrations/reducers'
import invitations from '../../devise/invitations/reducers'
import projectExports from '../../PrivatePages/ProjectsPage/ProjectExports/reducer'
import projectLanguages from '../../PrivatePages/ProjectsPage/ProjectPage/ProjectLanguages/reducer'
import projectLanguageStats from '../../PrivatePages/ProjectsPage/ProjectPage/ProjectLanguageStats/reducer'
import projectStringsAlerts from '../../PrivatePages/ProjectsPage/ProjectStringsAlerts/reducer'
import projectUnusedStrings from '../../PrivatePages/ProjectsPage/ProjectUnusedStrings/reducer'
import locales from '../../localez/reducers'
import password from '../../devise/passwords/reducers'
import projects from '../../PrivatePages/ProjectsPage/reducers'
import roles from '../../roles/reducer'
import translations from '../../PrivatePages/TranslationsPage/reducers'
import users from '../../PrivatePages/UsersPage/reducers'

const rootReducer = combineReducers({
  authentication,
  confirmation,
  integrations,
  invitations,
  projectExports,
  projectLanguages,
  projectLanguageStats,
  projectStringsAlerts,
  projectUnusedStrings,
  locales,
  password,
  projects,
  resendConfirmation,
  roles,
  translations,
  users
})

export default rootReducer
