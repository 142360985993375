import ApiUtils from '../helpers/ApiUtils'
import LocalesApi from '../apis/LocalesApi'

const checkCodeAvailablity = (code) => (
  new LocalesApi().checkCodeAvailablity(code).then(ApiUtils.handleResponse)
)

const create = (attributes) => (
  new LocalesApi().create({ locale: attributes }).then(ApiUtils.handleResponse)
)

const fetchAll = () => (
  new LocalesApi().all().then(ApiUtils.handleResponse)
)

const remove = (id) => (
  new LocalesApi().destroy(id).then(ApiUtils.handleResponse)
)

const update = (id, attributes) => (
  new LocalesApi().update(id, { locale: attributes }).then(ApiUtils.handleResponse)
)

export default {
  checkCodeAvailablity,
  create,
  fetchAll,
  remove,
  update
}
