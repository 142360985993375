import React from 'react'
import { Spinner } from 'reactstrap'
import PropTypes from 'prop-types'

const PageLoader = ({ className, message, size }) => (
  <div className={`d-flex flex-row align-items-center ${className}`}>
    <Spinner className="mr-2" size={size} />
    {message}
  </div>
)

PageLoader.propTypes = {
  className: PropTypes.string,
  message: PropTypes.string.isRequired,
  size: PropTypes.string
}

PageLoader.defaultProps = {
  className: null,
  size: 'md'
}

export default PageLoader
