/*
** This holds all the routes for the Devise integration.
*/
import React from 'react'

import DeviseConfirmationsNew from '../../devise/confirmations/DeviseConfirmationsNew'
import DeviseInvitationAccept from '../../devise/invitations/DeviseInvitationAccept'
import DevisePasswordsNew from '../../devise/passwords/DevisePasswordsNew'
import DeviseSessionsNew from '../../devise/sessions/DeviseSessionsNew'

const DeviseRouter = [
  {
    path: '/users/:id/invitation/accept',
    // eslint-disable-next-line react/jsx-props-no-spreading
    component: (props) => <DeviseInvitationAccept {...props} />
  },
  {
    path: '/users/password/new',
    component: () => <DevisePasswordsNew />
  },
  {
    path: '/users/confirmation/new',
    component: () => <DeviseConfirmationsNew />
  },
  {
    path: '/users/sign_in',
    component: () => <DeviseSessionsNew />
  }
]

export default DeviseRouter
