import ApiUtils from '../../../helpers/ApiUtils'
import ProjectStringsAlertsApi from '../../../apis/ProjectStringsAlertsApi'

const accept = (slug, id) => (
  new ProjectStringsAlertsApi(slug).create({ id }).then(ApiUtils.handleResponse)
)

const checkIfAny = (slug) => (
  new ProjectStringsAlertsApi(slug).get({
    query: { check: true }
  }).then(ApiUtils.handleResponse)
)

const fetchAll = (slug, filter = null, page = null) => (
  new ProjectStringsAlertsApi(slug).get({
    query: {
      filter,
      page
    }
  }).then(ApiUtils.handleResponse)
)

const keep = (slug, id) => (
  new ProjectStringsAlertsApi(slug).keep(id).then(ApiUtils.handleResponse)
)

const reject = (slug, id) => (
  new ProjectStringsAlertsApi(slug).destroy(id).then(ApiUtils.handleResponse)
)

export default {
  accept,
  checkIfAny,
  fetchAll,
  keep,
  reject
}
