import RestfulClient from 'restful-json-api-client'

export default class LocalesApi extends RestfulClient {
  constructor() {
    super('/api', { resource: 'locales' })
  }

  checkCodeAvailablity(code) {
    return this.request('POST', { path: 'check', body: { code } })
  }
}
