export default {
  CHECK_CODE_AVAILABILITY_REQUEST: 'LOCALES_CHECK_CODE_AVAILABILITY_REQUEST',
  CHECK_CODE_AVAILABILITY_FAILURE: 'LOCALES_CHECK_CODE_AVAILABILITY_FAILURE',
  CHECK_CODE_AVAILABILITY_SUCCESS: 'LOCALES_CHECK_CODE_AVAILABILITY_SUCCESS',

  CREATE_REQUEST: 'LOCALES_CREATE_REQUEST',
  CREATE_FAILURE: 'LOCALES_CREATE_FAILURE',
  CREATE_SUCCESS: 'LOCALES_CREATE_SUCCESS',

  FETCH_ALL_REQUEST: 'LOCALES_FETCH_ALL_REQUEST',
  FETCH_ALL_FAILURE: 'LOCALES_FETCH_ALL_FAILURE',
  FETCH_ALL_SUCCESS: 'LOCALES_FETCH_ALL_SUCCESS',

  REMOVE_REQUEST: 'LOCALES_REMOVE_REQUEST',
  REMOVE_FAILURE: 'LOCALES_REMOVE_FAILURE',
  REMOVE_SUCCESS: 'LOCALES_REMOVE_SUCCESS',

  UPDATE_REQUEST: 'LOCALES_UPDATE_REQUEST',
  UPDATE_FAILURE: 'LOCALES_UPDATE_FAILURE',
  UPDATE_SUCCESS: 'LOCALES_UPDATE_SUCCESS'
}
