import React from 'react'
import {
  Badge,
  Button
} from 'reactstrap'
import moment from 'moment'
import PropTypes from 'prop-types'

import i18n from '../../config/i18n'

const UserRow = ({
  canEdit,
  canRemove,
  currentUserEmail,
  onEdit,
  onRemove,
  user: {
    created_at: createdAt,
    email,
    invitation,
    id,
    role
  }
}) => (
  <tr className={currentUserEmail === email ? 'me' : null}>
    <td>
      {email}
      {invitation && (
        <Badge className="ml-2" color="warning">
          {i18n.t('UsersPage.UserRow.badges.invitation')}
        </Badge>
      )}
      {currentUserEmail === email && (
        <Badge className="ml-2" color="primary">
          {i18n.t('UsersPage.UserRow.badges.you')}
        </Badge>
      )}
    </td>
    <td>{role || ''}</td>
    <td>{moment(createdAt).format('LLL')}</td>
    <td>
      <Button
        className="float-right"
        color="danger"
        disabled={canRemove === false}
        name="remove"
        onClick={() => onRemove(id)}
        outline
      >
        {i18n.t('buttons.remove')}
      </Button>
      <Button
        className="float-right mr-2"
        disabled={canEdit === false}
        name="edit"
        onClick={() => onEdit(id)}
        outline
      >
        {i18n.t('buttons.edit')}
      </Button>
    </td>
  </tr>
)

UserRow.propTypes = {
  canEdit: PropTypes.bool,
  canRemove: PropTypes.bool,
  currentUserEmail: PropTypes.string.isRequired,
  onEdit: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  user: PropTypes.shape({
    created_at: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    invitation: PropTypes.bool.isRequired,
    role: PropTypes.string.isRequired
  }).isRequired
}

UserRow.defaultProps = {
  canEdit: false,
  canRemove: false
}

export default UserRow
