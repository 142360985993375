import ApiUtils from '../helpers/ApiUtils'
import RolesApi from '../apis/RolesApi'

const fetchAll = () => (
  new RolesApi().all().then(ApiUtils.handleResponse)
)

export default {
  fetchAll
}
