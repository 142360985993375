import projectsConstants from './constants'
import projectsService from './services'

const checkNameAvailablity = (name, excludeProjectId) => {
  const request = () => ({
    type: projectsConstants.CHECK_NAME_AVAILABILITY_REQUEST
  })
  const success = () => ({
    type: projectsConstants.CHECK_NAME_AVAILABILITY_SUCCESS
  })
  const failure = (error) => ({
    error,
    type: projectsConstants.CHECK_NAME_AVAILABILITY_FAILURE
  })

  return (dispatch) => {
    dispatch(request())

    projectsService.checkNameAvailablity(name, excludeProjectId)
      .then(() => dispatch(success()))
      .catch((error) => dispatch(failure(error)))
  }
}

const create = (attributes) => {
  const request = () => ({ type: projectsConstants.CREATE_REQUEST })
  const success = (project) => ({
    project,
    type: projectsConstants.CREATE_SUCCESS
  })
  const failure = (error) => ({
    error,
    type: projectsConstants.CREATE_FAILURE
  })

  return (dispatch) => {
    dispatch(request())

    projectsService.create(attributes)
      .then((project) => dispatch(success(project)))
      .catch((error) => dispatch(failure(error)))
  }
}

const enqueueStatsRefresh = (slug) => {
  const request = () => ({
    type: projectsConstants.ENQUEUE_STATS_REFRESH_REQUEST
  })
  const success = () => ({
    slug,
    type: projectsConstants.ENQUEUE_STATS_REFRESH_SUCCESS
  })
  const failure = (error) => ({
    type: projectsConstants.ENQUEUE_STATS_REFRESH_FAILURE,
    error
  })

  return (dispatch) => {
    dispatch(request())

    projectsService.enqueueStatsRefresh(slug)
      .then((project) => dispatch(success(project)))
      .catch((error) => dispatch(failure(error)))
  }
}

const fetch = (slug) => {
  const request = () => ({
    type: projectsConstants.FETCH_REQUEST
  })
  const success = (project) => ({
    project,
    type: projectsConstants.FETCH_SUCCESS
  })
  const failure = (error) => ({
    type: projectsConstants.FETCH_FAILURE,
    error
  })

  return (dispatch) => {
    dispatch(request())

    projectsService.fetch(slug)
      .then((project) => dispatch(success(project)))
      .catch((error) => dispatch(failure(error)))
  }
}

const fetchAll = () => {
  const request = () => ({ type: projectsConstants.FETCH_ALL_REQUEST })
  const success = (projects) => ({
    projects,
    type: projectsConstants.FETCH_ALL_SUCCESS
  })
  const failure = (error) => ({
    error,
    type: projectsConstants.FETCH_ALL_FAILURE
  })

  return (dispatch) => {
    dispatch(request())

    projectsService.fetchAll()
      .then((projects) => dispatch(success(projects)))
      .catch((error) => dispatch(failure(error)))
  }
}

const fixingAlert = (projectId, selectedIds, locale) => {
  const request = () => ({ type: projectsConstants.FIXES_ALERT_REQUEST })
  const success = (project) => ({
    locale,
    project,
    selectedIds,
    type: projectsConstants.FIXES_ALERT_SUCCESS
  })
  const failure = (error) => ({
    error,
    type: projectsConstants.FIXES_ALERT_FAILURE
  })

  return (dispatch) => {
    dispatch(request())

    projectsService.fixesAlert(projectId, selectedIds, locale)
      .then((project) => dispatch(success(project)))
      .catch((error) => dispatch(failure(error)))
  }
}

const forceImport = (id, allFiles, gitOptions) => {
  const request = () => ({ id, type: projectsConstants.FORCE_IMPORT_REQUEST })
  const success = () => ({
    id,
    type: projectsConstants.FORCE_IMPORT_SUCCESS
  })
  const failure = (error) => ({
    error,
    type: projectsConstants.FORCE_IMPORT_FAILURE
  })

  return (dispatch) => {
    dispatch(request())

    projectsService.forceImport(id, allFiles, gitOptions)
      .then(() => dispatch(success()))
      .catch((error) => dispatch(failure(error)))
  }
}

const remove = (id) => {
  const request = () => ({ id, type: projectsConstants.REMOVE_REQUEST })
  const success = () => ({ id, type: projectsConstants.REMOVE_SUCCESS })
  const failure = (error) => ({
    error,
    type: projectsConstants.REMOVE_FAILURE
  })

  return (dispatch) => {
    dispatch(request())

    projectsService.remove(id)
      .then(() => dispatch(success()))
      .catch((error) => dispatch(failure(error)))
  }
}

const update = (id, locales, name, paths) => {
  const request = () => ({ type: projectsConstants.UPDATE_REQUEST })
  const success = (project) => ({
    project,
    type: projectsConstants.UPDATE_SUCCESS
  })
  const failure = (error) => ({
    error,
    type: projectsConstants.UPDATE_FAILURE
  })

  return (dispatch) => {
    dispatch(request())

    projectsService.update(id, locales, name, paths)
      .then((project) => dispatch(success(project)))
      .catch((error) => dispatch(failure(error)))
  }
}

export default {
  checkNameAvailablity,
  create,
  enqueueStatsRefresh,
  fetch,
  fetchAll,
  fixingAlert,
  forceImport,
  remove,
  update
}
