import dotProp from 'dot-prop-immutable'

import usersConstants from './constants'

const initialState = {
  fetchingError: null,
  fetchingStatus: 'idle',

  items: null,

  invitationError: null,
  invitationStatus: 'idle',

  removingError: null,
  removingStatus: 'idle',

  updateError: null,
  updateStatus: 'idle'
}

export default (state = initialState, action) => {
  const error = action.error ? (action.error.data || action.error.message) : null
  let newState

  switch (action.type) {
    case usersConstants.FETCH_ALL_REQUEST:
      newState = dotProp.set(state, 'fetchingError', null)
      return dotProp.set(newState, 'fetchingStatus', 'fetching')
    case usersConstants.FETCH_ALL_FAILURE:
      newState = dotProp.set(state, 'fetchingError', error)
      newState = dotProp.set(newState, 'fetchingStatus', 'failed')
      return dotProp.set(newState, 'items', [])
    case usersConstants.FETCH_ALL_SUCCESS:
      newState = dotProp.set(state, 'fetchingError', null)
      newState = dotProp.set(newState, 'fetchingStatus', 'succeeded')
      return dotProp.set(newState, 'items', action.users)

    case usersConstants.INVITATION_CREATION_REQUEST:
      newState = dotProp.set(state, 'invitationError', null)
      return dotProp.set(newState, 'invitationStatus', 'inviting')
    case usersConstants.INVITATION_CREATION_FAILURE:
      newState = dotProp.set(state, 'invitationError', error)
      return dotProp.set(newState, 'invitationStatus', 'failed')
    case usersConstants.INVITATION_CREATION_SUCCESS:
      newState = dotProp.set(state, 'invitationError', null)
      newState = dotProp.set(newState, 'invitationStatus', 'succeeded')
      return dotProp.set(newState, 'items',
        [...state.items, action.invitation])

    case usersConstants.REMOVE_REQUEST:
      newState = dotProp.set(state, 'removingError', null)
      return dotProp.set(newState, 'removingStatus', 'removing')
    case usersConstants.REMOVE_FAILURE:
      newState = dotProp.set(state, 'removingError', error)
      return dotProp.set(newState, 'removingStatus', 'failed')
    case usersConstants.REMOVE_SUCCESS: {
      const itemIndex = state.items.findIndex(
        (item) => item.id === action.userId
      )
      newState = dotProp.delete(state, `items.${itemIndex}`)
      newState = dotProp.set(newState, 'removingError', null)
      return dotProp.set(newState, 'removingStatus', 'succeeded')
    }

    case usersConstants.UPDATE_REQUEST:
      newState = dotProp.set(state, 'updateError', null)
      return dotProp.set(newState, 'updateStatus', 'updating')
    case usersConstants.UPDATE_FAILURE:
      newState = dotProp.set(state, 'updateError', error)
      return dotProp.set(newState, 'updateStatus', 'failed')
    case usersConstants.UPDATE_SUCCESS: {
      const itemIndex = state.items.findIndex(
        (item) => item.id === action.user.id
      )
      newState = dotProp.set(state, `items.${itemIndex}`, action.user)
      newState = dotProp.set(newState, 'updateError', null)
      return dotProp.set(newState, 'updateStatus', 'succeeded')
    }
    default:
      return state
  }
}
