import React from 'react'
import { Button, Form } from 'reactstrap'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import AlertMessage from '../../components/AlertMessage'
import FormInputField from '../../components/FormInputField'
import i18n from '../../config/i18n'
import integrationsActions from '../../integrations/actions'

class GitlabIntegrationForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      gitlabPrivateToken: ''
    }
  }

  setGitlabPrivateToken(gitlabPrivateToken) {
    this.setState({
      gitlabPrivateToken
    })
  }

  activateGitlabIntegration(event) {
    const { dispatch } = this.props

    const { gitlabPrivateToken } = this.state

    event.preventDefault()

    if (gitlabPrivateToken === '') {
      this.privateTokenField.showAsInvalid()
    } else {
      dispatch(integrationsActions.activate(
        'gitlab',
        gitlabPrivateToken
      ))
    }
  }

  render() {
    const {
      activationError,
      activationPlatform,
      activationStatus
    } = this.props

    const { gitlabPrivateToken } = this.state

    return (
      <Form>
        <FormInputField
          inputId="gitlab-private-token"
          inputName="gitlab_private_token"
          inputType="text"
          labelText={i18n.t('SettingsPage.Integrations.fields.privateToken')}
          onChange={({ target: { value } }) => this.setGitlabPrivateToken(value)}
          ref={(component) => { this.privateTokenField = component }}
          value={gitlabPrivateToken}
        />

        {activationPlatform === 'gitlab' && activationStatus === 'failed'
          && typeof activationError === 'string' && (
          <AlertMessage>
            {i18n.t('errors.somethingWentWrong')}
          </AlertMessage>
        )}

        <Button
          color="primary"
          onClick={(event) => this.activateGitlabIntegration(event)}
        >
          {i18n.t('SettingsPage.Integrations.buttons.activate')}
        </Button>
      </Form>
    )
  }
}

GitlabIntegrationForm.propTypes = {
  activationError: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  activationPlatform: PropTypes.string,
  activationStatus: PropTypes.string,
  dispatch: PropTypes.func.isRequired
}

GitlabIntegrationForm.defaultProps = {
  activationError: null,
  activationPlatform: null,
  activationStatus: 'idle'
}

const mapStateToProps = ({
  integrations: {
    activationError,
    activationPlatform,
    activationStatus,

    items: integrations
  }
}) => ({
  activationError,
  activationPlatform,
  activationStatus,
  integrations
})

export default connect(mapStateToProps)(GitlabIntegrationForm)
