import ApiUtils from '../../helpers/ApiUtils'
import UsersInvitationApi from '../../apis/UsersInvitationApi'

const accept = ({ invitationToken, password, passwordConfirmation }) => (
  new UsersInvitationApi().update(0, {
    user: {
      invitation_token: invitationToken,
      password,
      password_confirmation: passwordConfirmation
    }
  }).then(ApiUtils.handleResponse)
)

export default {
  accept
}
