import React from 'react'
import { Button, FormGroup } from 'reactstrap'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

import DeviseLayout from '../DeviseLayout'
import EmailField from '../../components/EmailField'
import i18n from '../../config/i18n'
import PageLoader from '../../components/PageLoader'
import passwordActions from './actions'

class DevisePasswordsNew extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      email: ''
    }

    const { dispatch } = this.props
    dispatch(passwordActions.reset())
  }

  handleEmailChange({ target: { value } }) {
    this.setState({
      email: value
    })
  }

  handleSubmit(event) {
    const { dispatch } = this.props
    const { email } = this.state

    event.preventDefault()

    if (email === '') {
      this.emailField.showAsInvalid()
    } else {
      dispatch(passwordActions.sendPasswordInstructions(email))
    }
  }

  render() {
    const { sendingInstructionsStatus } = this.props

    const { email } = this.state

    return (
      <DeviseLayout
        onSubmit={(event) => this.handleSubmit(event)}
        title={i18n.t('devise.DevisePasswordsNew.title')}
      >
        <EmailField
          inputId="email"
          inputName="email"
          onChange={(event) => this.handleEmailChange(event)}
          ref={(element) => { this.emailField = element }}
          value={email}
        />
        <FormGroup>
          <div className="d-flex flex-column">
            {sendingInstructionsStatus === 'sending' && (
              <PageLoader
                message={i18n.t('devise.DevisePasswordsNew.sending')}
              />
            )}
            <Button
              className="mb-3"
              color="primary"
            >
              {i18n.t('devise.DevisePasswordsNew.sendButton')}
            </Button>
            <Link
              className="btn btn-secondary"
              to="/users/sign_in"
            >
              {i18n.t('buttons.back')}
            </Link>
          </div>
        </FormGroup>
      </DeviseLayout>
    )
  }
}

DevisePasswordsNew.propTypes = {
  dispatch: PropTypes.func.isRequired,
  sendingInstructionsStatus: PropTypes.string.isRequired
}

const mapStateToProps = ({
  password: { sendingInstructionsStatus }
}) => ({
  sendingInstructionsStatus
})

export default connect(mapStateToProps)(DevisePasswordsNew)
