import React from 'react'
import { Alert, Container } from 'reactstrap'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Trans } from 'react-i18next'

import i18n from '../../../config/i18n'

const ImportIssuesBanner = ({ count, projectId }) => (
  <Container fluid>
    <Alert className="d-flex flex-row flex-fill justify-content-between align-items-center" color="warning">
      <div className="d-block">
        <Trans i18nKey="ProjectsPage.ImportIssues.alert.message" count={count}>
          This project has&nbsp;
          <strong>
            {{ count }}
            &nbsp;conflicts and/or missing locales
          </strong>
          , please fix impacted strings.
        </Trans>
      </div>
      <Link
        className="btn btn-outline-warning text-warning text-decoration-none px-5"
        to={`/projects/${projectId}/issues`}
      >
        {i18n.t('ProjectsPage.ImportIssues.alert.button')}
      </Link>
    </Alert>
  </Container>
)

ImportIssuesBanner.propTypes = {
  count: PropTypes.number.isRequired,
  projectId: PropTypes.string.isRequired
}

export default ImportIssuesBanner
