import dotProp from 'dot-prop-immutable'

import deviseConstants from '../constants'

const initialState = {
  sendingInstructionsStatus: 'idle'
}

export default (state = initialState, action) => {
  switch (action.type) {
    case deviseConstants.PASSWORD_RESET_REQUEST:
      return dotProp.set(state, 'sendingInstructionsStatus', 'sending')
    case deviseConstants.PASSWORD_RESET_SUCCESS:
      return dotProp.set(state, 'sendingInstructionsStatus', 'succeeded')
    case deviseConstants.PASSWORD_RESET_FAILURE:
      return dotProp.set(state, 'sendingInstructionsStatus', 'failed')
    case deviseConstants.RESET_NEW_PASSWORD:
      return initialState
    default:
      return state
  }
}
