import React from 'react'
import { CardTitle } from 'reactstrap'
import PropTypes from 'prop-types'

import ImportStatus from './ImportStatus'
import Menu from './Menu'
import { truncateString } from '../../../helpers/StringHelpers'

const Header = ({
  onClickDelete,
  onClickEdit,
  onClickForceImport,
  project: {
    id,
    import_failure_reason: failureReason,
    import_stats: importStats,
    name,
    state,
    to_be_deleted: toBeDeleted
  }
}) => (
  <>
    <div
      className="d-flex flex-row justify-content-between"
      style={{ height: '50px' }}
    >
      <CardTitle tag="h5" title={name}>
        {truncateString(name, 30)}
      </CardTitle>

      {toBeDeleted === false && (
        <Menu
          onClickDelete={() => onClickDelete()}
          onClickEdit={() => onClickEdit()}
          onClickForceImport={() => onClickForceImport()}
          state={state}
        />
      )}
    </div>

    <hr className="mt-0" />

    {toBeDeleted === false && (
      <ImportStatus
        failureReason={failureReason}
        importStats={importStats}
        projectId={id}
        state={state}
      />
    )}
  </>
)

Header.propTypes = {
  onClickDelete: PropTypes.func.isRequired,
  onClickEdit: PropTypes.func.isRequired,
  onClickForceImport: PropTypes.func.isRequired,
  project: PropTypes.shape({
    id: PropTypes.string.isRequired,
    import_failure_reason: PropTypes.string,
    import_stats: PropTypes.shape(),
    name: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    to_be_deleted: PropTypes.bool
  }).isRequired
}

export default Header
