export default {
  CHECK_NAME_AVAILABILITY_REQUEST: 'PROJECTS_CHECK_NAME_AVAILABILITY_REQUEST',
  CHECK_NAME_AVAILABILITY_FAILURE: 'PROJECTS_CHECK_NAME_AVAILABILITY_FAILURE',
  CHECK_NAME_AVAILABILITY_SUCCESS: 'PROJECTS_CHECK_NAME_AVAILABILITY_SUCCESS',

  CREATE_REQUEST: 'PROJECTS_CREATE_REQUEST',
  CREATE_FAILURE: 'PROJECTS_CREATE_FAILURE',
  CREATE_SUCCESS: 'PROJECTS_CREATE_SUCCESS',

  ENQUEUE_STATS_REFRESH_REQUEST: 'PROJECTS_ENQUEUE_STATS_REFRESH_REQUEST',
  ENQUEUE_STATS_REFRESH_FAILURE: 'PROJECTS_ENQUEUE_STATS_REFRESH_FAILURE',
  ENQUEUE_STATS_REFRESH_SUCCESS: 'PROJECTS_ENQUEUE_STATS_REFRESH_SUCCESS',

  FETCH_REQUEST: 'PROJECTS_FETCH_REQUEST',
  FETCH_FAILURE: 'PROJECTS_FETCH_FAILURE',
  FETCH_SUCCESS: 'PROJECTS_FETCH_SUCCESS',

  FETCH_ALL_REQUEST: 'PROJECTS_FETCH_ALL_REQUEST',
  FETCH_ALL_FAILURE: 'PROJECTS_FETCH_ALL_FAILURE',
  FETCH_ALL_SUCCESS: 'PROJECTS_FETCH_ALL_SUCCESS',

  FIXES_ALERT_REQUEST: 'PROJECTS_FIXES_ALERT_REQUEST',
  FIXES_ALERT_FAILURE: 'PROJECTS_FIXES_ALERT_FAILURE',
  FIXES_ALERT_SUCCESS: 'PROJECTS_FIXES_ALERT_SUCCESS',

  FORCE_IMPORT_REQUEST: 'PROJECTS_FORCE_IMPORT_REQUEST',
  FORCE_IMPORT_FAILURE: 'PROJECTS_FORCE_IMPORT_FAILURE',
  FORCE_IMPORT_SUCCESS: 'PROJECTS_FORCE_IMPORT_SUCCESS',

  REMOVE_REQUEST: 'PROJECTS_REMOVE_REQUEST',
  REMOVE_FAILURE: 'PROJECTS_REMOVE_FAILURE',
  REMOVE_SUCCESS: 'PROJECTS_REMOVE_SUCCESS',

  UPDATE_REQUEST: 'PROJECTS_UPDATE_REQUEST',
  UPDATE_FAILURE: 'PROJECTS_UPDATE_FAILURE',
  UPDATE_SUCCESS: 'PROJECTS_UPDATE_SUCCESS'
}
