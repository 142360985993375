import dotProp from 'dot-prop-immutable'

import projectLanguagesConstants from './constants'

const initialState = {
  creationError: null,
  creationStatus: 'idle',

  fetchingAllError: null,
  fetchingAllStatus: 'idle',

  items: null
}

export default (state = initialState, action) => {
  const error = action.error ? (action.error.data || action.error.message) : null
  const sorted = (items) => items.sort((a, b) => a.locale.localeCompare(b.locale))
  let newState

  switch (action.type) {
    case projectLanguagesConstants.CREATE_REQUEST:
      newState = dotProp.set(state, 'creationError', null)
      return dotProp.set(newState, 'creationStatus', 'creation')
    case projectLanguagesConstants.CREATE_FAILURE:
      newState = dotProp.set(state, 'creationError', error)
      return dotProp.set(newState, 'creationStatus', 'failed')
    case projectLanguagesConstants.CREATE_SUCCESS:
      newState = dotProp.set(state, 'creationError', null)
      newState = dotProp.set(newState, 'creationStatus', 'succeeded')
      return dotProp.set(newState, 'items',
        sorted([...state.items || [], action.language]))

    case projectLanguagesConstants.FETCH_ALL_REQUEST:
      newState = dotProp.set(state, 'fetchingAllError', null)
      return dotProp.set(newState, 'fetchingAllStatus', 'fetching')
    case projectLanguagesConstants.FETCH_ALL_FAILURE:
      newState = dotProp.set(state, 'fetchingAllError', error)
      return dotProp.set(newState, 'fetchingAllStatus', 'failed')
    case projectLanguagesConstants.FETCH_ALL_SUCCESS:
      newState = dotProp.set(state, 'fetchingAllError', null)
      newState = dotProp.set(newState, 'fetchingAllStatus', 'succeeded')
      return dotProp.set(newState, 'items', action.languages)

    default:
      return state
  }
}
