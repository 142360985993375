export default {
  CREATE_REQUEST: 'PROJECT_LANGUAGES_CREATE_REQUEST',
  CREATE_FAILURE: 'PROJECT_LANGUAGES_CREATE_FAILURE',
  CREATE_SUCCESS: 'PROJECT_LANGUAGES_CREATE_SUCCESS',

  FETCH_REQUEST: 'PROJECT_LANGUAGES_FETCH_REQUEST',
  FETCH_FAILURE: 'PROJECT_LANGUAGES_FETCH_FAILURE',
  FETCH_SUCCESS: 'PROJECT_LANGUAGES_FETCH_SUCCESS',

  FETCH_ALL_REQUEST: 'PROJECT_LANGUAGES_FETCH_ALL_REQUEST',
  FETCH_ALL_FAILURE: 'PROJECT_LANGUAGES_FETCH_ALL_FAILURE',
  FETCH_ALL_SUCCESS: 'PROJECT_LANGUAGES_FETCH_ALL_SUCCESS'
}
