import React from 'react'
import { Button } from 'reactstrap'
import PropTypes from 'prop-types'

import i18n from '../config/i18n'
import StatusFailedIcon from '../svgs/StatusFailedIcon'
import StatusPendingIcon from '../svgs/StatusPendingIcon'
import StatusRunningIcon from '../svgs/StatusRunningIcon'
import StatusSuccessIcon from '../svgs/StatusSuccessIcon'

const IntegrationBadge = ({ state }) => {
  const badgeColorFromState = () => {
    if (state === 'pending') return 'warning'
    if (state.endsWith('ing')) return 'primary'
    if (state.endsWith('_succeeded')) return 'success'
    if (state.endsWith('_failed')) return 'danger'

    return 'warning'
  }

  const badgeIconFromState = () => {
    if (state === 'pending') return <StatusPendingIcon />
    if (state === 'fetching_succeeded') return <StatusSuccessIcon />
    if (state.endsWith('ing')) return <StatusRunningIcon />
    if (state.endsWith('_succeeded')) return <StatusRunningIcon />
    if (state.endsWith('_failed')) return <StatusFailedIcon />

    return <StatusPendingIcon />
  }

  return (
    <Button
      className="btn-badge"
      color={badgeColorFromState()}
      outline
    >
      {badgeIconFromState()}
      &nbsp;
      {i18n.t(`IntegrationBadge.${state}`)}
    </Button>
  )
}

IntegrationBadge.propTypes = {
  state: PropTypes.string.isRequired
}

export default IntegrationBadge
