const filterOptions = [
  { label: 'Path', value: 'path' },
  { label: 'Key', value: 'key' },
  { label: 'Locale', value: 'locale' },
  { label: 'Value', value: 'value' }
]

const defaultFilterOptions = filterOptions[0]

export default {
  defaultFilterOptions,
  filterOptions
}
