import React from 'react'
import PropTypes from 'prop-types'

const ExclamationMarkCircle = ({ opacity, size }) => (
  <svg
    fill="currentColor"
    height={`${size}em`}
    width={`${size}em`}
    viewBox="0 0 24 24"
  >
    <path
      d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 100-16 8 8 0 000 16zm-1-5h2v2h-2v-2zm0-8h2v6h-2V7z"
      fillOpacity={opacity}
    />
  </svg>
)

ExclamationMarkCircle.propTypes = {
  opacity: PropTypes.string,
  size: PropTypes.number
}

ExclamationMarkCircle.defaultProps = {
  opacity: '1',
  size: 1
}

export default ExclamationMarkCircle
