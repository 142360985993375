import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Container } from 'reactstrap'
import PropTypes from 'prop-types'

import i18n from '../../../config/i18n'
import PageHeader from '../../../components/PageHeader'
import projectUnusedStringsActions from './actions'
import UnusedStringsTable from './UnusedStringsTable'

const ProjectUnusedStrings = ({
  dispatch,
  match: { params: { slug } },
  projectUnusedStrings,
  projectUnusedStringsCurrentPage,
  projectUnusedStringsCount,
  projectUnusedStringsTotalPages,
  projectUnusedStringsFetchingStatus
}) => {
  const [page, setPage] = useState(null)

  if (projectUnusedStringsFetchingStatus === 'idle') {
    dispatch(projectUnusedStringsActions.fetchAll(slug, page))
  }

  const moveToPage = (selected) => {
    setPage(selected)
    dispatch(projectUnusedStringsActions.fetchAll(slug, selected))
  }

  const i18nBase = 'ProjectsPage.ProjectUnusedStrings'

  return (
    <Container fluid className="d-flex flex-column flex-fill mt-3">
      <PageHeader
        projectSlug={slug}
        title={i18n.t(`${i18nBase}.title`, { count: projectUnusedStringsCount })}
      />

      <div className="d-flex flex-column flex-fill mt-3">
        <UnusedStringsTable
          moveToPage={(selected) => moveToPage(selected)}
          paginationCurrentPage={projectUnusedStringsCurrentPage}
          paginationTotalPages={projectUnusedStringsTotalPages}
          projectUnusedStrings={projectUnusedStrings}
          projectUnusedStringsFetchingStatus={projectUnusedStringsFetchingStatus}
        />
      </div>
    </Container>
  )
}

ProjectUnusedStrings.propTypes = {
  dispatch: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      slug: PropTypes.string
    })
  }),
  projectUnusedStrings: PropTypes.arrayOf(PropTypes.shape()),
  projectUnusedStringsCurrentPage: PropTypes.number,
  projectUnusedStringsCount: PropTypes.number,
  projectUnusedStringsFetchingStatus: PropTypes.string,
  projectUnusedStringsTotalPages: PropTypes.number
}

ProjectUnusedStrings.defaultProps = {
  match: {},
  projectUnusedStrings: null,
  projectUnusedStringsCurrentPage: null,
  projectUnusedStringsCount: null,
  projectUnusedStringsFetchingStatus: 'idle',
  projectUnusedStringsTotalPages: null
}

const mapStateToProps = ({
  projectUnusedStrings: {
    fetchingStatus: projectUnusedStringsFetchingStatus,
    itemsCurrentPage: projectUnusedStringsCurrentPage,
    itemsCount: projectUnusedStringsCount,
    items: projectUnusedStrings,
    itemsTotalPages: projectUnusedStringsTotalPages
  }
}) => ({
  projectUnusedStrings,
  projectUnusedStringsCurrentPage,
  projectUnusedStringsCount,
  projectUnusedStringsFetchingStatus,
  projectUnusedStringsTotalPages
})

export default connect(mapStateToProps)(ProjectUnusedStrings)
