import React from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Form,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from 'reactstrap'
import { connect } from 'react-redux'

import AlertMessage from '../../components/AlertMessage'
import FormInputField from '../../components/FormInputField'
import i18n from '../../config/i18n'
import integrationsActions from '../../integrations/actions'
import PageLoader from '../../components/PageLoader'

class GitlabIntegrationModal extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      gitlabPrivateToken: props.integration.private_token
    }
  }

  componentDidUpdate(prevProps) {
    const { updateStatus: prevUpdateStatus } = prevProps
    const { onClose, updateStatus } = this.props

    if (prevUpdateStatus === 'updating' && updateStatus === 'succeeded') {
      onClose()
    }
  }

  setGitlabPrivateToken(gitlabPrivateToken) {
    this.setState({
      gitlabPrivateToken
    })
  }

  saveIntegration() {
    const { dispatch, integration } = this.props
    const { gitlabPrivateToken } = this.state

    if (gitlabPrivateToken === '') {
      this.privateTokenField.showAsInvalid()
    } else {
      dispatch(integrationsActions.update(
        integration.id,
        gitlabPrivateToken
      ))
    }
  }

  render() {
    const i18nBase = 'SettingsPage.Integrations'
    const {
      integration,
      isOpen,
      onClose,
      updateError,
      updateStatus
    } = this.props

    const { gitlabPrivateToken } = this.state

    return (
      <Modal isOpen={isOpen} toggle={() => onClose()}>
        <Form>
          <ModalHeader>
            {i18n.t(`${i18nBase}.stateDescriptions.${integration.state}`, { platform: 'Gitlab' })}
          </ModalHeader>
          <ModalBody className="d-flex flex-column">
            <FormInputField
              inputId="gitlab-private-token"
              inputName="gitlab_private_token"
              inputType="text"
              labelText={i18n.t('SettingsPage.Integrations.fields.privateToken')}
              onChange={({ target: { value } }) => this.setGitlabPrivateToken(value)}
              ref={(component) => { this.privateTokenField = component }}
              value={gitlabPrivateToken}
            />
            {(updateStatus === 'updating') && (
              <PageLoader message="updating integration" />
            )}
            {updateError && (
              <AlertMessage>
                {typeof updateError === 'string'
                  ? updateError
                  : i18n.t('errors.somethingWentWrong')}
              </AlertMessage>
            )}
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => onClose()}>
              {i18n.t('buttons.cancel')}
            </Button>
            <Button
              color="primary"
              onClick={() => this.saveIntegration()}
            >
              {i18n.t('buttons.save')}
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    )
  }
}

GitlabIntegrationModal.defaultProps = {
  updateError: null,
  updateStatus: 'idle'
}

GitlabIntegrationModal.propTypes = {
  dispatch: PropTypes.func.isRequired,
  integration: PropTypes.shape({
    id: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    private_token: PropTypes.string.isRequired
  }).isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  updateError: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  updateStatus: PropTypes.string
}

const mapStateToProps = ({
  integrations: {
    updateError,
    updateStatus
  }
}) => ({
  updateError,
  updateStatus
})

export default connect(mapStateToProps)(GitlabIntegrationModal)
