import React from 'react'
import { connect } from 'react-redux'
import {
  Button,
  Form,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner
} from 'reactstrap'
import PropTypes from 'prop-types'

import AlertMessage from '../../../components/AlertMessage'
import PageLoader from '../../../components/PageLoader'
import projectExportsActions from './actions'
import i18n from '../../../config/i18n'

class ProjectExportCreationConfirmationModal extends React.Component {
  componentDidUpdate(prevProps) {
    const { creationStatus: prevCreationStatus } = prevProps
    const { onClose, creationStatus, projectExports } = this.props

    if (prevCreationStatus === 'creating' && creationStatus === 'succeeded') {
      const projectExport = projectExports.find(
        (item) => ['pending', 'exporting'].includes(item.state)
      )

      onClose(projectExport.id)
    }
  }

  handleSubmit(event) {
    const { dispatch, projectId } = this.props

    event.preventDefault()

    dispatch(projectExportsActions.create(projectId))
  }

  render() {
    const {
      creationError,
      creationStatus,
      isOpen,
      onClose,
      pushAllStrings
    } = this.props

    const i18nBase = 'ProjectsPage.ProjectExports.ProjectExportCreationConfirmationModal'

    return (
      <Modal isOpen={isOpen} toggle={() => onClose()}>
        <Form>
          <ModalHeader toggle={() => onClose()}>
            {i18n.t(`${i18nBase}.${pushAllStrings ? 'allStringsTitle' : 'title'}`)}
          </ModalHeader>
          <ModalBody>
            <div className="d-flex flex-column">
              <p className="lead">
                {i18n.t(`${i18nBase}.${pushAllStrings ? 'allStringHeaderMessage' : 'headerMessage'}`)}
              </p>
              <p>{i18n.t(`${i18nBase}.bodyMessage`)}</p>
            </div>

            {(creationStatus === 'importing') && (
              <PageLoader message={i18n.t(`${i18nBase}.importing`)} />
            )}

            {creationStatus === 'failed' && (
              <AlertMessage>{creationError}</AlertMessage>
            )}
          </ModalBody>
          <ModalFooter>
            <div className="d-flex flex-row flex-fill justify-content-between">
              <div className="d-flex flex-row">
                {creationStatus === 'creating' && (
                  <>
                    <Spinner className="text-secondary" />
                    <div className="d-flex flex-column justify-content-center">
                      <span className="ml-3">{i18n.t(`${i18nBase}.creating`)}</span>
                    </div>
                  </>
                )}
              </div>

              <div className="d-flex flex-row">
                <Button
                  className="mr-2"
                  color="secondary"
                  onClick={() => onClose()}
                >
                  {i18n.t('buttons.cancel')}
                </Button>
                <Button
                  color="primary"
                  onClick={(event) => this.handleSubmit(event)}
                  type="submit"
                >
                  {i18n.t('buttons.continue')}
                </Button>
              </div>
            </div>
          </ModalFooter>
        </Form>
      </Modal>
    )
  }
}

ProjectExportCreationConfirmationModal.propTypes = {
  creationError: PropTypes.string,
  creationStatus: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  projectExports: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired
  })),
  projectId: PropTypes.string.isRequired,
  pushAllStrings: PropTypes.bool
}

ProjectExportCreationConfirmationModal.defaultProps = {
  creationError: null,
  creationStatus: 'idle',
  projectExports: null,
  isOpen: false,
  pushAllStrings: false
}

const mapStateToProps = ({
  projectExports: {
    creationError,
    creationStatus,
    items: projectExports
  }
}) => ({
  creationError,
  creationStatus,
  projectExports
})

export default connect(mapStateToProps)(ProjectExportCreationConfirmationModal)
