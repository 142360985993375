import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Redirect, Route } from 'react-router-dom'

const AuthRoute = ({
  component,
  exact,
  loggingStatus,
  path
}) => {
  const updateChildrenWithProps = (history, location, match) => (
    React.Children.map(
      component,
      (child) => React.cloneElement(child, {
        history,
        location,
        match
      })
    )
  )

  return (
    <Route
      exact={exact}
      path={path}
      render={({ history, location, match }) => (
        loggingStatus === 'succeeded' ? (
          updateChildrenWithProps(history, location, match)
        ) : (
          <Redirect
            to={{
              pathname: '/users/sign_in',
              state: { from: location }
            }}
          />
        )
      )}
    />
  )
}

AuthRoute.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.element
  ]).isRequired,
  exact: PropTypes.bool,
  loggingStatus: PropTypes.string,
  path: PropTypes.string.isRequired
}

AuthRoute.defaultProps = {
  exact: null,
  loggingStatus: 'idle'
}

const mapStateToProps = ({
  authentication: {
    loggingStatus
  }
}) => ({
  loggingStatus
})

export default connect(mapStateToProps)(AuthRoute)
