import React from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'

import i18n from '../config/i18n'

const TableSorting = ({
  sortingOption,
  onSortChange
}) => {
  const i18nBase = 'TableSorting'

  const sortingOptions = ['alpha', 'newests'].map((key) => (
    { label: i18n.t(`${i18nBase}.options.${key}`), value: key }
  ))

  return (
    <Select
      id="selected-sorting"
      name="selectedSorting"
      onChange={onSortChange}
      options={sortingOptions}
      value={sortingOption}
    />
  )
}

TableSorting.propTypes = {
  sortingOption: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired
  }),
  onSortChange: PropTypes.func.isRequired
}

TableSorting.defaultProps = {
  sortingOption: null
}

export default TableSorting
