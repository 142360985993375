import rolesConstants from './constants'
import rolesService from './services'

const fetchAll = () => {
  const request = () => ({ type: rolesConstants.FETCH_ALL_REQUEST })
  const success = (roles) => ({
    roles,
    type: rolesConstants.FETCH_ALL_SUCCESS
  })
  const failure = (error) => ({
    error,
    type: rolesConstants.FETCH_ALL_FAILURE
  })

  return (dispatch) => {
    dispatch(request())

    rolesService.fetchAll()
      .then((roles) => dispatch(success(roles)))
      .catch((error) => dispatch(failure(error)))
  }
}

export default {
  fetchAll
}
