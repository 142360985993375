import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import AlertMessage from '../../../components/AlertMessage'
import Header from './Header'
import i18n from '../../../config/i18n'
import ImportIssuesBanner from './ImportIssuesBanner'
import localesActions from '../../../localez/actions'
import projectActions from '../actions'
import ProjectExportBanner from './ProjectExportBanner'
import ProjectExports from '../ProjectExports'
import ProjectLanguageCreationModal from './ProjectLanguages/CreationModal'
import projectLanguagesActions from './ProjectLanguages/actions'
import projectStringsAlertsActions from '../ProjectStringsAlerts/actions'
import projectUnusedStringsActions from '../ProjectUnusedStrings/actions'
import PageLoader from '../../../components/PageLoader'
import TranslationList from './TranslationList'
import UnusedStringsBanner from './UnusedStringsBanner'

class ProjectPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      showProjectLanguageCreationModal: false
    }
  }

  componentDidMount() {
    const {
      dispatch,
      fetchingLocalesStatus,
      match: { params: { slug } },
      userRole
    } = this.props

    if (fetchingLocalesStatus === 'idle') dispatch(localesActions.fetchAll())

    dispatch(projectActions.fetch(slug))
    dispatch(projectLanguagesActions.fetchAll(slug))

    if (userRole === 'admin') {
      dispatch(projectStringsAlertsActions.checkIfAny(slug))
      dispatch(projectUnusedStringsActions.checkIfAny(slug))
    }
  }

  setShowProjectLanguageCreationModal(showProjectLanguageCreationModal) {
    this.setState({
      showProjectLanguageCreationModal
    })
  }

  removeProjectString(projectStringId, language) {
    const { dispatch, project } = this.props

    dispatch(
      projectActions.destroyProjectString(project.id, language, projectStringId)
    )
  }

  render() {
    const {
      fetchingError,
      fetchingAllLanguagesError,
      fetchingAllLanguagesStatus,
      fetchingLocalesError,
      fetchingLocalesStatus,
      fetchingStatus,
      languages,
      locales,
      project,
      projectStringsAlertsCheckingStatus,
      projectStringsAlertsCount,
      projectStringsAlertsHasAny,
      projectUnusedStringsCheckingStatus,
      projectUnusedStringsCount,
      projectUnusedStringsHasAny,
      userRole
    } = this.props

    const {
      showProjectLanguageCreationModal
    } = this.state

    const i18nBase = 'ProjectsPage'

    if (fetchingStatus === 'fetching' || project === null) {
      return (
        <div className="d-flex flex-column flex-fill justify-content-center align-items-center">
          <PageLoader message={i18n.t('ProjectsPage.fetching')} />
        </div>
      )
    }

    if (fetchingLocalesStatus === 'fetching' || locales === null) {
      return (
        <div className="d-flex flex-column flex-fill justify-content-center align-items-center">
          <PageLoader message={i18n.t('ProjectsPage.fetchingLocales')} />
        </div>
      )
    }

    if (fetchingStatus === 'failed' || fetchingLocalesStatus === 'failed'
        || fetchingAllLanguagesError === 'failed'
    ) {
      return (
        <div className="d-flex flex-column flex-fill justify-content-center align-items-center">
          <AlertMessage>
            {fetchingError || fetchingLocalesError || fetchingAllLanguagesError}
          </AlertMessage>
        </div>
      )
    }

    return (
      <>
        <Header
          locales={locales}
          project={project}
        />

        {userRole === 'admin'
          && projectStringsAlertsCheckingStatus === 'checking'
          && (
            <div className="d-flex flex-row mb-4 justify-content-center">
              <PageLoader
                message={i18n.t(`${i18nBase}.ProjectStringsAlerts.fetching`)}
              />
            </div>
          )}

        {userRole === 'admin'
          && projectStringsAlertsCheckingStatus === 'failed'
          && (
            <AlertMessage>
              {i18n.t(`${i18nBase}.ProjectStringsAlerts.fetchingFailed`)}
            </AlertMessage>
          )}

        {userRole === 'admin'
          && projectStringsAlertsCheckingStatus === 'succeeded'
          && projectStringsAlertsHasAny
          && (
            <ImportIssuesBanner
              count={projectStringsAlertsCount}
              projectId={project.id}
            />
          )}

        {userRole === 'admin'
          && ['idle', 'checking'].includes(projectUnusedStringsCheckingStatus)
          && (
            <div className="d-flex flex-column my-4 justify-content-center align-items-center">
              <PageLoader
                message={i18n.t(`${i18nBase}.ProjectUnusedStrings.fetching`)}
              />
            </div>
          )}

        {userRole === 'admin'
          && projectUnusedStringsCheckingStatus === 'failed'
          && (
            <AlertMessage>
              {i18n.t(`${i18nBase}.ProjectUnusedStrings.fetchingFailed`)}
            </AlertMessage>
          )}

        {userRole === 'admin'
          && projectUnusedStringsCheckingStatus === 'succeeded'
          && projectUnusedStringsHasAny
          && (
            <UnusedStringsBanner
              count={projectUnusedStringsCount}
              projectId={project.id}
            />
          )}

        {(['idle', 'fetching'].includes(fetchingAllLanguagesStatus)) && (
          <div className="d-flex flex-column my-4 justify-content-center align-items-center">
            <PageLoader message={i18n.t(`${i18nBase}.fetchingLanguages`)} />
          </div>
        )}

        <ProjectExportBanner project={project} />

        {fetchingAllLanguagesStatus === 'succeeded' && languages === null && (
          <div className="d-flex flex-column justify-content-center align-items-center">
            <p>{i18n.t(`${i18nBase}.noLanguagesYet`)}</p>
          </div>
        )}

        {fetchingAllLanguagesStatus === 'succeeded' && (
          <TranslationList
            languages={languages}
            locales={locales}
            onCreateNewLanguage={() => this.setShowProjectLanguageCreationModal(true)}
            projectId={project.id}
          />
        )}

        <ProjectExports projectId={project.id} />

        <ProjectLanguageCreationModal
          isOpen={showProjectLanguageCreationModal}
          projectId={project.id}
          toggle={() => this.setShowProjectLanguageCreationModal(false)}
        />
      </>
    )
  }
}

ProjectPage.propTypes = {
  dispatch: PropTypes.func.isRequired,
  fetchingError: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  fetchingAllLanguagesError: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  fetchingLocalesError: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  fetchingStatus: PropTypes.string,
  fetchingAllLanguagesStatus: PropTypes.string,
  fetchingLocalesStatus: PropTypes.string,
  languages: PropTypes.arrayOf(PropTypes.object),
  locales: PropTypes.arrayOf(PropTypes.object),
  match: PropTypes.shape({
    params: PropTypes.shape({
      slug: PropTypes.string
    })
  }),
  project: PropTypes.shape({
    id: PropTypes.string.isRequired
  }),
  projectStringsAlertsCheckingStatus: PropTypes.string,
  projectStringsAlertsCount: PropTypes.number,
  projectStringsAlertsHasAny: PropTypes.bool,
  projectUnusedStringsCheckingStatus: PropTypes.string,
  projectUnusedStringsCount: PropTypes.number,
  projectUnusedStringsHasAny: PropTypes.bool,
  userRole: PropTypes.string.isRequired
}

ProjectPage.defaultProps = {
  fetchingAllLanguagesError: null,
  fetchingAllLanguagesStatus: 'idle',
  fetchingError: null,
  fetchingLocalesError: null,
  fetchingLocalesStatus: 'idle',
  fetchingStatus: 'idle',
  match: {
    params: {
      slug: null
    }
  },
  languages: null,
  locales: null,
  project: null,
  projectStringsAlertsCheckingStatus: 'idle',
  projectStringsAlertsCount: null,
  projectStringsAlertsHasAny: false,
  projectUnusedStringsCheckingStatus: 'idle',
  projectUnusedStringsCount: null,
  projectUnusedStringsHasAny: false
}

const mapStateToProps = ({
  authentication: {
    role: userRole
  },
  locales: {
    items: locales,
    fetchingError: fetchingLocalesError,
    fetchingStatus: fetchingLocalesStatus
  },
  projectLanguages: {
    fetchingAllError: fetchingAllLanguagesError,
    fetchingAllStatus: fetchingAllLanguagesStatus,
    items: languages
  },
  projectStringsAlerts: {
    checkingStatus: projectStringsAlertsCheckingStatus,
    count: projectStringsAlertsCount,
    hasAny: projectStringsAlertsHasAny
  },
  projectUnusedStrings: {
    checkingStatus: projectUnusedStringsCheckingStatus,
    count: projectUnusedStringsCount,
    hasAny: projectUnusedStringsHasAny
  },
  projects: {
    fetchingError,
    fetchingStatus,
    item: project
  }
}) => ({
  fetchingError,
  fetchingAllLanguagesError,
  fetchingAllLanguagesStatus,
  fetchingLocalesError,
  fetchingLocalesStatus,
  fetchingStatus,
  languages,
  locales,
  project,
  projectStringsAlertsCheckingStatus,
  projectStringsAlertsCount,
  projectStringsAlertsHasAny,
  projectUnusedStringsCheckingStatus,
  projectUnusedStringsCount,
  projectUnusedStringsHasAny,
  userRole
})

export default connect(mapStateToProps)(ProjectPage)
