import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import actionCableActions from '../actions'

class IntegrationsChannel extends React.Component {
  constructor(props) {
    super(props)

    this.CHANNEL_NAME = 'IntegrationsChannel'
  }

  componentDidMount() {
    const { consumer } = this.props

    this.channel = consumer.subscriptions.create({
      channel: this.CHANNEL_NAME
    }, {
      connected: () => this.onConnected(),
      disconnected: () => this.onDisconnected(),
      received: (data) => this.onDataReceived(data)
    })
  }

  componentWillUnmount() {
    if (this.channel) this.channel.unsubscribe()
  }

  onConnected() {
    const { dispatch } = this.props

    dispatch(actionCableActions.connectedTo(this.CHANNEL_NAME))
  }

  onDataReceived(data) {
    const { dispatch } = this.props

    dispatch(actionCableActions.dataReceivedFrom(this.CHANNEL_NAME, data))
  }

  onDisconnected() {
    const { dispatch } = this.props

    dispatch(actionCableActions.disconnectedFrom(this.CHANNEL_NAME))
  }

  render() {
    return null
  }
}

IntegrationsChannel.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  consumer: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired
}

export default connect()(IntegrationsChannel)
