import React from 'react'
import { connect } from 'react-redux'
import { Button, Container, Table } from 'reactstrap'
import PropTypes from 'prop-types'
import { v1 as uuidv1 } from 'uuid'

import AlertMessage from '../../components/AlertMessage'
import i18n from '../../config/i18n'
import localesActions from '../../localez/actions'
import PageLoader from '../../components/PageLoader'
import LocaleDeleteConfirmModal from './LocaleDeleteConfirmModal'
import LocaleModal from './LocaleModal'

class LocalesPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      deleteLocaleId: null,
      editLocaleId: null,
      showDeleteLocalConfirmationModal: false,
      showLocaleModal: false
    }
  }

  componentDidMount() {
    const { dispatch } = this.props

    dispatch(localesActions.fetchAll())
  }

  hideDeleteLocalConfirmationModal() {
    this.setState({
      deleteLocaleId: null,
      showDeleteLocalConfirmationModal: false
    })
  }

  hideLocaleModal() {
    this.setState({
      editLocaleId: null,
      showLocaleModal: false
    })
  }

  showDeleteLocalConfirmationModalFor(id) {
    this.setState({
      deleteLocaleId: id,
      showDeleteLocalConfirmationModal: true
    })
  }

  showLocaleModal(id = null) {
    this.setState({
      editLocaleId: id,
      showLocaleModal: true
    })
  }

  render() {
    const {
      fetchingLocalesError,
      fetchingLocalesStatus,
      localeList,
      role
    } = this.props

    const {
      deleteLocaleId,
      editLocaleId,
      showDeleteLocalConfirmationModal,
      showLocaleModal
    } = this.state

    if (fetchingLocalesStatus === 'fetching' || localeList === null) {
      return (
        <div className="d-flex flex-column flex-fill justify-content-center align-items-center">
          <PageLoader message="fetching locales" />
        </div>
      )
    }

    if (fetchingLocalesStatus === 'failed') {
      return (
        <div className="d-flex flex-column">
          <AlertMessage>
            {fetchingLocalesError}
          </AlertMessage>
        </div>
      )
    }

    return (
      <Container className="mt-3 d-flex flex-column flex-fill">
        <div className="d-flex flex-row justify-content-between">
          <h2>{i18n.t('LocalesPage.title')}</h2>
          {role === 'admin' && (
            <div className="d-flex flex-column justify-content-center">
              <Button
                className="mt-3 mb-3 float-right"
                color="primary"
                onClick={() => this.showLocaleModal()}
              >
                {i18n.t('buttons.create')}
              </Button>
            </div>
          )}
        </div>
        <Table>
          <thead>
            <tr>
              <th>English Name</th>
              <th className="w-50">Code</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {localeList.sort((a, b) => (a.name > b.name ? 1 : -1)).map((locale) => (
              <tr key={uuidv1()}>
                <th scope="row">{locale.name}</th>
                <td>{locale.value}</td>
                <td>
                  <div className="d-flex flex-row">
                    <Button
                      className="mr-3"
                      color="primary"
                      onClick={() => this.showLocaleModal(locale.id)}
                      outline
                    >
                      {i18n.t('buttons.edit')}
                    </Button>

                    <Button
                      color="danger"
                      onClick={() => this.showDeleteLocalConfirmationModalFor(locale.id)}
                      outline
                    >
                      {i18n.t('buttons.remove')}
                    </Button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>

        <LocaleModal
          id={editLocaleId}
          isOpen={showLocaleModal}
          toggle={() => this.hideLocaleModal()}
        />

        <LocaleDeleteConfirmModal
          id={deleteLocaleId}
          isOpen={showDeleteLocalConfirmationModal}
          onClose={() => this.hideDeleteLocalConfirmationModal()}
        />
      </Container>
    )
  }
}

LocalesPage.propTypes = {
  dispatch: PropTypes.func.isRequired,
  fetchingLocalesError: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  fetchingLocalesStatus: PropTypes.string,
  localeList: PropTypes.arrayOf(PropTypes.object),
  role: PropTypes.string.isRequired
}

LocalesPage.defaultProps = {
  fetchingLocalesError: null,
  fetchingLocalesStatus: 'idle',
  localeList: null
}

const mapStateToProps = ({
  authentication: {
    role
  },
  locales: {
    items: localeList,
    fetchingError: fetchingLocalesError,
    fetchingStatus: fetchingLocalesStatus
  }
}) => ({
  fetchingLocalesError,
  fetchingLocalesStatus,
  localeList,
  role
})

export default connect(mapStateToProps)(LocalesPage)
