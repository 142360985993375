import React from 'react'
import { connect } from 'react-redux'
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown
} from 'reactstrap'
import PropTypes from 'prop-types'
import UserAvatar from 'react-user-avatar'

import history from '../../helpers/History'
import i18n from '../../config/i18n'

const UserDropdownMenu = ({ email }) => (
  <>
    {email === null ? (
      <div>...</div>
    ) : (
      <UncontrolledDropdown nav inNavbar>
        <DropdownToggle
          caret
          className="position-relative p-0"
          id="user-menu"
          nav
        >
          <UserAvatar
            className="mr-3"
            name={email}
            size="39"
          />
        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem
            className="text-danger"
            name="logout_entry"
            onClick={() => history.push('/logout')}
          >
            {i18n.t('ApplicationLayout.AppNavbar.UserDropdownMenu.options.logout')}
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    )}
  </>
)

UserDropdownMenu.propTypes = {
  email: PropTypes.string
}

UserDropdownMenu.defaultProps = {
  email: null
}

const mapStateToProps = ({
  authentication: {
    email
  }
}) => ({
  email
})

export default connect(mapStateToProps)(UserDropdownMenu)
