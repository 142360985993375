import React from 'react'
import { Button, Container, Table } from 'reactstrap'
import PropTypes from 'prop-types'
import { v1 as uuidv1 } from 'uuid'

import i18n from '../../../config/i18n'
import LanguageRow from './LanguageRow'
import TableHeaderLanguage from './TableHeaderLanguage'

const TranslationList = ({
  languages,
  locales,
  onCreateNewLanguage,
  projectId
}) => {
  const i18nBase = 'ProjectsPage.ProjectPage.TranslationList'

  return (
    <Container fluid>
      <div className="d-flex flex-row align-items-center justify-content-between">
        <h3>{i18n.t(`${i18nBase}.title`)}</h3>
        <Button
          className="mt-3 mb-3"
          color="primary"
          onClick={() => onCreateNewLanguage()}
          outline
        >
          {i18n.t(`${i18nBase}.buttons.addNewLanguage`)}
        </Button>
      </div>

      <Table>
        <thead>
          <TableHeaderLanguage />
        </thead>
        <tbody>
          {languages.sort((a, b) => (a.locale > b.locale ? 1 : -1)).map((language) => (
            <LanguageRow
              language={language}
              key={uuidv1()}
              locales={locales}
              projectId={projectId}
            />
          ))}
        </tbody>
      </Table>
    </Container>
  )
}

TranslationList.propTypes = {
  languages: PropTypes.arrayOf(PropTypes.shape({
    locale: PropTypes.string.isRequired
  })).isRequired,
  locales: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onCreateNewLanguage: PropTypes.func.isRequired,
  projectId: PropTypes.string.isRequired
}

export default TranslationList
