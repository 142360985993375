import ApiUtils from '../../helpers/ApiUtils'

const confirm = (token) => (
  fetch(`/api/users/confirmation?confirmation_token=${token}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  }).then(ApiUtils.handleResponse)
)

const resendConfirmation = (user) => (
  fetch('/api/users/confirmation', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ user })
  }).then(ApiUtils.handleResponse)
)

export default {
  confirm,
  resendConfirmation
}
