export default {
  CHANGE_PASSWORD_REQUEST: 'DEVISE_CHANGE_PASSWORD_REQUEST',
  CHANGE_PASSWORD_SUCCESS: 'DEVISE_CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_FAILURE: 'DEVISE_CHANGE_PASSWORD_FAILURE',

  CONFIRMATION_REQUEST: 'DEVISE_CONFIRMATION_REQUEST',
  CONFIRMATION_SUCCESS: 'DEVISE_CONFIRMATION_SUCCESS',
  CONFIRMATION_FAILURE: 'DEVISE_CONFIRMATION_FAILURE',

  CONFIRMATION_RESEND_REQUEST: 'DEVISE_CONFIRMATION_RESEND_REQUEST',
  CONFIRMATION_RESEND_SUCCESS: 'DEVISE_CONFIRMATION_RESEND_SUCCESS',
  CONFIRMATION_RESEND_FAILURE: 'DEVISE_CONFIRMATION_RESEND_FAILURE',

  DESTROY_REQUEST: 'DEVISE_DESTROY_REQUEST',
  DESTROY_SUCCESS: 'DEVISE_DESTROY_SUCCESS',
  DESTROY_FAILURE: 'DEVISE_DESTROY_FAILURE',

  INVITATION_ACCEPT_REQUEST: 'DEVISE_INVITATION_ACCEPT_REQUEST',
  INVITATION_ACCEPT_SUCCESS: 'DEVISE_INVITATION_ACCEPT_SUCCESS',
  INVITATION_ACCEPT_FAILURE: 'DEVISE_INVITATION_ACCEPT_FAILURE',

  LOGIN_REQUEST: 'DEVISE_LOGIN_REQUEST',
  LOGIN_SUCCESS: 'DEVISE_LOGIN_SUCCESS',
  LOGIN_FAILURE: 'DEVISE_LOGIN_FAILURE',

  LOGOUT_REQUEST: 'DEVISE_LOGOUT_REQUEST',
  LOGOUT_FAILURE: 'DEVISE_LOGOUT_FAILURE',
  LOGOUT_SUCCESS: 'DEVISE_LOGOUT_SUCCESS',

  PASSWORD_RESET_REQUEST: 'DEVISE_PASSWORD_RESET_REQUEST',
  PASSWORD_RESET_SUCCESS: 'DEVISE_PASSWORD_RESET_SUCCESS',
  PASSWORD_RESET_FAILURE: 'DEVISE_PASSWORD_RESET_FAILURE',

  RESET_CONFIRMATION: 'DEVISE_RESET_CONFIRMATION',
  RESET_LOGGING: 'DEVISE_RESET_LOGGING',
  RESET_NEW_PASSWORD: 'DEVISE_RESET_NEW_PASSWORD',
  RESET_REGISTER: 'DEVISE_RESET_REGISTER',

  REGISTER_REQUEST: 'DEVISE_REGISTER_REQUEST',
  REGISTER_SUCCESS: 'DEVISE_REGISTER_SUCCESS',
  REGISTER_FAILURE: 'DEVISE_REGISTER_FAILURE'

  // Unlock doesn't work becaue of NoBrainer I guess
  // UNLOCK_RESEND_REQUEST: 'DEVISE_UNLOCK_RESEND_REQUEST',
  // UNLOCK_RESEND_SUCCESS: 'DEVISE_UNLOCK_RESEND_SUCCESS',
  // UNLOCK_RESEND_FAILURE: 'DEVISE_UNLOCK_RESEND_FAILURE'
}
