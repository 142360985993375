import ApiUtils from '../../helpers/ApiUtils'
import ProjectStringsApi from '../../apis/ProjectStringsApi'

const fetchAll = ({
  projectId,
  language,
  emptyOnly,
  filter,
  page,
  sort
}) => (
  new ProjectStringsApi(projectId, language).get({
    query: {
      empty_only: emptyOnly,
      filter,
      page,
      sort
    }
  }).then(ApiUtils.handleResponse)
)

const update = (hash, language, projectId) => (
  new ProjectStringsApi(projectId, language).update('all', { hash })
    .then(ApiUtils.handleResponse)
)

export default {
  fetchAll,
  update
}
