import React from 'react'
import { Alert, Container } from 'reactstrap'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Trans } from 'react-i18next'

import i18n from '../../../config/i18n'

const UnusedStringsBanner = ({ count, projectId }) => (
  <Container fluid>
    <Alert className="d-flex flex-row flex-fill justify-content-between align-items-center" color="warning">
      <div className="d-block">
        <Trans i18nKey="ProjectsPage.ProjectUnusedStrings.alert.message" count={count}>
          This project has
          {{ count }}
          strings which are no more existing in the project&apos;s
          reference languages files.
        </Trans>
      </div>
      <Link
        className="btn btn-outline-warning text-warning text-decoration-none px-5"
        to={`/projects/${projectId}/unused-strings`}
      >
        {i18n.t('ProjectsPage.ProjectUnusedStrings.alert.button')}
      </Link>
    </Alert>
  </Container>
)

UnusedStringsBanner.propTypes = {
  count: PropTypes.number.isRequired,
  projectId: PropTypes.string.isRequired
}

export default UnusedStringsBanner
