import React from 'react'
import { connect } from 'react-redux'
import {
  Button,
  FormGroup
} from 'reactstrap'
import PropTypes from 'prop-types'

import AlertMessage from '../../components/AlertMessage'
import confirmationActions from './actions'
import DeviseLayout from '../DeviseLayout'
import EmailField from '../../components/EmailField'
import i18n from '../../config/i18n'
import PageLoader from '../../components/PageLoader'

class DeviseConfirmationsNew extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      email: ''
    }

    const { dispatch } = this.props
    dispatch(confirmationActions.reset())
  }

  handleEmailChange({ target: { value } }) {
    this.setState({
      email: value
    })
  }

  handleSubmit(event) {
    const { email } = this.state
    const { dispatch } = this.props

    event.preventDefault()

    if (email === '') {
      this.emailField.showAsInvalid()
    } else {
      dispatch(confirmationActions.resendConfirmation({ email }))
    }
  }

  render() {
    const {
      resendingError,
      resendingStatus
    } = this.props

    return (
      <DeviseLayout
        onSubmit={(event) => this.handleSubmit(event)}
        title={i18n.t('devise.DeviseConfirmationsNew.title')}
      >
        <EmailField
          inputId="email"
          inputName="email"
          onChange={(event) => this.handleEmailChange(event)}
          ref={(element) => { this.emailField = element }}
        />
        <FormGroup>
          <div className="d-flex flex-column">
            {resendingStatus === 'resending' && (
              <PageLoader
                message={i18n.t('devise.DeviseConfirmationsNew.resending')}
              />
            )}
            {resendingStatus === 'failed' && (
              <AlertMessage>
                {resendingError || i18n.t('errors.somethingWentWrong')}
              </AlertMessage>
            )}
            {resendingStatus === 'succeeded' && (
              <AlertMessage color="success">
                {resendingError
                  || i18n.t('devise.DeviseConfirmationsNew.confirmation_resend_suceeded')}
              </AlertMessage>
            )}
            <Button
              className="mb-3"
              color="primary"
            >
              {i18n.t('buttons.resend_confirmation_email')}
            </Button>
          </div>
        </FormGroup>
      </DeviseLayout>
    )
  }
}

DeviseConfirmationsNew.propTypes = {
  resendingError: PropTypes.string,
  resendingStatus: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired
}

DeviseConfirmationsNew.defaultProps = {
  resendingError: null
}

const mapStateToProps = ({
  resendConfirmation: {
    resendingError,
    resendingStatus
  }
}) => ({
  resendingError,
  resendingStatus
})

export default connect(mapStateToProps)(DeviseConfirmationsNew)
