import dotProp from 'dot-prop-immutable'

import deviseConstants from '../constants'

const confirmationInitialState = {
  confirmingError: null,
  confirmingStatus: 'idle'
}

export const confirmation = (state = confirmationInitialState, action) => {
  switch (action.type) {
    case deviseConstants.CONFIRMATION_REQUEST:
      return dotProp.set(state, 'confirmingStatus', 'confirming')
    case deviseConstants.CONFIRMATION_SUCCESS:
      return dotProp.set(state, 'confirmingStatus', 'succeeded')
    case deviseConstants.CONFIRMATION_FAILURE: {
      const newState = dotProp.set(
        state,
        'confirmingError',
        action.error
      )
      return dotProp.set(newState, 'confirmingStatus', 'failed')
    }
    default:
      return state
  }
}

const resendInitialState = {
  resendingError: null,
  resendingStatus: 'idle'
}

export const resendConfirmation = (state = resendInitialState, action) => {
  switch (action.type) {
    case deviseConstants.CONFIRMATION_RESEND_REQUEST:
      return dotProp.set(state, 'resendingStatus', 'resending')
    case deviseConstants.CONFIRMATION_RESEND_SUCCESS:
      return dotProp.set(state, 'resendingStatus', 'succeeded')
    case deviseConstants.CONFIRMATION_RESEND_FAILURE: {
      const newState = dotProp.set(
        state,
        'resendingError',
        action.error
      )
      return dotProp.set(newState, 'resendingStatus', 'failed')
    }
    case deviseConstants.RESET_CONFIRMATION:
      return resendInitialState
    default:
      return state
  }
}
