import actionCableConstants from './constants'

const connectedTo = (channelName) => (dispatch) => dispatch({
  channelName,
  type: actionCableConstants.CONNECTED_TO_CHANNEL
})

const dataReceivedFrom = (channelName, data, id = null) => (
  dispatch
) => dispatch({
  channelName,
  data,
  id,
  type: actionCableConstants.DATA_RECEIVED_FROM_CHANNEL
})

const disconnectedFrom = (channelName) => (dispatch) => dispatch({
  channelName,
  type: actionCableConstants.DISCONNECTED_FROM_CHANNEL
})

export default {
  connectedTo,
  dataReceivedFrom,
  disconnectedFrom
}
