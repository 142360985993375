import history from './History'

const cookies = () => (
  document.cookie.split(';').reduce((result, current) => {
    const [name, ...value] = current.split('=')
    return { ...result, [name]: value.join('=') }
  }, {})
)

const newError = (message, response, otherData = null) => {
  const error = new Error(message)
  error.response = response
  if (otherData) error.data = otherData
  return error
}

const parseJSON = (response) => (
  response.text().then((text) => (text && text !== '' ? JSON.parse(text) : {}))
)

const updateCsrfTokenFromCookies = () => {
  const csrfCookie = cookies()['CSRF-TOKEN']

  if (csrfCookie) {
    document.querySelector('[name="csrf-token"]').content = decodeURIComponent(
      csrfCookie
    )
  }
}

const handleResponse = (response) => {
  updateCsrfTokenFromCookies()

  if (response.ok) return parseJSON(response)

  if (response.status === 401) {
    if (response.url.endsWith('/api/users/sign_in')) {
      // In the case of the login page, we don't need to redirect the user,
      // a flash message will be shown about the login error.
    } else {
      history.push('/logout')
    }
  }

  if (response.status === 404) throw newError(response.statusText, response)

  return response.json().then((json) => {
    if (Object.keys(json).includes('error')) {
      throw newError(json.error.message || json.error, response)
    }

    if (Object.keys(json).includes('errors')) {
      throw newError(null, response, json.errors)
    }

    const firstKey = Object.keys(json)[0]
    if (typeof json[Object.keys(json)[0]] === 'object') {
      throw newError(`${firstKey} ${json[firstKey][0]}`, response)
    }

    throw newError(`${firstKey} ${json[firstKey]}`, response)
  })
}

export default {
  handleResponse
}
