import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import AlertMessage from '../../components/AlertMessage'
import i18n from '../../config/i18n'
import PageLoader from '../../components/PageLoader'
import NoConfiguredIntegration from './NoConfiguredIntegration'
import ProjectDeck from './ProjectDeck'
import integrationsActions from '../../integrations/actions'
import ReleaseNoteAnnouncer from '../../components/ReleaseNoteAnnouncer'

const ProjectsPage = ({
  dispatch,
  fetchingError,
  fetchingStatus,
  integrations,
  loggingStatus
}) => {
  if (fetchingStatus === 'idle') dispatch(integrationsActions.fetchAll())
  if (fetchingStatus === 'failed' && loggingStatus === 'succeeded') {
    dispatch(integrationsActions.fetchAll())
  }

  if (integrations === null) {
    return (
      <div className="d-flex flex-column flex-fill justify-content-center align-items-center">
        <PageLoader message={i18n.t('ProjectsPage.fetchingIntegration')} />
      </div>
    )
  }

  if (fetchingError) {
    return (
      <div className="d-flex flex-column flex-fill justify-content-center">
        <AlertMessage>
          {i18n.t('errors.somethingWentWrong')}
        </AlertMessage>
      </div>
    )
  }

  return (
    <div className="d-flex flex-column p-3" id="projects">
      <h1 className="h3 mb-4">{i18n.t('ProjectsPage.title.projects')}</h1>

      {integrations.length ? (
        <ProjectDeck />
      ) : (
        <NoConfiguredIntegration />
      )}

      <ReleaseNoteAnnouncer />
    </div>
  )
}

ProjectsPage.propTypes = {
  dispatch: PropTypes.func.isRequired,
  fetchingError: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  fetchingStatus: PropTypes.string,
  integrations: PropTypes.arrayOf(PropTypes.object),
  loggingStatus: PropTypes.string
}

ProjectsPage.defaultProps = {
  fetchingError: null,
  fetchingStatus: 'idle',
  integrations: null,
  loggingStatus: 'idle'
}

const mapStateToProps = ({
  authentication: {
    loggingStatus
  },
  integrations: {
    fetchingError,
    fetchingStatus,
    items: integrations
  }
}) => ({
  fetchingError,
  fetchingStatus,
  integrations,
  loggingStatus
})

export default connect(mapStateToProps)(ProjectsPage)
