import React, { useState } from 'react'
import { Button, Container } from 'reactstrap'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import AlertMessage from '../../../components/AlertMessage'
import history from '../../../helpers/History'
import i18n from '../../../config/i18n'
import localesActions from '../../../localez/actions'
import PageHeader from '../../../components/PageHeader'
import PageLoader from '../../../components/PageLoader'
import ProjectExportCreationConfirmationModal from './ProjectExportCreationConfirmationModal'
import projectExportsActions from './actions'
import TranslationsUpdatesDiffTable from './TranslationsUpdatesDiffTable'
import WellDone from '../../../components/WellDone'

const NewProjectExportPage = ({
  diff,
  diffCount,
  diffCurrentPage,
  diffFetchingError,
  diffFetchingStatus,
  diffTotalPages,
  dispatch,
  localesFetchingStatus,
  match: { params: { slug } },
  projectFetchingError,
  projectFetchingStatus
}) => {
  const [freshDiff, setFreshDiff] = useState(false)
  const [page, setPage] = useState(null)
  const [creationConfirmationModalIsOpen, setCreationConfirmationModalIsOpen] = useState(null)

  const i18nBase = 'ProjectsPage.ProjectExports.New'

  if (freshDiff === false) {
    setFreshDiff(true)
    dispatch(projectExportsActions.fetchDiff(slug, page))
  }

  if (localesFetchingStatus === 'idle'
      && diffFetchingStatus === 'succeeded'
      && diff.length > 0
  ) {
    dispatch(localesActions.fetchAll())
  }

  const moveToPage = (selected) => {
    setPage(selected)
    dispatch(projectExportsActions.fetchDiff(slug, selected))
  }

  const showCreationConfirmationModal = () => (
    setCreationConfirmationModalIsOpen(true)
  )

  return (
    <Container fluid className="d-flex flex-column flex-fill mt-3">
      <div className="d-flex flex-column">
        <div className="d-flex flex-row justify-content-between">
          <div className="d-flex flex-column">
            <PageHeader
              projectSlug={slug}
              title={i18n.t(`${i18nBase}.title`, { count: diffCount })}
              withoutHr
            />
          </div>

          <Button
            className="px-4 py-3"
            color="success"
            disabled={diffFetchingStatus !== 'succeeded' || diff.length === 0}
            onClick={() => showCreationConfirmationModal()}
            outline
          >
            {i18n.t(`${i18nBase}.buttons.export`)}
          </Button>
        </div>

        <hr className="w-100" />
      </div>

      {['idle', 'fetching'].includes(projectFetchingStatus) && (
        <PageLoader message={i18n.t('ProjectsPage.fetching')} />
      )}

      {projectFetchingStatus === 'failed' && (
        <AlertMessage>
          {projectFetchingError || i18n.t('errors.somethingWentWrong')}
        </AlertMessage>
      )}

      {projectFetchingStatus === 'succeeded'
        && localesFetchingStatus === 'succeeded'
        && (
          <>
            {diffFetchingStatus === 'failed' && (
              <AlertMessage>
                {diffFetchingError || i18n.t('errors.somethingWentWrong')}
              </AlertMessage>
            )}

            {diffFetchingStatus === 'succeeded'
              && diff.length === 0
              && (
                <WellDone projectId={slug} />
              )}

            {diffFetchingStatus === 'succeeded'
              && diff.length > 0
              && (
                <TranslationsUpdatesDiffTable
                  diff={diff}
                  moveToPage={(selected) => moveToPage(selected)}
                  paginationCurrentPage={diffCurrentPage}
                  paginationTotalPages={diffTotalPages}
                />
              )}
          </>
        )}

      <ProjectExportCreationConfirmationModal
        isOpen={creationConfirmationModalIsOpen}
        onClose={(createdProjectExportId) => {
          setCreationConfirmationModalIsOpen(false)
          history.push(`/projects/${slug}/exports/${createdProjectExportId}`)
        }}
        projectId={slug}
      />
    </Container>
  )
}

NewProjectExportPage.propTypes = {
  diff: PropTypes.arrayOf(PropTypes.shape()),
  diffCount: PropTypes.number,
  diffCurrentPage: PropTypes.number,
  diffFetchingError: PropTypes.string,
  diffFetchingStatus: PropTypes.string,
  diffTotalPages: PropTypes.number,
  dispatch: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      slug: PropTypes.string
    })
  }),
  localesFetchingStatus: PropTypes.string,
  projectFetchingError: PropTypes.string,
  projectFetchingStatus: PropTypes.string
}

NewProjectExportPage.defaultProps = {
  diff: null,
  diffCount: null,
  diffCurrentPage: null,
  diffFetchingError: null,
  diffFetchingStatus: null,
  diffTotalPages: null,
  match: {
    params: {
      slug: null
    }
  },
  localesFetchingStatus: 'idle',
  projectFetchingError: null,
  projectFetchingStatus: 'idle'
}

const mapStateToProps = ({
  locales: {
    fetchingStatus: localesFetchingStatus
  },
  projects: {
    fetchingStatus: projectFetchingStatus
  },
  projectExports: {
    diffFetchingError,
    diffFetchingStatus,
    diffItems: diff,
    itemsCurrentPage: diffCurrentPage,
    itemsCount: diffCount,
    itemsTotalPages: diffTotalPages
  }
}) => ({
  diff,
  diffCount,
  diffCurrentPage,
  diffFetchingError,
  diffFetchingStatus,
  diffTotalPages,
  localesFetchingStatus,
  projectFetchingStatus
})

export default connect(mapStateToProps)(NewProjectExportPage)
