import React from 'react'
import { Container } from 'reactstrap'

import i18n from '../../config/i18n'
import Integrations from './Integrations'

const SettingsPage = () => (
  <Container className="mt-3 d-flex flex-column flex-fill">
    <div className="d-flex flex-row justify-content-between">
      <h2>{i18n.t('SettingsPage.title')}</h2>
    </div>

    <div className="d-flex flex-column">
      <Integrations />
    </div>
  </Container>
)

export default SettingsPage
