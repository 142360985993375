import React from 'react'
import { connect } from 'react-redux'
import Icofont from 'react-icofont'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

import i18n from '../config/i18n'

const WellDone = ({ project: { id }, projectId }) => (
  <div className="d-flex flex-column flex-fill justify-content-center align-items-center text-muted">
    <Icofont
      className="mb-5"
      icon="thumbs-up"
      size="9"
    />

    <p className="lead">
      {i18n.t('WellDone.text')}
    </p>

    <Link
      className="btn btn-outline-secondary"
      to={`/projects/${id || projectId}`}
    >
      {i18n.t('ProjectsPage.links.back')}
    </Link>
  </div>
)

WellDone.propTypes = {
  project: PropTypes.shape({
    id: PropTypes.string.isRequired
  }),
  projectId: PropTypes.string
}

WellDone.defaultProps = {
  project: {
    id: null
  },
  projectId: null
}

const mapStateToProps = ({
  projects: {
    item: project
  }
}) => ({
  project
})

export default connect(mapStateToProps)(WellDone)
