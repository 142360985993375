import RestfulClient from 'restful-json-api-client'

export default class ProjectExportsApi extends RestfulClient {
  constructor(projectId) {
    super(`/api/projects/${projectId}`, { resource: 'project_exports' })
  }

  // body contains { page: 1 } and is used for the pagination
  diff(body) {
    return this.request('GET', { path: 'diff', body })
  }

  getDiff(id, body) {
    return this.request('GET', { path: `${id}/diff`, body })
  }

  // body contains { page: 1 } and is used for the pagination
  new(body) {
    return this.request('GET', { path: 'new', body })
  }

  retry(id) {
    return this.request('POST', { path: `${id}/retry` })
  }
}
