export default {
  FETCH_ALL_REQUEST: 'TRANSLATIONS_PAGE_FETCH_ALL_REQUEST',
  FETCH_ALL_SUCCESS: 'TRANSLATIONS_PAGE_FETCH_ALL_SUCCESS',
  FETCH_ALL_FAILURE: 'TRANSLATIONS_PAGE_FETCH_ALL_FAILURE',

  FILTER_REQUEST: 'TRANSLATIONS_PAGE_FILTER_REQUEST',
  FILTER_SUCCESS: 'TRANSLATIONS_PAGE_FILTER_SUCCESS',
  FILTER_FAILURE: 'TRANSLATIONS_PAGE_FILTER_FAILURE',

  UPDATE_REQUEST: 'TRANSLATIONS_PAGE_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'TRANSLATIONS_PAGE_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'TRANSLATIONS_PAGE_UPDATE_FAILURE'
}
