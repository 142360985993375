import dotProp from 'dot-prop-immutable'

import rolesConstants from './constants'

const initialState = {
  fetchingError: null,
  fetchingStatus: 'idle',

  roles: null
}

export default (state = initialState, action) => {
  const error = action.error ? (action.error.data || action.error.message) : null
  let newState

  switch (action.type) {
    case rolesConstants.FETCH_ALL_REQUEST:
      newState = dotProp.set(state, 'fetchingError', null)
      return dotProp.set(newState, 'fetchingStatus', 'fetching')
    case rolesConstants.FETCH_ALL_FAILURE:
      newState = dotProp.set(state, 'fetchingError', error)
      newState = dotProp.set(newState, 'fetchingStatus', 'failed')
      return dotProp.set(newState, 'roles', [])
    case rolesConstants.FETCH_ALL_SUCCESS:
      newState = dotProp.set(state, 'fetchingError', null)
      newState = dotProp.set(newState, 'fetchingStatus', 'succeeded')
      return dotProp.set(newState, 'roles', action.roles)

    default:
      return state
  }
}
