import ApiUtils from '../../../../helpers/ApiUtils'
import ProjectLanguagesApi from '../../../../apis/ProjectLanguagesApi'

const create = (projectId, language) => (
  new ProjectLanguagesApi(projectId).create(language)
    .then(ApiUtils.handleResponse)
)

const fetchAll = (projectId) => (
  new ProjectLanguagesApi(projectId).all().then(ApiUtils.handleResponse)
)

export default {
  create,
  fetchAll
}
