import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Table } from 'reactstrap'
import { v1 as uuidv1 } from 'uuid'

import AlertMessage from '../../components/AlertMessage'
import i18n from '../../config/i18n'
import PageLoader from '../../components/PageLoader'
import usersActions from './actions'
import UserRow from './UserRow'

class UsersTable extends React.Component {
  componentDidMount() {
    const { dispatch } = this.props

    dispatch(usersActions.fetchAll())
  }

  render() {
    const {
      currentUserEmail,
      currentUserRole,
      fetchingStatus,
      onUserEdit,
      onUserRemove,
      users
    } = this.props

    const i18nBase = 'UsersPage.UsersTable'

    if (fetchingStatus === 'fetching' || users === null) {
      return (
        <div className={`d-flex flex-row flex-fill justify-content-center
                         align-items-center`}
        >
          <PageLoader message={i18n.t(`${i18nBase}.fetching`)} />
        </div>
      )
    }

    if (fetchingStatus === 'failed') {
      return (
        <div className={`d-flex flex-row flex-fill justify-content-center
                         align-items-center`}
        >
          <AlertMessage>
            {i18n.t(`${i18nBase}.errors.fetchUsersFailed`)}
          </AlertMessage>
        </div>
      )
    }

    return (
      <Table>
        <thead>
          <tr>
            <th>{i18n.t(`${i18nBase}.headers.email`)}</th>
            <th>{i18n.t(`${i18nBase}.headers.role`)}</th>
            <th>{i18n.t(`${i18nBase}.headers.memberSince`)}</th>
            <th aria-label="Actions" />
          </tr>
        </thead>
        <tbody>
          {users.map((user) => (
            <UserRow
              canEdit={currentUserRole === 'admin'}
              canRemove={users.length > 1 && (currentUserRole === 'admin' || user.email === currentUserEmail)}
              currentUserEmail={currentUserEmail}
              key={uuidv1()}
              onEdit={(id) => onUserEdit(id)}
              onRemove={(id) => onUserRemove(id)}
              user={user}
            />
          ))}
        </tbody>
      </Table>
    )
  }
}

UsersTable.propTypes = {
  currentUserEmail: PropTypes.string,
  currentUserRole: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  fetchingStatus: PropTypes.string,
  onUserEdit: PropTypes.func.isRequired,
  onUserRemove: PropTypes.func.isRequired,
  users: PropTypes.arrayOf(PropTypes.object)
}

UsersTable.defaultProps = {
  currentUserEmail: null,
  currentUserRole: null,
  fetchingStatus: 'idle',
  users: null
}

const mapStateToProps = ({
  authentication: {
    email: currentUserEmail,
    role: currentUserRole
  },
  users: {
    fetchingStatus,
    items: users
  }
}) => ({
  currentUserEmail,
  currentUserRole,
  fetchingStatus,
  users
})

export default connect(mapStateToProps)(UsersTable)
