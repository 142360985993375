export default {
  CHECK_IF_ANY_REQUEST: 'PROJECT_UNUSED_STRINGS_CHECK_IF_ANY_REQUEST',
  CHECK_IF_ANY_FAILURE: 'PROJECT_UNUSED_STRINGS_CHECK_IF_ANY_FAILURE',
  CHECK_IF_ANY_SUCCESS: 'PROJECT_UNUSED_STRINGS_CHECK_IF_ANY_SUCCESS',

  DESTROY_REQUEST: 'PROJECT_UNUSED_STRINGS_DESTROY_REQUEST',
  DESTROY_FAILURE: 'PROJECT_UNUSED_STRINGS_DESTROY_FAILURE',
  DESTROY_SUCCESS: 'PROJECT_UNUSED_STRINGS_DESTROY_SUCCESS',

  FETCH_ALL_REQUEST: 'PROJECT_UNUSED_STRINGS_FETCH_ALL_REQUEST',
  FETCH_ALL_FAILURE: 'PROJECT_UNUSED_STRINGS_FETCH_ALL_FAILURE',
  FETCH_ALL_SUCCESS: 'PROJECT_UNUSED_STRINGS_FETCH_ALL_SUCCESS'
}
