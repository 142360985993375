import deviseConstants from '../constants'
import confirmationService from './service'

const confirm = (token) => {
  const request = () => ({ type: deviseConstants.CONFIRMATION_REQUEST })
  const success = () => ({ type: deviseConstants.CONFIRMATION_SUCCESS })
  const failure = (error) => ({
    error,
    type: deviseConstants.CONFIRMATION_FAILURE
  })

  return (dispatch) => {
    dispatch(request())

    confirmationService.confirm(token)
      .then((user) => dispatch(success(user)))
      .catch((error) => dispatch(failure(error)))
  }
}

const resendConfirmation = (user) => {
  const request = () => ({ type: deviseConstants.CONFIRMATION_RESEND_REQUEST })
  const success = () => ({ type: deviseConstants.CONFIRMATION_RESEND_SUCCESS })
  const failure = (error) => ({
    error,
    type: deviseConstants.CONFIRMATION_RESEND_FAILURE
  })

  return (dispatch) => {
    dispatch(request())

    confirmationService.resendConfirmation(user)
      .then(() => {
        dispatch(success())
      })
      .catch((error) => dispatch(failure(error)))
  }
}

const reset = () => (dispatch) => {
  dispatch({ type: deviseConstants.RESET_CONFIRMATION })
}

export default {
  confirm,
  resendConfirmation,
  reset
}
