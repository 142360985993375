export default {
  CHECK_IF_ANY_REQUEST: 'PROJECT_EXPORTS_CHECK_IF_ANY_REQUEST',
  CHECK_IF_ANY_FAILURE: 'PROJECT_EXPORTS_CHECK_IF_ANY_FAILURE',
  CHECK_IF_ANY_SUCCESS: 'PROJECT_EXPORTS_CHECK_IF_ANY_SUCCESS',

  CREATE_REQUEST: 'PROJECT_EXPORTS_CREATE_REQUEST',
  CREATE_FAILURE: 'PROJECT_EXPORTS_CREATE_FAILURE',
  CREATE_SUCCESS: 'PROJECT_EXPORTS_CREATE_SUCCESS',

  FETCH_REQUEST: 'PROJECT_EXPORTS_FETCH_REQUEST',
  FETCH_FAILURE: 'PROJECT_EXPORTS_FETCH_FAILURE',
  FETCH_SUCCESS: 'PROJECT_EXPORTS_FETCH_SUCCESS',

  FETCH_ALL_REQUEST: 'PROJECT_EXPORTS_FETCH_ALL_REQUEST',
  FETCH_ALL_FAILURE: 'PROJECT_EXPORTS_FETCH_ALL_FAILURE',
  FETCH_ALL_SUCCESS: 'PROJECT_EXPORTS_FETCH_ALL_SUCCESS',

  FETCH_DIFF_REQUEST: 'PROJECT_EXPORTS_FETCH_DIFF_REQUEST',
  FETCH_DIFF_FAILURE: 'PROJECT_EXPORTS_FETCH_DIFF_FAILURE',
  FETCH_DIFF_SUCCESS: 'PROJECT_EXPORTS_FETCH_DIFF_SUCCESS',

  FETCH_EXPORT_DIFF_REQUEST: 'PROJECT_EXPORTS_FETCH_EXPORT_DIFF_REQUEST',
  FETCH_EXPORT_DIFF_FAILURE: 'PROJECT_EXPORTS_FETCH_EXPORT_DIFF_FAILURE',
  FETCH_EXPORT_DIFF_SUCCESS: 'PROJECT_EXPORTS_FETCH_EXPORT_DIFF_SUCCESS',

  RETRY_REQUEST: 'PROJECT_EXPORTS_RETRY_REQUEST',
  RETRY_FAILURE: 'PROJECT_EXPORTS_RETRY_FAILURE',
  RETRY_SUCCESS: 'PROJECT_EXPORTS_RETRY_SUCCESS'
}
