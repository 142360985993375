import React from 'react'
import { Form } from 'reactstrap'
import PropTypes from 'prop-types'

const DeviseLayout = ({
  children,
  id,
  onSubmit,
  title
}) => (
  <>
    <h2 className="text-center">{title}</h2>
    <div
      className={`d-flex flex-column align-self-center
                  w-100 w-md-75
                  p-4 p-md-0`}
    >
      {onSubmit === null ? (
        <div
          className="d-flex flex-column flex-fill"
          id={id}
        >
          {children}
        </div>
      ) : (
        <Form
          className="d-flex flex-column flex-fill"
          id={id}
          onSubmit={(event) => onSubmit(event)}
        >
          {children}
        </Form>
      )}
    </div>
  </>
)

DeviseLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.element
  ]).isRequired,
  id: PropTypes.string,
  onSubmit: PropTypes.func,
  title: PropTypes.string.isRequired
}

DeviseLayout.defaultProps = {
  id: null,
  onSubmit: null
}

export default DeviseLayout
