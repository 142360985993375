import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Table } from 'reactstrap'
import { v1 as uuidv1 } from 'uuid'

import i18n from '../../../config/i18n'
import TablePagination from '../../../components/TablePagination'

const TranslationsUpdatesDiffTable = ({
  diff,
  locales,
  moveToPage,
  paginationCurrentPage,
  paginationTotalPages,
  userRole
}) => {
  const i18nBase = 'ProjectsPage.ProjectExports.TranslationsUpdatesDiffTable'

  return (
    <>
      <Table borderless>
        <thead>
          <tr>
            <th className="w-50">{i18n.t(`${i18nBase}.columns.before`)}</th>
            <th className="w-50">{i18n.t(`${i18nBase}.columns.after`)}</th>
          </tr>
        </thead>
        {diff.map((item) => {
          const localeObject = (locales || []).find(
            (locale) => locale.value === item.locale
          )

          return (
            <tbody key={item.id || uuidv1()}>
              <tr>
                <td className="text-muted" colSpan="2">
                  <div className="d-flex flex-column">
                    <div className="d-flex flex-row">
                      {item.key}
                      {localeObject && (
                        <>
                          &nbsp;(
                          {localeObject.label}
                          )
                        </>
                      )}
                    </div>
                    {userRole === 'admin' && (
                      <div className="d-flex flex-row">
                        <small>
                          {item.path}
                        </small>
                      </div>
                    )}
                  </div>
                </td>
              </tr>
              <tr>
                <td className="text-muted" style={{ backgroundColor: 'rgb(250, 197, 205)' }}>
                  {item.old_value
                    ? item.old_value
                    : (<i>{i18n.t(`${i18nBase}.emptyValue`)}</i>)}
                </td>
                <td style={{ backgroundColor: 'rgb(199, 240, 210)' }}>
                  {item.value || i18n.t(`${i18nBase}.emptyValue`)}
                </td>
              </tr>
            </tbody>
          )
        })}
      </Table>

      <div className="d-flex flex-row justify-content-center">
        <TablePagination
          currentPage={paginationCurrentPage}
          onPaginationClick={(page) => moveToPage(page)}
          totalPages={paginationTotalPages}
        />
      </div>
    </>
  )
}

TranslationsUpdatesDiffTable.propTypes = {
  diff: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    locale: PropTypes.string.isRequired,
    old_value: PropTypes.string,
    path: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired
  })).isRequired,
  locales: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired
  })),
  moveToPage: PropTypes.func.isRequired,
  paginationCurrentPage: PropTypes.number,
  paginationTotalPages: PropTypes.number,
  userRole: PropTypes.string
}

TranslationsUpdatesDiffTable.defaultProps = {
  locales: null,
  paginationCurrentPage: 1,
  paginationTotalPages: 1,
  userRole: null
}

const mapStateToProps = ({
  authentication: {
    role: userRole
  },
  locales: {
    items: locales
  }
}) => ({
  locales,
  userRole
})

export default connect(mapStateToProps)(TranslationsUpdatesDiffTable)
