import integrationsConstants from './constants'
import integrationsService from './services'

const activate = (platform, token) => {
  const request = (platformName) => ({
    platform: platformName,
    type: integrationsConstants.ACTIVATE_INTEGRATION_REQUEST
  })
  const success = (integration) => ({
    integration,
    type: integrationsConstants.ACTIVATE_INTEGRATION_SUCCESS
  })
  const failure = (error) => ({
    error,
    type: integrationsConstants.ACTIVATE_INTEGRATION_FAILURE
  })

  return (dispatch) => {
    dispatch(request(platform))

    integrationsService.activate(platform, token)
      .then((integration) => dispatch(success(integration)))
      .catch((error) => dispatch(failure(error)))
  }
}

const checkPaths = (platform, repositoryId, paths) => {
  const request = () => ({
    platform,
    type: integrationsConstants.CHECK_PATHS_REQUEST
  })
  const success = () => ({
    type: integrationsConstants.CHECK_PATHS_SUCCESS
  })
  const failure = (error) => ({
    error,
    type: integrationsConstants.CHECK_PATHS_FAILURE
  })

  return (dispatch) => {
    dispatch(request(platform))

    integrationsService.checkPaths(platform, repositoryId, paths)
      .then(() => dispatch(success()))
      .catch((error) => dispatch(failure(error)))
  }
}

const fetchAll = () => {
  const request = () => ({ type: integrationsConstants.FETCH_ALL_REQUEST })
  const success = (integrations) => ({
    integrations,
    type: integrationsConstants.FETCH_ALL_SUCCESS
  })
  const failure = (error) => ({
    error,
    type: integrationsConstants.FETCH_ALL_FAILURE
  })

  return (dispatch) => {
    dispatch(request())

    integrationsService.fetchAll()
      .then((integrations) => dispatch(success(integrations)))
      .catch((error) => dispatch(failure(error)))
  }
}

const fetchBranches = (projectId, platform) => {
  const request = () => ({
    type: integrationsConstants.FETCH_BRANCHES_REQUEST
  })
  const success = (branches) => ({
    platform,
    branches,
    type: integrationsConstants.FETCH_BRANCHES_SUCCESS
  })
  const failure = (error) => ({
    error,
    type: integrationsConstants.FETCH_BRANCHES_FAILURE
  })

  return (dispatch) => {
    dispatch(request())

    integrationsService.fetchBranches(projectId, platform)
      .then((branches) => dispatch(success(branches)))
      .catch((error) => dispatch(failure(error)))
  }
}

const fetchRepositories = (platform) => {
  const request = () => ({
    type: integrationsConstants.FETCH_REPOSITORIES_REQUEST
  })
  const success = (repositories) => ({
    platform,
    repositories,
    type: integrationsConstants.FETCH_REPOSITORIES_SUCCESS
  })
  const failure = (error) => ({
    error,
    type: integrationsConstants.FETCH_REPOSITORIES_FAILURE
  })

  return (dispatch) => {
    dispatch(request())

    integrationsService.fetchRepositories(platform)
      .then((repositories) => dispatch(success(repositories)))
      .catch((error) => dispatch(failure(error)))
  }
}

const update = (id, token) => {
  const request = () => ({
    type: integrationsConstants.UPDATE_INTEGRATION_REQUEST
  })
  const success = (integration) => ({
    integration,
    type: integrationsConstants.UPDATE_INTEGRATION_SUCCESS
  })
  const failure = (error) => ({
    error,
    type: integrationsConstants.UPDATE_INTEGRATION_FAILURE
  })

  return (dispatch) => {
    dispatch(request())

    integrationsService.update(id, token)
      .then((integration) => dispatch(success(integration)))
      .catch((error) => dispatch(failure(error)))
  }
}

export default {
  activate,
  checkPaths,
  fetchAll,
  fetchBranches,
  fetchRepositories,
  update
}
