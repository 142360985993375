import React from 'react'
import { connect } from 'react-redux'
import {
  Button,
  Form,
  FormFeedback,
  FormGroup,
  FormText,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from 'reactstrap'
import PropTypes from 'prop-types'
import { v1 as uuidv1 } from 'uuid'

import AlertMessage from '../../components/AlertMessage'
import FormInputField from '../../components/FormInputField'
import PageLoader from '../../components/PageLoader'
import i18n from '../../config/i18n'
import rolesActions from '../../roles/actions'
import usersActions from './actions'

class UserModal extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      email: '',
      id: null,
      role: '',
      showRoleRequired: false
    }
  }

  componentDidMount() {
    const { dispatch, fetchingStatus } = this.props

    if (fetchingStatus !== 'succeeded') dispatch(rolesActions.fetchAll())
  }

  componentDidUpdate(prevProps) {
    const {
      id,
      invitationStatus,
      modalIsOpen,
      updateStatus
    } = this.props

    const {
      invitationStatus: prevInvitationStatus,
      modalIsOpen: prevModalIsOpen,
      updateStatus: prevUpdateStatus
    } = prevProps

    const { id: idFromState } = this.state

    if (prevModalIsOpen === false && modalIsOpen === true) {
      if (idFromState === null && id) this.updateUserId()
    }

    if (prevInvitationStatus === 'inviting') {
      if (invitationStatus === 'failed') {
        this.showErrorMessage()
      } else {
        this.onToggle()
      }
    }

    if (prevUpdateStatus === 'updating' && updateStatus === 'succeeded') {
      this.onToggle()
    }
  }

  handleSubmit(event) {
    const { dispatch } = this.props

    const { email, id, role } = this.state

    event.preventDefault()

    if (email === '') {
      this.emailField.showAsInvalid()
    }

    if (role === '') {
      this.setState({
        showRoleRequired: true
      })
    }

    if (email !== '' && role !== '') {
      if (id === null) {
        dispatch(usersActions.create(email, role))
      } else {
        dispatch(usersActions.update(id, role))
      }
    }
  }

  onToggle() {
    const { onToggle } = this.props

    onToggle()

    this.setState({
      email: '',
      id: null,
      role: ''
    })
  }

  setEmail({ target: { value } }) {
    this.setState({
      email: value
    })
  }

  setRole(role) {
    this.setState({
      role,
      showRoleRequired: false
    })
  }

  showErrorMessage() {
    const { invitationError, updateError } = this.props

    if (invitationError && invitationError.email) {
      this.emailField.showAsInvalid(invitationError.email[0])
    }

    if (invitationError && invitationError.role) {
      this.roleField.showAsInvalid(invitationError.role[0])
    }

    if (updateError && updateError.role) {
      this.roleField.showAsInvalid(invitationError.role[0])
    }
  }

  updateUserId() {
    const { id, users } = this.props

    const user = users.find((item) => item.id === id)

    this.setState({
      email: user.email,
      id,
      invitation: user.invitation,
      role: user.role
    })
  }

  render() {
    const {
      currentUserRole,
      fetchingError,
      fetchingStatus,
      modalIsOpen,
      roles
    } = this.props

    const {
      email,
      id,
      invitation,
      role,
      showRoleRequired
    } = this.state

    const i18nBase = 'UsersPage.UserModal'

    return (
      <Modal isOpen={modalIsOpen} toggle={() => this.onToggle()}>
        <Form>
          <ModalHeader toggle={() => this.onToggle()}>
            {i18n.t(`${i18nBase}.titles.${id ? 'edit' : 'invite'}`)}
          </ModalHeader>
          <ModalBody>
            {(['idle', 'fetching'].includes(fetchingStatus)) && (
              <PageLoader message={i18n.t(`${i18nBase}.fetchingRoles`)} />
            )}

            {fetchingStatus === 'failed' && (
              <AlertMessage>{fetchingError}</AlertMessage>
            )}

            {fetchingStatus === 'succeeded' && (
              <>
                <FormInputField
                  disabled={id !== null} // Linguiini doesn't support changing email address yet.
                  inputId="email"
                  inputName="email"
                  inputType="email"
                  labelText={
                    invitation
                      ? i18n.t(`${i18nBase}.fields.invitation.email`)
                      : i18n.t(`${i18nBase}.fields.email`)
                  }
                  onChange={(event) => this.setEmail(event)}
                  ref={(component) => { this.emailField = component }}
                  value={email}
                />
                <FormGroup className="position-relative">
                  <Label for="roles">{i18n.t(`${i18nBase}.roles`)}</Label>
                  <Input
                    id="roles"
                    invalid={showRoleRequired}
                    disabled={currentUserRole !== 'admin'}
                    name="roles"
                    onChange={({ target: { value } }) => this.setRole(value)}
                    type="select"
                    ref={(component) => { this.roleField = component }}
                    value={role}
                  >
                    {id === null && (
                      <option>{i18n.t(`${i18nBase}.pleaseSelectRole`)}</option>
                    )}

                    {roles.map((item) => (
                      <option
                        key={uuidv1()}
                        value={item}
                      >
                        {item}
                      </option>
                    ))}
                  </Input>
                  {currentUserRole !== 'admin' && (
                    <FormText>{i18n.t('warnings.requiresAdminRole')}</FormText>
                  )}
                  {showRoleRequired && (
                    <FormFeedback tooltip>
                      {i18n.t('errors.required_field')}
                    </FormFeedback>
                  )}
                </FormGroup>
              </>
            )}
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => this.onToggle()}>
              {i18n.t('buttons.cancel')}
            </Button>
            <Button
              color="primary"
              onClick={(event) => this.handleSubmit(event)}
              type="submit"
            >
              {i18n.t(`${id ? '' : `${i18nBase}.`}buttons.${id ? 'save' : 'invite'}`)}
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    )
  }
}

UserModal.propTypes = {
  currentUserRole: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  fetchingError: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  fetchingStatus: PropTypes.string,
  id: PropTypes.string,
  invitationError: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  invitationStatus: PropTypes.string,
  modalIsOpen: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  roles: PropTypes.arrayOf(PropTypes.string),
  users: PropTypes.arrayOf(PropTypes.shape({
    email: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    invitation: PropTypes.bool.isRequired
  })),
  updateError: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  updateStatus: PropTypes.string
}

UserModal.defaultProps = {
  currentUserRole: null,
  fetchingError: null,
  fetchingStatus: 'idle',
  id: null,
  invitationError: null,
  invitationStatus: 'idle',
  roles: null,
  updateError: null,
  updateStatus: 'idle',
  users: null
}

const mapStateToProps = ({
  authentication: {
    role: currentUserRole
  },
  roles: {
    fetchingError,
    fetchingStatus,
    roles
  },
  users: {
    items: users,
    invitationError,
    invitationStatus,
    updateError,
    updateStatus
  }
}) => ({
  currentUserRole,
  fetchingError,
  fetchingStatus,
  invitationError,
  invitationStatus,
  roles,
  updateError,
  updateStatus,
  users
})

export default connect(mapStateToProps)(UserModal)
