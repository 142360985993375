import projectLanguagesConstants from './constants'
import projectLanguagesServices from './services'

const create = (projectId, locale) => {
  const request = () => ({ type: projectLanguagesConstants.CREATE_REQUEST })
  const success = (language) => ({
    language,
    type: projectLanguagesConstants.CREATE_SUCCESS
  })
  const failure = (error) => ({
    error,
    type: projectLanguagesConstants.CREATE_FAILURE
  })

  return (dispatch) => {
    dispatch(request())

    projectLanguagesServices.create(projectId, locale)
      .then((language) => dispatch(success(language)))
      .catch((error) => dispatch(failure(error)))
  }
}

const fetch = (id) => {
  const request = () => ({ type: projectLanguagesConstants.FETCH_REQUEST })
  const success = (language) => ({
    language,
    type: projectLanguagesConstants.FETCH_SUCCESS
  })
  const failure = (error) => ({
    error,
    type: projectLanguagesConstants.FETCH_FAILURE
  })

  return (dispatch) => {
    dispatch(request())

    projectLanguagesServices.fetch(id)
      .then((language) => dispatch(success(language)))
      .catch((error) => dispatch(failure(error)))
  }
}

const fetchAll = (id) => {
  const request = () => ({ type: projectLanguagesConstants.FETCH_ALL_REQUEST })
  const success = (languages) => ({
    languages,
    type: projectLanguagesConstants.FETCH_ALL_SUCCESS
  })
  const failure = (error) => ({
    error,
    type: projectLanguagesConstants.FETCH_ALL_FAILURE
  })

  return (dispatch) => {
    dispatch(request())

    projectLanguagesServices.fetchAll(id)
      .then((languages) => dispatch(success(languages)))
      .catch((error) => dispatch(failure(error)))
  }
}

export default {
  create,
  fetch,
  fetchAll
}
