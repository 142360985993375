import React, { useState } from 'react'
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledTooltip
} from 'reactstrap'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import i18n from '../../../config/i18n'

const Menu = ({
  onClickDelete,
  onClickEdit,
  onClickForceImport,
  role,
  state
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const toggle = () => setIsOpen(!isOpen)

  return (
    <Dropdown
      isOpen={isOpen}
      name="menu"
      toggle={toggle}
    >
      <DropdownToggle nav className="p-0">
        <div className="vertical-triple-dots" />
      </DropdownToggle>
      <DropdownMenu right>
        <DropdownItem
          disabled={['pending', 'importing'].includes(state)}
          name="edit"
          onClick={() => onClickEdit()}
        >
          {i18n.t('buttons.edit')}
        </DropdownItem>
        <DropdownItem
          disabled={['pending', 'importing'].includes(state)}
          className="text-danger"
          name="remove"
          onClick={() => onClickDelete()}
        >
          {i18n.t('buttons.remove')}
        </DropdownItem>

        {role === 'admin' && (
          <>
            <DropdownItem divider />
            <DropdownItem
              disabled={['pending', 'importing'].includes(state)}
              name="force_import"
              id="force_import"
              onClick={() => onClickForceImport()}
            >
              {i18n.t('buttons.force_import')}
            </DropdownItem>
            <UncontrolledTooltip placement="right" target="force_import">
              {i18n.t('ProjectsPage.Menu.tooltip.forceImport')}
            </UncontrolledTooltip>
          </>
        )}
      </DropdownMenu>
    </Dropdown>
  )
}

Menu.propTypes = {
  onClickDelete: PropTypes.func.isRequired,
  onClickEdit: PropTypes.func.isRequired,
  onClickForceImport: PropTypes.func.isRequired,
  role: PropTypes.string.isRequired,
  state: PropTypes.string
}

Menu.defaultProps = {
  state: null
}

const mapStateToProps = ({
  authentication: {
    role
  }
}) => ({
  role
})

export default connect(mapStateToProps)(Menu)
