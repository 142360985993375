/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import React from 'react'
import * as ActionCable from '@rails/actioncable'
import { PersistGate } from 'redux-persist/integration/react'
import { Provider } from 'react-redux'
import { render } from 'react-dom'
import RestfulClient from 'restful-json-api-client'

// Bootstrap
// eslint-disable-next-line no-unused-vars
import $ from 'jquery'
import 'popper.js/dist/popper'
import 'bootstrap/dist/js/bootstrap'
import '../stylesheets/application.scss'

import AppRouter from '../src/AppRouter'
import configureStore from '../src/config/stores'

import 'moment/min/moment-with-locales'

const { persistor, store } = configureStore()

RestfulClient.configure({
  headers: {
    'X-CSRF-Token': () => document.querySelector('[name="csrf-token"]')?.content
  },
  requestCredentials: 'same-origin'
})

ActionCable.logger.enabled = process.env.NODE_ENV === 'development'

document.addEventListener('DOMContentLoaded', () => (
  render(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <AppRouter />
      </PersistGate>
    </Provider>,
    document.getElementById('app')
  )
))
