import React, { useState } from 'react'
import { connect } from 'react-redux'
import {
  Badge,
  Button,
  Container,
  Table,
  Tooltip
} from 'reactstrap'
import { Link } from 'react-router-dom'
import moment from 'moment'
import PropTypes from 'prop-types'
import { v1 as uuidv1 } from 'uuid'

import AlertMessage from '../../../components/AlertMessage'
import ProjectExportCreationConfirmationModal from './ProjectExportCreationConfirmationModal'
import history from '../../../helpers/History'
import i18n from '../../../config/i18n'
import PageLoader from '../../../components/PageLoader'
import projectExportsActions from './actions'
import TablePagination from '../../../components/TablePagination'

const ProjectExports = ({
  dispatch,
  fetchingAllError,
  fetchingAllStatus,
  paginationCurrentPage,
  paginationTotalPages,
  projectExports,
  projectId
}) => {
  const [exportNowModalIsOpen, setExportNowModalIsOpen] = useState(false)
  const [page, setPage] = useState(null)
  const [tooltipOpen, setTooltipOpen] = useState(false)

  if (fetchingAllStatus === 'idle') {
    dispatch(projectExportsActions.fetchAll(projectId, page))
  }

  const moveToPage = (selected) => {
    setPage(selected)
    dispatch(projectExportsActions.fetchAll(projectId, selected))
  }

  const stateBadgeColor = (state) => {
    switch (state) {
      case 'pending': return 'warning'
      case 'exporting': return 'primary'
      case 'exporting_failed': return 'danger'
      case 'exporting_succeeded': return 'success'
      default: return 'secondary'
    }
  }

  const toggle = () => setTooltipOpen(!tooltipOpen)

  const i18nBase = 'ProjectsPage.ProjectExports.Index'

  return (
    <Container fluid className="mt-3">
      <div className="d-flex flex-column">
        <div className="d-flex flex-row justify-content-between">
          <h3>{i18n.t(`${i18nBase}.title`)}</h3>
          <div className="d-flex flex-column">
            <Button
              color="success"
              id="export-now-button"
              onClick={() => setExportNowModalIsOpen(true)}
              outline
            >
              {i18n.t('buttons.export')}
            </Button>
            <Tooltip
              isOpen={tooltipOpen}
              placement="bottom"
              target="export-now-button"
              toggle={toggle}
            >
              {i18n.t(`${i18nBase}.exportButtonTooltip`)}
            </Tooltip>
          </div>
        </div>

        <ProjectExportCreationConfirmationModal
          isOpen={exportNowModalIsOpen}
          onClose={(createdProjectExportId) => {
            setExportNowModalIsOpen(false)
            if (createdProjectExportId) {
              history.push(`/projects/${projectId}/exports/${createdProjectExportId}`)
            }
          }}
          projectId={projectId}
          pushAllStrings
        />

        <hr className="w-100" />
      </div>

      <div className="d-flex flex-column flex-fill justify-content-center">
        {['idle', 'fetching'].includes(fetchingAllStatus) && (
          <div className="d-flex flex-row justify-content-center">
            <PageLoader message={i18n.t(`${i18nBase}.statuses.fetching`)} />
          </div>
        )}

        {fetchingAllStatus === 'failed' && (
          <AlertMessage>
            {fetchingAllError || i18n.t('errors.somethingWentWrong')}
          </AlertMessage>
        )}

        {fetchingAllStatus === 'succeeded'
          && projectExports.length === 0
          && (
            <div className="d-flex flex-row justify-content-center my-5">
              <p>{i18n.t(`${i18nBase}.messages.empty`)}</p>
            </div>
          )}

        {fetchingAllStatus === 'succeeded'
          && projectExports.length > 0
          && (
            <div className="d-flex flex-column">
              <Table>
                <thead>
                  <tr>
                    <th>{i18n.t(`${i18nBase}.tableColumns.when`)}</th>
                    <th>{i18n.t(`${i18nBase}.tableColumns.state`)}</th>
                    <th aria-label="Actions" />
                  </tr>
                </thead>
                <tbody>
                  {projectExports.map((projectExport) => (
                    <tr key={uuidv1()}>
                      <td>{moment(projectExport.created_at).format('LLL')}</td>
                      <td>
                        <Badge
                          color={stateBadgeColor(projectExport.state)}
                        >
                          {projectExport.state}
                        </Badge>
                      </td>
                      <td>
                        <Link
                          className={`btn btn-outline-secondary
                                      text-decoration-none float-right`}
                          to={
                            `/projects/${projectId}/exports/${projectExport.id}`
                          }
                        >
                          {i18n.t(`${i18nBase}.buttons.open`)}
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>

              <div className="d-flex flex-row justify-content-center">
                <TablePagination
                  currentPage={paginationCurrentPage}
                  onPaginationClick={(selected) => moveToPage(selected)}
                  totalPages={paginationTotalPages}
                />
              </div>
            </div>
          )}
      </div>
    </Container>
  )
}

ProjectExports.propTypes = {
  dispatch: PropTypes.func.isRequired,
  fetchingAllError: PropTypes.string,
  fetchingAllStatus: PropTypes.string,
  paginationCurrentPage: PropTypes.number,
  paginationTotalPages: PropTypes.number,
  projectExports: PropTypes.arrayOf(PropTypes.shape({
    created_at: PropTypes.string.isRequired
  })),
  projectId: PropTypes.string
}

ProjectExports.defaultProps = {
  fetchingAllError: null,
  fetchingAllStatus: 'idle',
  paginationCurrentPage: null,
  paginationTotalPages: null,
  projectExports: null,
  projectId: null
}

const mapStateToProps = ({
  projectExports: {
    fetchingAllError,
    fetchingAllStatus,
    items: projectExports,
    itemsCurrentPage: paginationCurrentPage,
    itemsTotalPages: paginationTotalPages
  }
}) => ({
  fetchingAllError,
  fetchingAllStatus,
  paginationCurrentPage,
  paginationTotalPages,
  projectExports
})

export default connect(mapStateToProps)(ProjectExports)
