import translationsConstants from './constants'
import translationsService from './services'

const fetchAll = (attributes) => {
  const request = () => ({ type: translationsConstants.FETCH_ALL_REQUEST })
  const success = (translations) => ({
    type: translationsConstants.FETCH_ALL_SUCCESS,
    translations
  })
  const failure = (error) => ({
    error,
    type: translationsConstants.FETCH_ALL_FAILURE
  })

  return (dispatch) => {
    dispatch(request())

    translationsService.fetchAll(attributes)
      .then((translations) => dispatch(success(translations)))
      .catch((error) => dispatch(failure(error)))
  }
}

const filter = (attributes) => {
  const request = () => ({ type: translationsConstants.FILTER_REQUEST })
  const success = (translations) => ({
    type: translationsConstants.FILTER_SUCCESS,
    translations
  })
  const failure = (error) => ({
    error,
    type: translationsConstants.FILTER_FAILURE
  })

  return (dispatch) => {
    dispatch(request())

    translationsService.fetchAll(attributes)
      .then((translations) => dispatch(success(translations)))
      .catch((error) => dispatch(failure(error)))
  }
}

const update = (hash, language, projectId) => {
  const request = () => ({ type: translationsConstants.UPDATE_REQUEST })
  const success = (updatedStrings) => ({
    type: translationsConstants.UPDATE_SUCCESS,
    updatedStrings
  })
  const failure = (error) => ({
    error,
    type: translationsConstants.UPDATE_FAILURE
  })

  return (dispatch) => {
    dispatch(request())

    translationsService.update(hash, language, projectId)
      .then((updatedStrings) => dispatch(success(updatedStrings)))
      .catch((error) => dispatch(failure(error)))
  }
}

export default {
  fetchAll,
  filter,
  update
}
