import React, { useState } from 'react'
import {
  Button,
  Form,
  Input
} from 'reactstrap'
import PropTypes from 'prop-types'
import Select from 'react-select'

import FilterHelpers from '../helpers/FilterHelpers'
import i18n from '../config/i18n'

const TableFiler = ({
  filterColumn,
  filterQuery,
  onColumnChange,
  onQueryChange,
  onSubmit
}) => {
  const [showPressEnter, setShowPressEnter] = useState(false)

  return (
    <div className="d-flex flex-row flex-fill">
      <div className="d-flex flex-column mr-2">
        <Select
          id="selected-filter"
          name="selectedFilter"
          onChange={(selected) => onColumnChange(selected)}
          options={FilterHelpers.filterOptions}
          styles={{
            control: (css) => ({
              ...css,
              width: 250
            })
          }}
          value={filterColumn}
        />
      </div>

      <div className="d-flex flex-column flex-fill">
        <Form
          onSubmit={(event) => {
            event.preventDefault()
            setShowPressEnter(false)
            onSubmit()
          }}
        >
          <div className="position-relative">
            <Input
              name="filterByText"
              onChange={(event) => {
                setShowPressEnter(true)
                onQueryChange(event)
              }}
              placeholder={i18n.t('TableFilter.inputPlaceholder')}
              value={filterQuery || ''}
            />

            {showPressEnter && (
              <Button
                className="bg-dark rounded text-white px-2 shadow-sm"
                onClick={() => {
                  setShowPressEnter(false)
                  onSubmit()
                }}
                style={{
                  padding: '2px 0',
                  position: 'absolute',
                  top: '4px',
                  right: '5px'
                }}
              >
                {i18n.t('TableFilter.pressEnter')}
              </Button>
            )}
          </div>
        </Form>
      </div>
    </div>
  )
}

TableFiler.propTypes = {
  filterColumn: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired
  }),
  filterQuery: PropTypes.string,
  onColumnChange: PropTypes.func.isRequired,
  onQueryChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
}

TableFiler.defaultProps = {
  filterColumn: null,
  filterQuery: null
}

export default TableFiler
