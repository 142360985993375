import dotProp from 'dot-prop-immutable'

import localesConstants from './constants'

const initialState = {
  codeCheckingError: null,
  codeCheckingStatus: 'idle',

  creatingError: null,
  creatingStatus: '',

  fetchingError: null,
  fetchingStatus: 'idle',

  removeError: null,
  removeStatus: 'idle',

  items: null,

  updatingError: null,
  updatingStatus: ''
}

export default (state = initialState, action) => {
  const error = action.error ? (action.error.data || action.error.message) : null
  const sorted = (items) => items.sort((a, b) => a.name.localeCompare(b.name))
  let newState

  switch (action.type) {
    case localesConstants.CHECK_CODE_AVAILABILITY_REQUEST:
      newState = dotProp.set(state, 'codeCheckingError', null)
      return dotProp.set(newState, 'codeCheckingStatus', 'checking')
    case localesConstants.CHECK_CODE_AVAILABILITY_FAILURE:
      newState = dotProp.set(state, 'codeCheckingError', error)
      return dotProp.set(newState, 'codeCheckingStatus', 'failed')
    case localesConstants.CHECK_CODE_AVAILABILITY_SUCCESS:
      newState = dotProp.set(state, 'codeCheckingError', null)
      return dotProp.set(newState, 'codeCheckingStatus', 'succeeded')

    case localesConstants.CREATE_REQUEST:
      newState = dotProp.set(state, 'creatingError', null)
      return dotProp.set(newState, 'creatingStatus', 'creating')
    case localesConstants.CREATE_FAILURE:
      newState = dotProp.set(state, 'creatingError', error)
      return dotProp.set(newState, 'creatingStatus', 'failed')
    case localesConstants.CREATE_SUCCESS:
      newState = dotProp.set(state, 'creatingError', null)
      newState = dotProp.set(newState, 'creatingStatus', 'succeeded')
      return dotProp.set(
        newState,
        'items',
        sorted([...state.items, action.locale])
      )

    case localesConstants.FETCH_ALL_REQUEST:
      newState = dotProp.set(state, 'fetchingError', null)
      return dotProp.set(newState, 'fetchingStatus', 'fetching')
    case localesConstants.FETCH_ALL_FAILURE:
      newState = dotProp.set(state, 'fetchingError', error)
      return dotProp.set(newState, 'fetchingStatus', 'failed')
    case localesConstants.FETCH_ALL_SUCCESS:
      newState = dotProp.set(state, 'fetchingError', null)
      newState = dotProp.set(newState, 'fetchingStatus', 'succeeded')
      return dotProp.set(newState, 'items', action.locales)

    case localesConstants.REMOVE_REQUEST:
      newState = dotProp.set(state, 'removingError', null)
      return dotProp.set(newState, 'removingStatus', 'removing')
    case localesConstants.REMOVE_FAILURE:
      newState = dotProp.set(state, 'removingError', error)
      return dotProp.set(newState, 'removingStatus', 'failed')
    case localesConstants.REMOVE_SUCCESS: {
      const itemIndex = state.items.findIndex(
        (item) => item.id === action.id
      )
      newState = dotProp.delete(state, `items.${itemIndex}`)
      newState = dotProp.set(newState, 'removingError', null)
      return dotProp.set(newState, 'removingStatus', 'succeeded')
    }

    case localesConstants.UPDATE_REQUEST:
      newState = dotProp.set(state, 'updatingError', null)
      return dotProp.set(newState, 'updatingStatus', 'updating')
    case localesConstants.UPDATE_FAILURE:
      newState = dotProp.set(state, 'updatingError', error)
      return dotProp.set(newState, 'updatingStatus', 'failed')
    case localesConstants.UPDATE_SUCCESS: {
      newState = dotProp.set(state, 'updatingError', null)
      newState = dotProp.set(newState, 'updatingStatus', 'succeeded')

      const { id, name, value } = action.locale
      const index = state.items.findIndex((item) => item.id === id)

      newState = dotProp.set(newState, `items.${index}.name`, name)
      return dotProp.set(newState, `items.${index}.value`, value)
    }

    default:
      return state
  }
}
