import ApiUtils from '../../helpers/ApiUtils'
import ProjectsApi from '../../apis/ProjectsApi'

const checkNameAvailablity = (name, excludeProjectId) => (
  new ProjectsApi().checkNameAvailablity(name, excludeProjectId).then(
    ApiUtils.handleResponse
  )
)

const create = (project) => (
  new ProjectsApi().create(project).then(ApiUtils.handleResponse)
)

const enqueueStatsRefresh = (slug) => (
  new ProjectsApi().enqueueStatsRefresh(slug).then(ApiUtils.handleResponse)
)

const fetch = (slug) => (
  new ProjectsApi().get({ id: slug }).then(ApiUtils.handleResponse)
)

const fetchAll = () => (
  new ProjectsApi().all().then(ApiUtils.handleResponse)
)

const forceImport = (id, allFiles, gitOptions) => (
  new ProjectsApi().forceImport(id, allFiles, gitOptions).then(
    ApiUtils.handleResponse
  )
)

const fixesAlert = (projectId, selectedIds, locale) => (
  new ProjectsApi().fixesAlert(
    projectId,
    selectedIds,
    locale
  ).then(ApiUtils.handleResponse)
)

const remove = (id) => (
  new ProjectsApi().destroy(id).then(ApiUtils.handleResponse)
)

const update = (id, locales, name, paths) => (
  new ProjectsApi().update(id, {
    locales,
    name,
    paths
  }).then(ApiUtils.handleResponse)
)

export default {
  checkNameAvailablity,
  create,
  enqueueStatsRefresh,
  fetch,
  fetchAll,
  fixesAlert,
  forceImport,
  remove,
  update
}
