import dotProp from 'dot-prop-immutable'

import deviseConstants from '../constants'

const initialState = {
  acceptingError: null,
  acceptingStatus: 'idle'
}

export default (state = initialState, action) => {
  const error = action.error ? (action.error.data || action.error.message) : null
  let newState

  switch (action.type) {
    case deviseConstants.INVITATION_ACCEPT_REQUEST:
      newState = dotProp.set(state, 'acceptingError', null)
      return dotProp.set(newState, 'acceptingStatus', 'accepting')
    case deviseConstants.INVITATION_ACCEPT_SUCCESS: {
      newState = dotProp.set(state, 'acceptingError', null)
      return dotProp.set(newState, 'acceptingStatus', 'succeeded')
    }
    case deviseConstants.INVITATION_ACCEPT_FAILURE: {
      newState = dotProp.set(state, 'acceptingError', error)
      return dotProp.set(newState, 'acceptingStatus', 'failed')
    }
    default:
      return state
  }
}
