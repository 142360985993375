import React from 'react'
import { Spinner } from 'reactstrap'

import i18n from '../config/i18n'

const SearchingOverlay = () => (
  <div
    className="d-flex flex-row bg-dark text-white lead justify-content-center align-items-center"
    style={{
      opacity: '50%',
      position: 'absolute',
      top: 0,
      height: '100%',
      width: '100%'
    }}
  >
    <Spinner className="mr-3" />
    {i18n.t('SearchingOverlay.searching')}
  </div>
)

export default SearchingOverlay
