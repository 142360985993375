import React from 'react'
import {
  Button,
  PopoverHeader,
  UncontrolledPopover
} from 'reactstrap'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import i18n from '../../../config/i18n'
import projectUnusedStringsActions from './actions'

const ProjectStringToRemoveRow = ({
  destroyingError,
  destroyingId,
  destroyingStatus,
  dispatch,
  project,
  projectString: {
    id,
    key,
    locale,
    path,
    value
  }
}) => {
  const onRemoveClick = () => {
    dispatch(
      projectUnusedStringsActions.destroy(project.id, locale, id)
    )
  }

  const i18nBase = 'ProjectsPage.ProjectUnusedStrings.ProjectStringToRemoveRow'

  return (
    <tr>
      <td>{key}</td>
      <td>{path}</td>
      <td>{locale}</td>
      <td>{value}</td>
      <td>
        <Button
          color="danger"
          id={`unused-string-delete-${id}`}
          onClick={() => onRemoveClick()}
          type="submit"
        >
          {i18n.t('buttons.remove')}
        </Button>

        <UncontrolledPopover
          isOpen={destroyingStatus === 'failed' && destroyingId === id}
          placement="left"
          target={`unused-string-delete-${id}`}
          trigger="legacy"
        >
          <PopoverHeader className="border-0 bg-danger text-white">
            {i18n.t(`${i18nBase}.errorPopover.title`)}
            &nbsp;:&nbsp;
            {destroyingError}
          </PopoverHeader>
        </UncontrolledPopover>
      </td>
    </tr>
  )
}

ProjectStringToRemoveRow.propTypes = {
  destroyingError: PropTypes.string,
  destroyingId: PropTypes.string,
  destroyingStatus: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  project: PropTypes.shape({
    id: PropTypes.string.isRequired
  }),
  projectString: PropTypes.shape({
    key: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    locale: PropTypes.string,
    path: PropTypes.string.isRequired,
    value: PropTypes.string
  }).isRequired
}

ProjectStringToRemoveRow.defaultProps = {
  destroyingError: null,
  destroyingId: null,
  destroyingStatus: 'idle',
  project: null
}

const mapStateToProps = ({
  projects: {
    item: project
  },
  projectUnusedStrings: {
    destroyingError,
    destroyingId,
    destroyingStatus
  }
}) => ({
  destroyingError,
  destroyingId,
  destroyingStatus,
  project
})

export default connect(mapStateToProps)(ProjectStringToRemoveRow)
