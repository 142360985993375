import React from 'react'
import PropTypes from 'prop-types'

const StatusPendingIcon = ({ size }) => (
  <svg
    fill="rgb(240, 176, 73)"
    height={`${size}em`}
    width={`${size}em`}
    viewBox="0 0 14 14"
  >
    <g fillRule="evenodd">
      <path d="M0 7a7 7 0 1 1 14 0A7 7 0 0 1 0 7z" />
      <path d="M13 7A6 6 0 1 0 1 7a6 6 0 0 0 12 0z" fill="var(--svg-status-bg, #fff)" />
      <path d="M4.7 5.3c0-.2.1-.3.3-.3h.9c.2 0 .3.1.3.3v3.4c0 .2-.1.3-.3.3H5c-.2 0-.3-.1-.3-.3V5.3m3 0c0-.2.1-.3.3-.3h.9c.2 0 .3.1.3.3v3.4c0 .2-.1.3-.3.3H8c-.2 0-.3-.1-.3-.3V5.3" />
    </g>
  </svg>
)

StatusPendingIcon.propTypes = {
  size: PropTypes.number
}

StatusPendingIcon.defaultProps = {
  size: 1
}

export default StatusPendingIcon
