import React, { useState } from 'react'
import {
  Badge,
  Button,
  List,
  Popover,
  PopoverHeader,
  PopoverBody,
  Spinner
} from 'reactstrap'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { v1 as uuidv1 } from 'uuid'

import i18n from '../../../config/i18n'
import projectStringsAlertsActions from '../ProjectStringsAlerts/actions'

const ProjectStringAlertRow = ({
  checkedInList,
  dispatch,
  keepingError,
  keepingStatus,
  projectSlug,
  projectString: {
    conflict,
    duplicated,
    id,
    key,
    locale,
    new_value: newValue,
    relative_path: relativePath,
    value
  },
  rowNumber,
  toggleProjectString
}) => {
  const [isBusy, setIsBusy] = useState(false)
  const [isOpen, setIsOpen] = useState(true)
  const [popoverOpen, setPopoverOpen] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)

  const onAcceptClicked = () => {
    setIsBusy(true)
    dispatch(projectStringsAlertsActions.accept(projectSlug, id))
  }

  const onKeepClicked = () => {
    setIsBusy(true)
    dispatch(projectStringsAlertsActions.keep(projectSlug, id))
  }

  const onRejectClicked = () => {
    setIsBusy(true)
    dispatch(projectStringsAlertsActions.reject(projectSlug, id))
  }

  const toggle = () => {
    toggleProjectString(id)
    setIsOpen(!isOpen)
  }

  const hideKeepPopover = () => setPopoverOpen(false)

  const i18nBase = 'ProjectsPage.ImportIssues.ProjectStringAlertRow'

  if (keepingStatus === 'succeeded' && isBusy) setIsBusy(false)

  if (keepingStatus === 'failed' && isBusy) {
    setIsBusy(false)
    setErrorMessage(typeof keepingError === 'string'
      ? keepingError
      : i18n.t('errors.somethingWentWrong'))
    setPopoverOpen(true)
  }

  const EmptyValueComponent = (
    <i className="text-muted">{i18n.t('global.empty')}</i>
  )

  const SpacedValueComponent = (
    <i className="text-muted">{i18n.t('global.space')}</i>
  )

  const NullValueComponent = (
    <i className="text-muted">{i18n.t('global.null')}</i>
  )

  const UnknownValueComponent = (
    <i className="text-muted">{i18n.t('global.unknown')}</i>
  )

  const displayValueContentFrom = (stringValue) => {
    if (Array.isArray(stringValue)) {
      return (
        <List type="unstyled">
          {stringValue.map((item) => <li key={uuidv1()}>{item}</li>)}
        </List>
      )
    }

    if (typeof stringValue === 'string' && stringValue !== null) {
      if (stringValue.match(/^\s+$/)) return SpacedValueComponent

      return stringValue || EmptyValueComponent
    }

    if (typeof stringValue === 'number') return stringValue.toString()

    if (typeof stringValue === 'boolean') return stringValue.toString()

    if (stringValue === null) return NullValueComponent

    return UnknownValueComponent
  }

  return (
    <tr>
      <td>
        <input
          name="select-alert"
          type="checkbox"
          checked={checkedInList}
          onChange={() => toggle()}
        />
      </td>
      <td>
        {duplicated === false && conflict && i18n.t(`${i18nBase}.conflict`)}
        {duplicated && i18n.t(`${i18nBase}.duplicated`)}
        {duplicated === false && locale === '' && i18n.t(`${i18nBase}.unknowLocale`)}
      </td>
      <td>
        <div className="d-flex flex-column">
          {key}
          <small className="text-muted">{relativePath}</small>
        </div>
      </td>
      <td>
        {displayValueContentFrom(value)}
      </td>
      <td>
        {displayValueContentFrom(newValue)}
      </td>
      <td>
        <Badge color="info">
          {locale}
        </Badge>
      </td>
      <td>
        {duplicated === false && conflict && (
          <div className="d-flex flex-row justify-content-center">
            {isBusy ? (
              <Spinner />
            ) : (
              <>
                <Button
                  className="mr-1"
                  color="success"
                  onClick={() => onAcceptClicked()}
                  outline
                >
                  {i18n.t(`${i18nBase}.buttons.accept`)}
                </Button>
                <Button
                  className="ml-1"
                  color="danger"
                  onClick={() => onRejectClicked()}
                  outline
                >
                  {i18n.t(`${i18nBase}.buttons.reject`)}
                </Button>
              </>
            )}
          </div>
        )}

        {duplicated && (
          <div
            className="d-flex flex-row justify-content-center"
            id={`keep-button-${rowNumber}`}
          >
            {isBusy ? (
              <Spinner />
            ) : (
              <div className="d-flex flex-column justify-content-center align-items-center">
                <Button
                  color="success"
                  onClick={() => onKeepClicked()}
                  outline
                >
                  {i18n.t(`${i18nBase}.buttons.keep`)}
                </Button>
              </div>
            )}
            <Popover
              isOpen={popoverOpen}
              placement="bottom"
              target={`keep-button-${rowNumber}`}
              trigger="legacy"
              toggle={hideKeepPopover}
            >
              <PopoverHeader className="bg-danger text-white border-0">
                {i18n.t(`${i18nBase}.keepErrorTitle`)}
              </PopoverHeader>
              <PopoverBody className="bg-danger text-white">
                {errorMessage}
              </PopoverBody>
            </Popover>
          </div>
        )}
      </td>
    </tr>
  )
}

ProjectStringAlertRow.propTypes = {
  checkedInList: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  keepingError: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  keepingStatus: PropTypes.string,
  projectSlug: PropTypes.string.isRequired,
  projectString: PropTypes.shape({
    conflict: PropTypes.bool.isRequired,
    duplicated: PropTypes.bool.isRequired,
    key: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    locale: PropTypes.string,
    new_value: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.bool,
      PropTypes.number,
      PropTypes.string
    ]),
    relative_path: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.bool,
      PropTypes.number,
      PropTypes.string
    ])
  }).isRequired,
  rowNumber: PropTypes.number.isRequired,
  toggleProjectString: PropTypes.func.isRequired
}

ProjectStringAlertRow.defaultProps = {
  keepingError: null,
  keepingStatus: 'idle'
}

const mapStateToProps = ({
  projectStringsAlerts: {
    keepingError,
    keepingStatus
  }
}) => ({
  keepingError,
  keepingStatus
})

export default connect(mapStateToProps)(ProjectStringAlertRow)
