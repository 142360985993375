import React from 'react'
import { connect } from 'react-redux'
import { Spinner } from 'reactstrap'
import PropTypes from 'prop-types'

import AlertMessage from '../../../components/AlertMessage'
import i18n from '../../../config/i18n'
import importsActions from '../../../integrations/actions'

class Step3 extends React.Component {
  componentDidMount() {
    const {
      dispatch,
      paths,
      platform,
      repository
    } = this.props

    dispatch(importsActions.checkPaths(platform, repository.value, paths))
  }

  render() {
    const {
      checkingPathsError,
      checkingPathsStatus,
      creationError,
      creationStatus
    } = this.props

    const i18nBase = 'ProjectsPage.ProjectCreationModal.Step3'

    return (
      <div className="d-flex flex-row justify-content-center align-items-center my-3">
        {checkingPathsStatus === 'checking' && (
          <>
            <Spinner className="text-secondary" />
            <span className="ml-3">{i18n.t(`${i18nBase}.checkingSourceCode`)}</span>
          </>
        )}

        {checkingPathsStatus === 'failed' && (
          <AlertMessage>
            {typeof checkingPathsError === 'string'
              ? checkingPathsError
              : i18n.t(`${i18nBase}.errors.pathsCheckFailed`)}
          </AlertMessage>
        )}

        {creationStatus === 'creating' && (
          <>
            <Spinner className="text-secondary" />
            <span className="ml-3">{i18n.t(`${i18nBase}.creatingProject`)}</span>
          </>
        )}

        {creationStatus === 'failed' && (
          <AlertMessage>
            {typeof creationError === 'string'
              ? creationError
              : i18n.t(`${i18nBase}.errors.projectCreationFailed`)}
          </AlertMessage>
        )}
      </div>
    )
  }
}

Step3.propTypes = {
  checkingPathsError: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  checkingPathsStatus: PropTypes.string,
  creationError: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  creationStatus: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  paths: PropTypes.string.isRequired,
  platform: PropTypes.string.isRequired,
  repository: PropTypes.shape({
    value: PropTypes.number
  })
}

Step3.defaultProps = {
  checkingPathsError: null,
  checkingPathsStatus: 'idle',
  creationError: null,
  creationStatus: 'idle',
  repository: null
}

const mapStateToProps = ({
  integrations: {
    checkingPathsError,
    checkingPathsStatus
  },
  projects: {
    creationError,
    creationStatus
  }
}) => ({
  checkingPathsError,
  checkingPathsStatus,
  creationError,
  creationStatus
})

export default connect(mapStateToProps)(Step3)
