import React from 'react'
import { connect } from 'react-redux'
import {
  Button,
  Form,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from 'reactstrap'
import PropTypes from 'prop-types'

import AlertMessage from '../../components/AlertMessage'
import PageLoader from '../../components/PageLoader'
import i18n from '../../config/i18n'
import projectsActions from './actions'

class ProjectDeleteConfirmModal extends React.Component {
  componentDidUpdate(prevProps) {
    const { onClose, removingStatus } = this.props
    const { removingStatus: prevRemovingStatus } = prevProps

    if (prevRemovingStatus === 'removing' && removingStatus === 'succeeded') {
      onClose()
    }
  }

  handleSubmit(event) {
    const { id, dispatch } = this.props

    event.preventDefault()

    dispatch(projectsActions.remove(id))
  }

  render() {
    const {
      removingError,
      removingStatus,
      isOpen,
      onClose
    } = this.props

    const i18nBase = 'ProjectsPage.ProjectDeleteConfirmModal'

    return (
      <Modal isOpen={isOpen} toggle={() => onClose()}>
        <Form>
          <ModalHeader toggle={() => onClose()}>
            {i18n.t(`${i18nBase}.title`)}
          </ModalHeader>
          <ModalBody>
            {i18n.t(`${i18nBase}.message`)}

            {(removingStatus === 'removing') && (
              <PageLoader message={i18n.t(`${i18nBase}.removing`)} />
            )}

            {removingStatus === 'failed' && (
              <AlertMessage>{removingError}</AlertMessage>
            )}
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => onClose()}>
              {i18n.t('buttons.cancel')}
            </Button>
            <Button
              color="danger"
              onClick={(event) => this.handleSubmit(event)}
              type="submit"
            >
              {i18n.t('buttons.confirm')}
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    )
  }
}

ProjectDeleteConfirmModal.propTypes = {
  dispatch: PropTypes.func.isRequired,
  id: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  removingError: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  removingStatus: PropTypes.string
}

ProjectDeleteConfirmModal.defaultProps = {
  id: null,
  removingError: null,
  removingStatus: 'idle'
}

const mapStateToProps = ({
  projects: {
    removingError,
    removingStatus
  }
}) => ({
  removingError,
  removingStatus
})

export default connect(mapStateToProps)(ProjectDeleteConfirmModal)
