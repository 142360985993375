import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import i18n from '../config/i18n'
import PageLoader from './PageLoader'
import projectActions from '../PrivatePages/ProjectsPage/actions'

const PageHeader = ({
  dispatch,
  fetchingStatus,
  project,
  projectSlug,
  title,
  withoutHr
}) => {
  if (fetchingStatus === 'idle') {
    dispatch(projectActions.fetch(projectSlug))
  }

  if (['idle', 'fetching'].includes(fetchingStatus)) {
    return (
      <PageLoader message={i18n.t('ProjectsPage.fetching')} />
    )
  }

  return (
    <>
      {fetchingStatus === 'succeeded' && (
        <>
          <div className="d-flex flex-column">
            <h3 className="d-flex flex-row align-items-center mb-0">
              {project.name}
              <small className="ml-3 text-muted">
                {title}
              </small>
            </h3>
          </div>
          <Link to={`/projects/${project.id}`}>
            {i18n.t('ProjectsPage.links.back')}
          </Link>
        </>
      )}

      {withoutHr === false && (<hr className="w-100 mt-2" />)}
    </>
  )
}

PageHeader.propTypes = {
  dispatch: PropTypes.func.isRequired,
  fetchingStatus: PropTypes.string,
  project: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  }),
  projectSlug: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  withoutHr: PropTypes.bool
}

PageHeader.defaultProps = {
  fetchingStatus: 'idle',
  project: null,
  withoutHr: false
}

const mapStateToProps = ({
  projects: {
    fetchingStatus,
    item: project
  }
}) => ({
  fetchingStatus,
  project
})

export default connect(mapStateToProps)(PageHeader)
