import dotProp from 'dot-prop-immutable'

import deviseConstants from '../constants'

const initialState = {
  email: null,
  loggingError: null,
  loggingStatus: 'idle',
  role: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case deviseConstants.LOGIN_REQUEST:
      return dotProp.set(state, 'loggingStatus', 'logging')
    case deviseConstants.LOGIN_SUCCESS: {
      let newState = dotProp.set(state, 'email', action.user.email)
      newState = dotProp.set(newState, 'role', action.user.role)
      return dotProp.set(newState, 'loggingStatus', 'succeeded')
    }
    case deviseConstants.LOGIN_FAILURE: {
      const newState = dotProp.set(
        state,
        'loggingError',
        action.error.message
      )
      return dotProp.set(newState, 'loggingStatus', 'failed')
    }
    case deviseConstants.LOGOUT_SUCCESS:
    case deviseConstants.RESET_LOGGING:
      return initialState
    default:
      return state
  }
}
