import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import moment from 'moment'

import resources from '../../locales'

// Keep the language on moment.js in sync with i18next
// by listening to the change language event.
i18n.on('languageChanged', (newlocale) => moment.locale(newlocale))

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    defaultNS: 'translations',
    detection: {
      order: ['querystring', 'cookie', 'localStorage', 'navigator']
    },
    lookupQuerystring: 'lng',
    caches: ['localStorage', 'cookie'],
    debug: process.env.NODE_ENV === 'development',
    resources,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
      format: (value, format) => {
        if (value instanceof Date) return moment(value).format(format)
        return value
      }
    },
    react: {
      wait: true
    }
  })

export default i18n
