import React, { useState } from 'react'
import { Badge, Button, Container } from 'reactstrap'
import { connect } from 'react-redux'
import { createConsumer } from '@rails/actioncable'
import PropTypes from 'prop-types'

import i18n from '../../../config/i18n'
import PageLoader from '../../../components/PageLoader'
import projectsActions from '../actions'
import ProjectStatsChannel from '../../../ActionCable/channels/ProjectStatsChannel'
import ReferenceLanguages from '../../../components/ReferenceLanguages'

const Header = ({
  dispatch,
  locales,
  project,
  refreshingStatsStatus,
  role
}) => {
  const [mountProjectStatsChannel, setMountProjectStatsChannel] = useState(false)
  const [statsUpdateStatus, setStatsUpdateStatus] = useState('idle')

  const {
    name,
    reference_languages: referenceLanguages,
    strings_stats: stringsStats
  } = project

  const projectLocales = locales.filter(
    (locale) => referenceLanguages.includes(locale.value)
  )

  const enqueueProjectStatsRefresh = () => {
    setStatsUpdateStatus('refreshing')
    setMountProjectStatsChannel(true)
    dispatch(projectsActions.enqueueStatsRefresh(project.id))
  }

  if (mountProjectStatsChannel && refreshingStatsStatus === 'succeeded') {
    setStatsUpdateStatus('idle')
    setMountProjectStatsChannel(false)
  }

  const i18nBase = 'ProjectsPage.ProjectPage.Header'

  return (
    <Container fluid className="mt-3">
      <div className="d-flex flex-row justify-content-between">
        <div className="d-flex flex-column">
          <h3 className="d-flex flex-row align-items-center mb-0">
            {name}
            <div className="ml-4 fs-70 text-muted">
              <ReferenceLanguages
                badgesColor="secondary"
                locales={projectLocales}
                withoutLabel
              />
            </div>
          </h3>

          {role === 'admin' && (
            <div className="d-flex flex-row text-muted mt-2">
              <Badge
                className={`d-flex flex-row align-items-center
                            badge-outline-${parseInt(stringsStats.conflicts, 10) > 0 ? 'warning' : 'success'}`}
              >
                <strong>conflicts:</strong>
                &nbsp;
                {stringsStats.conflicts}
              </Badge>

              <Badge
                className={`d-flex flex-row align-items-center ml-2
                            badge-outline-${parseInt(stringsStats.duplicates, 10) > 0 ? 'warning' : 'success'}`}
              >
                <strong>duplicates:</strong>
                &nbsp;
                {stringsStats.duplicates}
              </Badge>

              <Badge
                className={`d-flex flex-row align-items-center ml-2
                            badge-outline-${parseInt(stringsStats.missing_locale_strings, 10) > 0 ? 'warning' : 'success'}`}
              >
                <strong>missing_locale_strings:</strong>
                &nbsp;
                {stringsStats.missing_locale_strings}
              </Badge>

              <Badge
                className="d-flex flex-row align-items-center ml-2 badge-outline-info"
              >
                <strong>translated:</strong>
                &nbsp;
                {stringsStats.translated}
              </Badge>

              <Badge
                className="d-flex flex-row align-items-center ml-2 badge-outline-info"
              >
                <strong>untranslated:</strong>
                &nbsp;
                {stringsStats.untranslated}
              </Badge>

              {statsUpdateStatus === 'idle' && (
                <Button
                  className="p-0 ml-2 font-italic border-0"
                  color="link"
                  onClick={() => enqueueProjectStatsRefresh()}
                  title={i18n.t(`${i18nBase}.refreshStatsLinkTitle`)}
                >
                  {i18n.t('buttons.refresh')}
                </Button>
              )}

              {statsUpdateStatus === 'refreshing' && (
                <PageLoader
                  className="ml-3 font-italic"
                  message={i18n.t(`${i18nBase}.refreshingStats`)}
                  size="sm"
                />
              )}
            </div>
          )}
        </div>
      </div>
      <hr className="w-100" />

      {mountProjectStatsChannel && (
        <ProjectStatsChannel
          consumer={createConsumer(undefined)}
          id={project.id}
        />
      )}
    </Container>
  )
}

Header.propTypes = {
  dispatch: PropTypes.func.isRequired,
  locales: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired
  })).isRequired,
  project: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    reference_languages: PropTypes.arrayOf(
      PropTypes.string.isRequired
    ).isRequired,
    strings_stats: PropTypes.shape({
      conflicts: PropTypes.number,
      duplicates: PropTypes.number,
      missing_locale_strings: PropTypes.number,
      translated: PropTypes.number,
      untranslated: PropTypes.number
    }).isRequired
  }).isRequired,
  refreshingStatsStatus: PropTypes.string,
  role: PropTypes.string.isRequired
}

Header.defaultProps = {
  refreshingStatsStatus: 'idle'
}

const mapStateToProps = ({
  authentication: { role },
  projects: {
    refreshingStatsStatus
  }
}) => ({
  refreshingStatsStatus,
  role
})

export default connect(mapStateToProps)(Header)
