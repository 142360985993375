import React from 'react'
import { Card, CardBody } from 'reactstrap'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import Gitlab from '../../svgs/Gitlab'
import GitlabIntegrationModal from './GitlabIntegrationModal'
import GitlabIntegrationForm from './GitlabIntegrationForm'
import i18n from '../../config/i18n'
import IntegrationBadge from '../../components/IntegrationBadge'
import integrationsActions from '../../integrations/actions'
import Menu from './Menu'

class Integrations extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      openModal: false
    }
  }

  hideEditIntegrationModal() {
    this.setState({
      openModal: false
    })
  }

  showEditIntegrationModal() {
    this.setState({
      openModal: true
    })
  }

  render() {
    const {
      dispatch,
      fetchingStatus,
      integrations,
      role
    } = this.props

    const { openModal } = this.state

    if (fetchingStatus === 'idle'
        || (fetchingStatus === 'failed' && integrations === null)) {
      dispatch(integrationsActions.fetchAll())
    }

    const gitlabIntegration = integrations && integrations.find(
      (integration) => integration.platform === 'gitlab'
    )

    return (
      <section
        className="d-flex flex-column"
        name="integrations"
      >
        <header>
          <h3 className="ml-2">
            {i18n.t('SettingsPage.Integrations.sections.integrations')}
          </h3>
        </header>

        <section
          className="d-flex"
          name="gitlab"
        >
          <Card style={{ width: '400px' }}>
            <CardBody>
              <header className="d-flex flex-row justify-content-between mb-2">
                <div className="d-flex flex-row">
                  <Gitlab size={2.8} />
                  <h4 className="ml-2">
                    {i18n.t('SettingsPage.Integrations.sections.gitlab')}
                  </h4>
                  {role === 'admin' && gitlabIntegration && (
                    <Menu
                      onClickEdit={() => this.showEditIntegrationModal()}
                    />
                  )}
                </div>
                {gitlabIntegration && (
                  <div className="d-flex flex-column justify-content-center">
                    <IntegrationBadge state={gitlabIntegration.state} />
                  </div>
                )}
              </header>
              <hr />
              <section name="gitlab">
                {gitlabIntegration ? (
                  <GitlabIntegrationModal
                    integration={gitlabIntegration}
                    isOpen={openModal}
                    onClose={() => this.hideEditIntegrationModal()}
                  />
                ) : (
                  <GitlabIntegrationForm />
                )}
              </section>
            </CardBody>
          </Card>
        </section>
      </section>
    )
  }
}

Integrations.propTypes = {
  dispatch: PropTypes.func.isRequired,
  fetchingStatus: PropTypes.string,
  integrations: PropTypes.arrayOf(PropTypes.shape({
    platform: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired
  })),
  role: PropTypes.string.isRequired
}

Integrations.defaultProps = {
  fetchingStatus: 'idle',
  integrations: null
}

const mapStateToProps = ({
  authentication: {
    role
  },
  integrations: {
    fetchingStatus,
    items: integrations
  }
}) => ({
  fetchingStatus,
  integrations,
  role
})

export default connect(mapStateToProps)(Integrations)
