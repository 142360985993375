import projectLanguageStatsConstants from './constants'
import projectLanguageStatsServices from './services'

const fetch = (projectId, locale) => {
  const request = () => ({
    locale,
    projectId,
    type: projectLanguageStatsConstants.FETCH_REQUEST
  })
  const success = (stats) => ({
    projectId,
    locale,
    type: projectLanguageStatsConstants.FETCH_SUCCESS,
    stats
  })
  const failure = (error) => ({
    error,
    type: projectLanguageStatsConstants.FETCH_FAILURE
  })

  return (dispatch) => {
    dispatch(request())

    projectLanguageStatsServices.fetch(projectId, locale)
      .then((stats) => dispatch(success(stats)))
      .catch((error) => dispatch(failure(error)))
  }
}

export default {
  fetch
}
