import React from 'react'
import { Alert, Container } from 'reactstrap'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

import AlertMessage from '../../../components/AlertMessage'
import i18n from '../../../config/i18n'
import projectExportsActions from '../ProjectExports/actions'

const ProjectExportBanner = ({
  dispatch,
  checkingError,
  checkingStatus,
  hasAny,
  project,
  projectExports,
  projectExportsFetchingError,
  projectExportsFetchingStatus
}) => {
  if (checkingStatus === 'idle') {
    dispatch(projectExportsActions.checkIfAny(project.id))
  }

  if (projectExportsFetchingStatus === 'idle') {
    dispatch(projectExportsActions.fetchAll(project.id))
  }

  const i18nBase = 'ProjectsPage.ProjectPage.ProjectExportBanner'

  let existingProjectExport

  if (projectExportsFetchingStatus === 'succeeded') {
    existingProjectExport = (projectExports || []).find(
      (item) => ['pending', 'exporting'].includes(item.state)
    )
  }

  if (checkingStatus === 'failed'
      || projectExportsFetchingStatus === 'failed') {
    return (
      <Container className="mt-2">
        {checkingStatus === 'failed' && (
          <AlertMessage>
            {typeof checkingError === 'string'
              ? checkingError
              : i18n.t('errors.somethingWentWrong')}
          </AlertMessage>
        )}

        {projectExportsFetchingStatus === 'failed' && (
          <AlertMessage>
            {projectExportsFetchingError || i18n.t('errors.somethingWentWrong')}
          </AlertMessage>
        )}
      </Container>
    )
  }

  if (checkingStatus === 'succeeded'
      && projectExportsFetchingStatus === 'succeeded'
      && hasAny
      && [null, undefined].includes(existingProjectExport)
  ) {
    return (
      <Alert className="border-0" color="warning">
        <div className="d-flex flex-row justify-content-between">
          <div className="d-flex flex-column justify-content-center">
            <span>{i18n.t(`${i18nBase}.text`)}</span>
          </div>
          <Link
            className="btn btn-warning text-decoration-none px-5"
            to={`/projects/${project.id}/exports/new`}
          >
            {i18n.t(`${i18nBase}.buttons.checkDiff`)}
          </Link>
        </div>
      </Alert>
    )
  }

  return null
}

ProjectExportBanner.propTypes = {
  checkingError: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string
  ]),
  checkingStatus: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  hasAny: PropTypes.bool,
  project: PropTypes.shape({
    id: PropTypes.string.isRequired
  }),
  projectExports: PropTypes.arrayOf(PropTypes.shape({
    state: PropTypes.string.isRequired
  })),
  projectExportsFetchingError: PropTypes.string,
  projectExportsFetchingStatus: PropTypes.string
}

ProjectExportBanner.defaultProps = {
  checkingError: null,
  checkingStatus: 'idle',
  hasAny: false,
  project: null,
  projectExports: null,
  projectExportsFetchingError: null,
  projectExportsFetchingStatus: 'idle'
}

const mapStateToProps = ({
  projectExports: {
    checkingError,
    checkingStatus,
    fetchingAllError: projectExportsFetchingError,
    fetchingAllStatus: projectExportsFetchingStatus,
    hasAny,
    items: projectExports
  }
}) => ({
  checkingError,
  checkingStatus,
  hasAny,
  projectExports,
  projectExportsFetchingError,
  projectExportsFetchingStatus
})

export default connect(mapStateToProps)(ProjectExportBanner)
