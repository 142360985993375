import localesConstants from './constants'
import localesService from './services'

const checkCodeAvailablity = (code) => {
  const request = () => ({
    type: localesConstants.CHECK_CODE_AVAILABILITY_REQUEST
  })
  const success = () => ({
    type: localesConstants.CHECK_CODE_AVAILABILITY_SUCCESS
  })
  const failure = (error) => ({
    error,
    type: localesConstants.CHECK_CODE_AVAILABILITY_FAILURE
  })

  return (dispatch) => {
    dispatch(request())

    localesService.checkCodeAvailablity(code)
      .then(() => dispatch(success()))
      .catch((error) => dispatch(failure(error)))
  }
}

const create = (attributes) => {
  const request = () => ({ type: localesConstants.CREATE_REQUEST })
  const success = (locale) => ({
    locale,
    type: localesConstants.CREATE_SUCCESS
  })
  const failure = (error) => ({
    error,
    type: localesConstants.CREATE_FAILURE
  })

  return (dispatch) => {
    dispatch(request())

    localesService.create(attributes)
      .then((locale) => dispatch(success(locale)))
      .catch((error) => dispatch(failure(error)))
  }
}

const fetchAll = () => {
  const request = () => ({ type: localesConstants.FETCH_ALL_REQUEST })
  const success = (locales) => ({
    locales,
    type: localesConstants.FETCH_ALL_SUCCESS
  })
  const failure = (error) => ({
    error,
    type: localesConstants.FETCH_ALL_FAILURE
  })

  return (dispatch) => {
    dispatch(request())

    localesService.fetchAll()
      .then((locales) => dispatch(success(locales)))
      .catch((error) => dispatch(failure(error)))
  }
}

const remove = (id) => {
  const request = () => ({ id, type: localesConstants.REMOVE_REQUEST })
  const success = () => ({ id, type: localesConstants.REMOVE_SUCCESS })
  const failure = (error) => ({
    error,
    type: localesConstants.REMOVE_FAILURE
  })

  return (dispatch) => {
    dispatch(request())

    localesService.remove(id)
      .then(() => dispatch(success()))
      .catch((error) => dispatch(failure(error)))
  }
}

const update = (id, attributes) => {
  const request = () => ({ type: localesConstants.UPDATE_REQUEST })
  const success = (locale) => ({
    locale,
    type: localesConstants.UPDATE_SUCCESS
  })
  const failure = (error) => ({
    error,
    type: localesConstants.UPDATE_FAILURE
  })

  return (dispatch) => {
    dispatch(request())

    localesService.update(id, attributes)
      .then((locale) => dispatch(success(locale)))
      .catch((error) => dispatch(failure(error)))
  }
}

export default {
  checkCodeAvailablity,
  create,
  fetchAll,
  remove,
  update
}
