import dotProp from 'dot-prop-immutable'

import projectUnusedStringsConstants from './constants'

const initialState = {
  checkingError: null,
  checkingStatus: 'idle',

  count: null, // Amount of ProjectStrings with an alert

  destroyingError: null,
  destroyingId: null,
  destroyingStatus: null,

  fetchingError: null,
  fetchingStatus: 'idle',

  // This becomes `true` when there's at least one ProjectStrings with an alert
  hasAny: false,

  items: null,
  itemsCount: null,
  // Pagination current page number
  itemsCurrentPage: null,
  // Pagination total page count
  itemsTotalPages: null
}

const sorted = (items) => items.sort((a, b) => a.locale.localeCompare(b.locale))

export default (state = initialState, action) => {
  const error = action.error ? (action.error.data || action.error.message) : null
  let newState

  switch (action.type) {
    case projectUnusedStringsConstants.CHECK_IF_ANY_REQUEST:
      newState = dotProp.set(state, 'checkingError', null)
      return dotProp.set(newState, 'checkingStatus', 'checking')
    case projectUnusedStringsConstants.CHECK_IF_ANY_FAILURE:
      newState = dotProp.set(state, 'checkingError', error)
      return dotProp.set(newState, 'checkingStatus', 'failed')
    case projectUnusedStringsConstants.CHECK_IF_ANY_SUCCESS:
      newState = dotProp.set(state, 'checkingError', null)
      newState = dotProp.set(newState, 'checkingStatus', 'succeeded')
      newState = dotProp.set(newState, 'count', action.data.count)
      return dotProp.set(newState, 'hasAny', action.data.has_any)

    case projectUnusedStringsConstants.DESTROY_REQUEST:
      newState = dotProp.set(state, 'destroyingError', null)
      newState = dotProp.set(newState, 'destroyingId', action.id)
      return dotProp.set(newState, 'destroyingStatus', 'destroying')
    case projectUnusedStringsConstants.DESTROY_FAILURE:
      newState = dotProp.set(state, 'destroyingError', error)
      return dotProp.set(newState, 'destroyingStatus', 'failed')
    case projectUnusedStringsConstants.DESTROY_SUCCESS: {
      const itemIndex = state.items.findIndex((item) => item.id === action.id)
      if (itemIndex > -1) newState = dotProp.delete(state, `items.${itemIndex}`)

      newState = dotProp.set(newState, 'destroyingError', null)
      newState = dotProp.set(newState, 'destroyingStatus', 'succeeded')

      newState = dotProp.set(
        newState,
        'items',
        sorted(action.data.data)
      )
      newState = dotProp.set(
        newState,
        'itemsCount',
        action.data.count
      )
      newState = dotProp.set(
        newState,
        'itemsCurrentPage',
        action.data.current_page
      )
      return dotProp.set(
        newState,
        'itemsTotalPages',
        action.data.total_pages
      )
    }

    case projectUnusedStringsConstants.FETCH_ALL_REQUEST:
      newState = dotProp.set(state, 'fetchingError', null)
      return dotProp.set(newState, 'fetchingStatus', 'fetching')
    case projectUnusedStringsConstants.FETCH_ALL_FAILURE:
      newState = dotProp.set(state, 'fetchingError', error)
      return dotProp.set(newState, 'fetchingStatus', 'failed')
    case projectUnusedStringsConstants.FETCH_ALL_SUCCESS:
      newState = dotProp.set(state, 'fetchingError', null)
      newState = dotProp.set(newState, 'fetchingStatus', 'succeeded')
      newState = dotProp.set(
        newState,
        'items',
        sorted(action.unusedStrings.data)
      )
      newState = dotProp.set(
        newState,
        'itemsCount',
        action.unusedStrings.count
      )
      newState = dotProp.set(
        newState,
        'itemsCurrentPage',
        action.unusedStrings.current_page
      )
      return dotProp.set(
        newState,
        'itemsTotalPages',
        action.unusedStrings.total_pages
      )

    default:
      return state
  }
}
