import dotProp from 'dot-prop-immutable'

import actionCableConstants from '../../../ActionCable/constants'
import projectExportsConstants from './constants'
import translationsConstants from '../../TranslationsPage/constants'

const initialState = {
  checkingError: null,
  checkingStatus: 'idle',

  creatingError: null,
  creatingStatus: 'idle',

  diffFetchingError: null,
  diffFetchingStatus: 'idle',
  diffItems: null, // The diff items shown in the "New" page

  fetchingAllError: null,
  fetchingAllStatus: 'idle',

  fetchingError: null,
  fetchingStatus: 'idle',

  // This becomes `true` when there's at least one ProjectStrings which has an
  // update
  hasAny: false,

  item: null, // The ProjectExport for the Show page

  items: null, // All the ProjectExport

  itemsCount: null,
  // Pagination current page number
  itemsCurrentPage: null,
  // Pagination total page count
  itemsTotalPages: null,

  retryError: null,
  retryStatus: 'idle'
}

const sorted = (items) => items.sort(
  (a, b) => b.created_at.localeCompare(a.created_at)
)

export default (state = initialState, action) => {
  const error = action.error ? (action.error.data || action.error.message) : null
  let newState

  switch (action.type) {
    case actionCableConstants.DATA_RECEIVED_FROM_CHANNEL: {
      newState = state
      if (action.channelName === 'ProjectExportsChannel') {
        if (state.items) {
          const index = state.items.findIndex((item) => item.id === action.id)

          // Updates ProjectExport from the `items` list
          if (index > -1) {
            newState = dotProp.set(newState, `items.${index}`, action.data)
          }
        }

        // Updates the `item` if user is on the Show page of that ProjectExport
        if (state.item.id === action.id) {
          newState = dotProp.set(newState, 'item', action.data)
        }
      }

      return newState
    }

    case projectExportsConstants.CHECK_IF_ANY_REQUEST:
      newState = dotProp.set(state, 'checkingError', null)
      return dotProp.set(newState, 'checkingStatus', 'checking')
    case projectExportsConstants.CHECK_IF_ANY_FAILURE:
      newState = dotProp.set(state, 'checkingError', error)
      return dotProp.set(newState, 'checkingStatus', 'failed')
    case projectExportsConstants.CHECK_IF_ANY_SUCCESS:
      newState = dotProp.set(state, 'checkingError', null)
      newState = dotProp.set(newState, 'checkingStatus', 'succeeded')
      return dotProp.set(newState, 'hasAny', action.data.has_any)

    case projectExportsConstants.CREATE_REQUEST:
      newState = dotProp.set(state, 'creationError', null)
      newState = dotProp.set(newState, 'creationStatus', 'creating')
      newState = dotProp.set(newState, 'fetchingStatus', 'idle')
      return dotProp.set(newState, 'item', null)
    case projectExportsConstants.CREATE_FAILURE:
      newState = dotProp.set(state, 'creationError', error)
      return dotProp.set(newState, 'creationStatus', 'failed')
    case projectExportsConstants.CREATE_SUCCESS:
      newState = dotProp.set(state, 'creationError', null)
      newState = dotProp.set(newState, 'creationStatus', 'succeeded')
      newState = dotProp.set(newState, 'hasAny', false)
      return dotProp.set(
        newState,
        'items',
        sorted([...state.items || [], action.projectExport])
      )

    case projectExportsConstants.FETCH_REQUEST:
      newState = dotProp.set(state, 'fetchingError', null)
      return dotProp.set(newState, 'fetchingStatus', 'fetching')
    case projectExportsConstants.FETCH_FAILURE:
      newState = dotProp.set(state, 'fetchingError', error)
      return dotProp.set(newState, 'fetchingStatus', 'failed')
    case projectExportsConstants.FETCH_SUCCESS:
      newState = dotProp.set(state, 'fetchingError', null)
      newState = dotProp.set(newState, 'fetchingStatus', 'succeeded')
      return dotProp.set(newState, 'item', action.projectExport)

    case projectExportsConstants.FETCH_ALL_REQUEST:
      newState = dotProp.set(state, 'fetchingAllError', null)
      return dotProp.set(newState, 'fetchingAllStatus', 'fetching')
    case projectExportsConstants.FETCH_ALL_FAILURE:
      newState = dotProp.set(state, 'fetchingAllError', error)
      return dotProp.set(newState, 'fetchingAllStatus', 'failed')
    case projectExportsConstants.FETCH_ALL_SUCCESS:
      newState = dotProp.set(state, 'fetchingAllError', null)
      newState = dotProp.set(newState, 'fetchingAllStatus', 'succeeded')
      newState = dotProp.set(newState, 'items', action.projectExports.data)

      newState = dotProp.set(
        newState,
        'itemsCount',
        action.projectExports.count
      )
      newState = dotProp.set(
        newState,
        'itemsCurrentPage',
        action.projectExports.current_page
      )
      return dotProp.set(
        newState,
        'itemsTotalPages',
        action.projectExports.total_pages
      )

    case projectExportsConstants.FETCH_DIFF_REQUEST:
    case projectExportsConstants.FETCH_EXPORT_DIFF_REQUEST:
      newState = dotProp.set(state, 'diffFetchingError', null)
      return dotProp.set(newState, 'diffFetchingStatus', 'fetching')
    case projectExportsConstants.FETCH_DIFF_FAILURE:
    case projectExportsConstants.FETCH_EXPORT_DIFF_FAILURE:
      newState = dotProp.set(state, 'diffFetchingError', error)
      return dotProp.set(newState, 'diffFetchingStatus', 'failed')
    case projectExportsConstants.FETCH_DIFF_SUCCESS:
    case projectExportsConstants.FETCH_EXPORT_DIFF_SUCCESS: {
      newState = dotProp.set(state, 'diffFetchingError', null)
      newState = dotProp.set(newState, 'diffFetchingStatus', 'succeeded')

      const {
        count,
        current_page: currentPage,
        data,
        total_pages: totalPages
      } = action.diff

      newState = dotProp.set(
        newState,
        'diffItems',
        data.sort((a, b) => a.key.localeCompare(b.key))
      )
      newState = dotProp.set(newState, 'itemsCount', count)
      newState = dotProp.set(newState, 'itemsCurrentPage', currentPage)
      return dotProp.set(newState, 'itemsTotalPages', totalPages)
    }

    case projectExportsConstants.RETRY_REQUEST:
      newState = dotProp.set(state, 'retryError', null)
      return dotProp.set(newState, 'retryStatus', 'retrying')
    case projectExportsConstants.RETRY_FAILURE:
      newState = dotProp.set(state, 'retryError', error)
      return dotProp.set(newState, 'retryStatus', 'failed')
    case projectExportsConstants.RETRY_SUCCESS:
      newState = dotProp.set(state, 'retryError', null)
      return dotProp.set(newState, 'retryStatus', 'succeeded')

    // Resets the checkingError and checkingStatus when updating a translation
    // so that Linguiini checks for ready updates next time the user goes to the
    // project's page.
    case translationsConstants.UPDATE_SUCCESS:
      newState = dotProp.set(state, 'checkingError', null)
      return dotProp.set(newState, 'checkingStatus', 'idle')

    default:
      return state
  }
}
