import deviseConstants from '../constants'
import invitationsService from './service'

const accept = (invitation) => {
  const request = () => ({ type: deviseConstants.INVITATION_ACCEPT_REQUEST })
  const success = () => ({ type: deviseConstants.INVITATION_ACCEPT_SUCCESS })
  const failure = (error) => ({
    error,
    type: deviseConstants.INVITATION_ACCEPT_FAILURE
  })

  return (dispatch) => {
    dispatch(request())

    invitationsService.accept(invitation)
      .then((user) => dispatch(success(user)))
      .catch((error) => dispatch(failure(error)))
  }
}

export default {
  accept
}
