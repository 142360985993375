import React from 'react'
import PropTypes from 'prop-types'

import AppNavbar from './AppNavbar'

const ApplicationLayout = ({ children }) => (
  <div className="d-flex flex-column flex-fill">
    <AppNavbar />
    {children}
  </div>
)

ApplicationLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element
  ]).isRequired
}

export default ApplicationLayout
