import React from 'react'
import PropTypes from 'prop-types'

const PlusIcon = ({ size }) => (
  <svg
    fill="rgba(0, 0, 0, 0.2)"
    height={`${size}em`}
    width={`${size}em`}
    viewBox="0 0 48 48"
  >
    <path d="M24 4C12.95 4 4 12.95 4 24s8.95 20 20 20 20-8.95 20-20S35.05 4 24 4zm10 22h-8v8h-4v-8h-8v-4h8v-8h4v8h8v4z" />
  </svg>
)

PlusIcon.propTypes = {
  size: PropTypes.number
}

PlusIcon.defaultProps = {
  size: 1
}

export default PlusIcon
