import deviseConstants from '../constants'
import passwordService from './services'

const changePassword = (user) => {
  const request = () => ({ type: deviseConstants.CHANGE_PASSWORD_REQUEST })
  const success = () => ({ type: deviseConstants.CHANGE_PASSWORD_SUCCESS })
  const failure = (error) => ({
    error,
    type: deviseConstants.CHANGE_PASSWORD_FAILURE
  })

  return (dispatch) => {
    dispatch(request())

    passwordService.changePassword(user)
      .then(() => dispatch(success()))
      .catch((error) => dispatch(failure(error)))
  }
}

const reset = () => (dispatch) => {
  dispatch({ type: deviseConstants.RESET_NEW_PASSWORD })
}

const sendPasswordInstructions = (email) => {
  const request = () => ({ type: deviseConstants.PASSWORD_RESET_REQUEST })
  const success = () => ({ type: deviseConstants.PASSWORD_RESET_SUCCESS })
  const failure = (error) => ({
    error,
    type: deviseConstants.PASSWORD_RESET_FAILURE
  })

  return (dispatch) => {
    dispatch(request(email))

    passwordService.sendResetPasswordInstructions(email)
      .then(() => dispatch(success()))
      .catch((error) => dispatch(failure(error)))
  }
}

export default {
  changePassword,
  reset,
  sendPasswordInstructions
}
