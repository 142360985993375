import React from 'react'
import { connect } from 'react-redux'
import { Progress } from 'reactstrap'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

import AlertMessage from '../../../components/AlertMessage'
import i18n from '../../../config/i18n'
import PageLoader from '../../../components/PageLoader'
import projectLanguageStatsActions from './ProjectLanguageStats/actions'

const LanguageRow = ({
  dispatch,
  fetchingProjectLanguageStatsError,
  locales,
  language: { locale },
  projectId,
  stats
}) => {
  const statsKey = `${projectId}-${locale}`

  if (stats === null || statsKey in stats === false) {
    dispatch(projectLanguageStatsActions.fetch(projectId, locale))
  }

  const languageLabel = () => {
    const localeObject = locales.find((item) => item.value === locale)

    if (localeObject) return localeObject.label

    return `Unknown (${locale})`
  }

  // Do not show a row for ProjectString without a locale, they are visible from
  // the ImportIssues page.
  if (locale === '') return null

  const i18nBase = 'ProjectsPage.ProjectPage.LanguageRow'

  let translationProgress = null
  let statsTranslateds = null
  let statsTranslations = null

  if (stats && stats[statsKey]?.status === 'succeeded') {
    const { translateds, translations } = stats[`${projectId}-${locale}`]

    statsTranslateds = translateds
    statsTranslations = translations
    translationProgress = ((translateds * 100) / translations)
  }

  return (
    <tr>
      <td>
        <Link to={`/projects/${projectId}/locales/${locale}/project_strings`}>
          {languageLabel()}
        </Link>
      </td>
      <td>
        {(stats === null || ['idle', 'fetching'].includes(stats[statsKey]?.status)) && (
          <PageLoader
            message={i18n.t(`${i18nBase}.fetchingLanguageStats`)}
            size="sm"
          />
        )}

        {stats && stats[statsKey]?.status === 'failed' && (
          <AlertMessage alertClassName="mb-0 py-1 px-2" centered={false}>
            {typeof fetchingProjectLanguageStatsError === 'string'
              ? fetchingProjectLanguageStatsError
              : i18n.t('errors.somethingWentWrong')}
          </AlertMessage>
        )}

        {stats && stats[statsKey]?.status === 'succeeded'
          && translationProgress
          && (
            <div className="d-flex flex-row align-items-center">
              <div className="w-25">
                <Progress value={translationProgress} />
              </div>
              <div className="pl-3">
                {translationProgress.toFixed(2)}
                %&nbsp;
                (
                {statsTranslateds}
                /
                {statsTranslations}
                )
              </div>
            </div>
          )}

        {stats && stats[statsKey]?.status === 'succeeded'
          && translationProgress === null
          && (
            <AlertMessage alertClassName="mb-0 py-1 px-2" centered={false}>
              {i18n.t(`${i18nBase}.errors.noStatsFound`)}
            </AlertMessage>
          )}
      </td>
    </tr>
  )
}

LanguageRow.propTypes = {
  dispatch: PropTypes.func.isRequired,
  fetchingProjectLanguageStatsError: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  locales: PropTypes.arrayOf(PropTypes.object).isRequired,
  language: PropTypes.shape({
    locale: PropTypes.string.isRequired
  }).isRequired,
  projectId: PropTypes.string.isRequired,
  stats: PropTypes.shape()
}

LanguageRow.defaultProps = {
  fetchingProjectLanguageStatsError: null,
  stats: null
}

const mapStateToProps = ({
  projectLanguageStats: {
    fetchingError: fetchingProjectLanguageStatsError,
    items: stats
  }
}) => ({
  fetchingProjectLanguageStatsError,
  stats
})

export default connect(mapStateToProps)(LanguageRow)
