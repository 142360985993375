import React from 'react'
import { connect } from 'react-redux'
import {
  Navbar,
  NavbarBrand
} from 'reactstrap'
import PropTypes from 'prop-types'

import ConnectedUserNavbar from './ConnectedUserNavbar'

const AppNavbar = ({ loggingStatus }) => (
  <Navbar color="light" light expand="md">
    <NavbarBrand href="/">Linguiini</NavbarBrand>
    {loggingStatus === 'succeeded' && (
      <ConnectedUserNavbar />
    )}
  </Navbar>
)

AppNavbar.propTypes = {
  loggingStatus: PropTypes.string
}

AppNavbar.defaultProps = {
  loggingStatus: 'idle'
}

const mapStateToProps = ({
  authentication: {
    loggingStatus
  }
}) => ({
  loggingStatus
})

export default connect(mapStateToProps)(AppNavbar)
