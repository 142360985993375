import ApiUtils from '../../helpers/ApiUtils'
import UserApi from '../../apis/UserApi'

const login = (email, password) => (
  new UserApi().signIn(email, password).then(ApiUtils.handleResponse)
)

const logout = () => (
  new UserApi().logout().then(ApiUtils.handleResponse)
)

export default {
  login,
  logout
}
