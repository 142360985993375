import ApiUtils from '../../helpers/ApiUtils'
import UsersApi from '../../apis/UsersApi'
import UsersInvitationApi from '../../apis/UsersInvitationApi'

const create = (email, role) => (
  new UsersInvitationApi().create({
    user: { email, role }
  }).then(ApiUtils.handleResponse)
)

const fetchAll = () => (
  new UsersApi().all().then(ApiUtils.handleResponse)
)

const remove = (id) => (
  new UsersApi().destroy(id).then(ApiUtils.handleResponse)
)

const update = (id, role) => (
  new UsersApi().update(id, { role }).then(ApiUtils.handleResponse)
)

export default {
  create,
  fetchAll,
  remove,
  update
}
