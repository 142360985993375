export default {
  FETCH_ALL_REQUEST: 'USERS_PAGE_FETCH_ALL_REQUEST',
  FETCH_ALL_SUCCESS: 'USERS_PAGE_FETCH_ALL_SUCCESS',
  FETCH_ALL_FAILURE: 'USERS_PAGE_FETCH_ALL_FAILURE',

  INVITATION_CREATION_REQUEST: 'USERS_PAGE_INVITATION_CREATION_REQUEST',
  INVITATION_CREATION_SUCCESS: 'USERS_PAGE_INVITATION_CREATION_SUCCESS',
  INVITATION_CREATION_FAILURE: 'USERS_PAGE_INVITATION_CREATION_FAILURE',

  REMOVE_REQUEST: 'USERS_PAGE_REMOVE_REQUEST',
  REMOVE_SUCCESS: 'USERS_PAGE_REMOVE_SUCCESS',
  REMOVE_FAILURE: 'USERS_PAGE_REMOVE_FAILURE',

  UPDATE_REQUEST: 'USERS_PAGE_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'USERS_PAGE_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'USERS_PAGE_UPDATE_FAILURE'
}
