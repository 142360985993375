import React from 'react'
import {
  Button,
  Form,
  FormFeedback,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner
} from 'reactstrap'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import Select from 'react-select'

import AlertMessage from '../../../../../components/AlertMessage'
import i18n from '../../../../../config/i18n'
import projectLanguagesActions from '../actions'

class CreationModal extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      // Locale to create to compare with references locales
      locale: null,
      noSelectedLanguage: false
    }
  }

  componentDidUpdate(prevProps) {
    const {
      creationStatus: prevCreationStatus
    } = prevProps

    const {
      creationStatus,
      isOpen
    } = this.props

    if (isOpen === false) return

    if (prevCreationStatus === 'creation' && creationStatus === 'succeeded') {
      this.toggle()
      this.resetLocale()
    }
  }

  onSubmit(event) {
    const {
      dispatch,
      projectId
    } = this.props

    const { locale } = this.state

    event.preventDefault()

    if (locale === null) {
      this.setState({
        noSelectedLanguage: true
      })
    } else {
      dispatch(projectLanguagesActions.create(projectId, locale))
    }
  }

  onLocalesSelect(locale) {
    this.setState({
      locale
    })
  }

  resetLocale() {
    this.setState({
      noSelectedLanguage: false
    })
  }

  toggle() {
    const { toggle } = this.props

    this.setState({
      locale: null
    }, () => toggle())
  }

  render() {
    const {
      creationError,
      creationStatus,
      isOpen,
      locales
    } = this.props

    const {
      locale,
      noSelectedLanguage
    } = this.state

    const noOptionsMessage = () => (
      <p>
        {i18n.t('ProjectsPage.labels.noOptions')}
        <Link
          className="text-secondary font-weight-bold"
          name="create-locales"
          to="/locales"
        >
          {i18n.t('ProjectsPage.labels.noOptionsLink')}
        </Link>
      </p>
    )

    return (
      <Modal isOpen={isOpen} toggle={() => this.toggle()}>
        <Form id="project-language-creation-modal">
          <ModalHeader toggle={() => this.toggle()}>
            {i18n.t('ProjectLanguagesPage.ProjectLanguageCreationModal.title')}
          </ModalHeader>
          <ModalBody className="d-flex flex-column">
            <FormGroup className="position-relative">
              <Label for="locales">
                {i18n.t('ProjectLanguagesPage.ProjectLanguageCreationModal.label')}
              </Label>
              <Select
                id="locales"
                onChange={(selected) => this.onLocalesSelect(selected)}
                openMenuOnFocus
                options={locales}
                noOptionsMessage={() => noOptionsMessage()}
                placeholder={i18n.t('ProjectsPage.fields.localesPlaceholder')}
                value={locale}
              />
              {noSelectedLanguage && (
                <FormFeedback className="d-block z-0" tooltip>
                  {i18n.t('errors.required_field')}
                </FormFeedback>
              )}
              {creationStatus === 'creation' && (
                <>
                  <Spinner className="mt-2 text-secondary" />
                  <span className="ml-3">{i18n.t('ProjectLanguagesPage.ProjectLanguageCreationModal.creationProjectLanguage')}</span>
                </>
              )}

              {creationStatus === 'failed' && (
                <AlertMessage className="mt-2">
                  {typeof creationError === 'string'
                    ? creationError
                    : i18n.t('errors.somethingWentWrong')}
                </AlertMessage>
              )}
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => this.toggle()}>
              {i18n.t('buttons.cancel')}
            </Button>
            <Button
              color="primary"
              onClick={(event) => this.onSubmit(event)}
            >
              {i18n.t('buttons.create')}
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    )
  }
}

CreationModal.propTypes = {
  creationError: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  creationStatus: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  locales: PropTypes.arrayOf(PropTypes.object),
  projectId: PropTypes.string.isRequired,
  toggle: PropTypes.func.isRequired
}

CreationModal.defaultProps = {
  creationError: null,
  creationStatus: 'idle',
  locales: null
}

const mapStateToProps = ({
  locales: {
    items: locales
  },
  projectLanguages: {
    creationError,
    creationStatus
  }
}) => ({
  creationError,
  creationStatus,
  locales
})

export default connect(mapStateToProps)(CreationModal)
