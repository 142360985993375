import React from 'react'
import { createConsumer } from '@rails/actioncable'

import IntegrationsChannel from './channels/IntegrationsChannel'
import ProjectsChannel from './channels/ProjectsChannel'

const ActionCableMounter = () => {
  const consumer = createConsumer()

  return (
    <>
      <IntegrationsChannel consumer={consumer} />
      <ProjectsChannel consumer={consumer} />
    </>
  )
}

export default ActionCableMounter
