import React from 'react'
import PropTypes from 'prop-types'

const StatusSuccessIcon = ({ size }) => (
  <svg
    fill="rgb(102, 212, 151)"
    height={`${size}em`}
    width={`${size}em`}
    viewBox="0 0 14 14"
  >
    <g fillRule="evenodd">
      <path d="M0 7a7 7 0 1 1 14 0A7 7 0 0 1 0 7z" />
      <path d="M13 7A6 6 0 1 0 1 7a6 6 0 0 0 12 0z" fill="var(--svg-status-bg, #fff)" />
      <path d="M6.278 7.697L5.045 6.464a.296.296 0 0 0-.42-.002l-.613.614a.298.298 0 0 0 .002.42l1.91 1.909a.5.5 0 0 0 .703.005l.265-.265L9.997 6.04a.291.291 0 0 0-.009-.408l-.614-.614a.29.29 0 0 0-.408-.009L6.278 7.697z" />
    </g>
  </svg>
)

StatusSuccessIcon.propTypes = {
  size: PropTypes.number
}

StatusSuccessIcon.defaultProps = {
  size: 1
}

export default StatusSuccessIcon
