import React from 'react'
import { connect } from 'react-redux'
import {
  Button,
  FormGroup
} from 'reactstrap'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

import AlertMessage from '../../components/AlertMessage'
import DeviseLayout from '../DeviseLayout'
import EmailField from '../../components/EmailField'
import i18n from '../../config/i18n'
import PageLoader from '../../components/PageLoader'
import PasswordField from '../../components/PasswordField'
import sessionActions from './actions'

class DeviseSessionsNew extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      email: '',
      password: ''
    }
  }

  handleEmailChange({ target: { value } }) {
    this.setState({
      email: value
    })
  }

  handlePasswordChange({ target: { value } }) {
    this.setState({
      password: value
    })
  }

  handleSubmit(event) {
    const { dispatch } = this.props
    const { email, password } = this.state

    event.preventDefault()

    if (email === '') {
      this.emailField.showAsInvalid()
    }

    if (password === '') {
      this.passwordField.showAsInvalid()
    }

    if (email !== '' && password !== '') {
      dispatch(sessionActions.login(email, password))
    }
  }

  render() {
    const {
      loggingError,
      loggingStatus
    } = this.props

    const { email, password } = this.state

    return (
      <DeviseLayout
        id="login-form"
        onSubmit={(event) => this.handleSubmit(event)}
        title={i18n.t('devise.DeviseSessionsNew.title')}
      >
        <EmailField
          inputId="login-email"
          inputName="login-email"
          onChange={(event) => { this.handleEmailChange(event) }}
          ref={(component) => { this.emailField = component }}
          value={email}
        />
        <PasswordField
          inputId="login-password"
          inputName="login-password"
          onChange={(event) => this.handlePasswordChange(event)}
          ref={(component) => { this.passwordField = component }}
          value={password}
        />
        <FormGroup>
          <div className="d-flex flex-column">
            {loggingStatus === 'logging' && (
              <PageLoader
                message={i18n.t('devise.DeviseSessionsNew.connecting')}
              />
            )}
            {loggingStatus === 'failed' && (
              <AlertMessage>
                {loggingError || i18n.t('errors.somethingWentWrong')}
              </AlertMessage>
            )}
            <Button
              className="mb-5"
              color="primary"
            >
              {i18n.t('buttons.login')}
            </Button>
            <Link
              className="mb-3 btn btn-secondary"
              to="/users/password/new"
            >
              {i18n.t('buttons.forgot_password')}
            </Link>
          </div>
        </FormGroup>
      </DeviseLayout>
    )
  }
}

DeviseSessionsNew.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }),
  loggingError: PropTypes.string,
  loggingStatus: PropTypes.string.isRequired
}

DeviseSessionsNew.defaultProps = {
  history: {},
  loggingError: null
}

const mapStateToProps = ({
  authentication: {
    loggingError,
    loggingStatus
  }
}) => ({
  loggingError,
  loggingStatus
})

export default connect(mapStateToProps)(DeviseSessionsNew)
