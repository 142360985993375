import usersConstants from './constants'
import usersService from './services'

const create = (email, role) => {
  const request = () => ({ type: usersConstants.INVITATION_CREATION_REQUEST })
  const success = (invitation) => ({
    invitation,
    type: usersConstants.INVITATION_CREATION_SUCCESS
  })
  const failure = (error) => ({
    error,
    type: usersConstants.INVITATION_CREATION_FAILURE
  })

  return (dispatch) => {
    dispatch(request())

    usersService.create(email, role)
      .then((invitation) => dispatch(success(invitation)))
      .catch((error) => dispatch(failure(error)))
  }
}

const fetchAll = () => {
  const request = () => ({ type: usersConstants.FETCH_ALL_REQUEST })
  const success = (users) => ({
    type: usersConstants.FETCH_ALL_SUCCESS,
    users
  })
  const failure = (error) => ({
    error,
    type: usersConstants.FETCH_ALL_FAILURE
  })

  return (dispatch) => {
    dispatch(request())

    usersService.fetchAll()
      .then((users) => dispatch(success(users)))
      .catch((error) => dispatch(failure(error)))
  }
}

const remove = (id) => {
  const request = () => ({ type: usersConstants.REMOVE_REQUEST })
  const success = (userId) => ({
    type: usersConstants.REMOVE_SUCCESS,
    userId
  })
  const failure = (error) => ({
    error,
    type: usersConstants.REMOVE_FAILURE
  })

  return (dispatch) => {
    dispatch(request())

    usersService.remove(id)
      .then(() => dispatch(success(id)))
      .catch((error) => dispatch(failure(error)))
  }
}

const update = (id, role) => {
  const request = () => ({ type: usersConstants.UPDATE_REQUEST })
  const success = (user) => ({
    type: usersConstants.UPDATE_SUCCESS,
    user
  })
  const failure = (error) => ({
    error,
    type: usersConstants.UPDATE_FAILURE
  })

  return (dispatch) => {
    dispatch(request())

    usersService.update(id, role)
      .then((user) => dispatch(success(user)))
      .catch((error) => dispatch(failure(error)))
  }
}

export default {
  create,
  fetchAll,
  remove,
  update
}
