import { applyMiddleware, createStore } from 'redux'
import { createFilter } from 'redux-persist-transform-filter'
import { createLogger } from 'redux-logger'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import thunkMiddleware from 'redux-thunk'

import reducers from './reducers'

const loggerMiddleware = createLogger({
  // No logger in production
  predicate: () => process.env.NODE_ENV !== 'production'
})

const saveAuthenticationFilteredAttributes = createFilter(
  'authentication',
  [
    'email',
    'loggingStatus',
    'role'
  ]
)

const persistConfig = {
  key: 'root',
  storage,
  transforms: [
    saveAuthenticationFilteredAttributes
  ],
  whitelist: [
    'authentication'
  ]
}

const persistedReducer = persistReducer(persistConfig, reducers)

export default () => {
  const store = createStore(
    persistedReducer,
    applyMiddleware(
      thunkMiddleware,
      loggerMiddleware
    )
  )

  const persistor = persistStore(store)

  if (module.hot) {
    module.hot.accept('./reducers', () => {
      // This fetch the new state of the above reducers.
      // eslint-disable-next-line global-require
      const nextRootReducer = require('./reducers').default
      store.replaceReducer(
        persistReducer(persistConfig, nextRootReducer)
      )
    })
  }

  return { store, persistor }
}
