import React, { useState } from 'react'
import {
  Button,
  Card,
  CardText,
  CardTitle,
  List
} from 'reactstrap'
import Icofont from 'react-icofont'
import { Trans } from 'react-i18next'

import i18n from '../config/i18n'

const localStorageKey = 'releasenoteannouncer'
const version = '0.2.4'

const ReleaseNoteAnnouncer = () => {
  const [showAnnouncer, setShowAnnouncer] = useState(null)

  const i18nBase = 'ReleaseNoteAnnouncer'

  const localStorageValue = localStorage.getItem(localStorageKey)

  if (showAnnouncer === null) setShowAnnouncer(localStorageValue !== version)

  const hideReleaseNoteAnnouncer = () => {
    localStorage.setItem(
      localStorageKey,
      version
    )
    setShowAnnouncer(false)
  }

  if (showAnnouncer === false || process.env.NODE_ENV === 'test') return null

  return (
    <div
      className="position-absolute m-3"
      style={{ bottom: 0, maxWidth: '600px', right: 0 }}
    >
      <Card body inverse color="primary">
        <CardTitle className="mb-3 d-flex flex-row justify-content-between" tag="h4">
          <div className="d-flex align-items-center">
            <span className="mr-3" style={{ fontSize: '2em' }}>📢</span>
            {i18n.t(`${i18nBase}.title`, { version })}
          </div>
          <div className="d-flex align-items-center">
            <Button
              className="bg-primary-on-hover"
              color="link"
              onClick={() => hideReleaseNoteAnnouncer()}
            >
              <Icofont icon="close" />
            </Button>
          </div>
        </CardTitle>
        <CardText>
          <Trans i18nKey={`${i18nBase}.annoncingNewRelease`}>
            <strong>Lucky you!</strong>
            You are running a new version of Linguiini!
            &nbsp;
            Check out the release note bellow.
          </Trans>
        </CardText>
        <List className="mb-4" type="unstyled">
          <li className="h5">Added</li>
          <ul>
            <li>
              Export branch name option allowing to export somewhere else than the&nbsp;
              <code>pharmony-one/export</code>
              &nbsp;branch.
            </li>
            <li>Show code availability checking status when adding a new locale.</li>
            <li>Pre-selecting the git branch when importing a new project.</li>
            <li>This release note announcer! 📣</li>
          </ul>
          <li className="h5 mt-3">Changed</li>
          <ul>
            <li>Reverted to session cookies, a more secure way to managed your sessions.</li>
            <li>Optimized fetching a project language list and translation status bar.</li>
            <li>Makes the user role mandatory when inviting someone.</li>
          </ul>
          <li className="h5 mt-3">Fixed</li>
          <ul>
            <li>
              False string duplicate when locale uses dash like&nbsp;
              <code>fr-FR</code>
              &nbsp;for example.
            </li>
          </ul>
        </List>
        <Button
          color="success"
          onClick={() => hideReleaseNoteAnnouncer()}
        >
          {i18n.t(`${i18nBase}.buttons.close`)}
        </Button>
      </Card>
    </div>
  )
}

export default ReleaseNoteAnnouncer
