import React, { useState } from 'react'
import { Badge, CardSubtitle, Tooltip } from 'reactstrap'
import moment from 'moment'
import PropTypes from 'prop-types'

import i18n from '../../../config/i18n'

const ImportStatus = ({
  failureReason,
  importStats,
  projectId,
  state
}) => {
  const [tooltipOpen, setTooltipOpen] = useState(false)

  const toggle = () => setTooltipOpen(!tooltipOpen)

  const badgeColorFromState = () => {
    switch (state) {
      case 'pending': return 'warning'
      case 'importing': return 'primary'
      case 'importing_succeeded': return 'success'
      case 'importing_failed': return 'danger'
      default: return 'secondary'
    }
  }

  const i18nBase = 'ProjectsPage.ProjectCard.ImportStatus'

  return (
    <CardSubtitle>
      <Badge
        color={badgeColorFromState()}
        id={`project-${projectId}-import-tooltip`}
        value={state}
      >
        {i18n.t(`${i18nBase}.states.${state}`)}
      </Badge>
      {failureReason && (
        <Tooltip
          isOpen={tooltipOpen}
          placement="bottom"
          target={`project-${projectId}-import-tooltip`}
          toggle={toggle}
        >
          {failureReason}
        </Tooltip>
      )}
      {!failureReason && importStats && importStats.duration !== null && (
        <Tooltip
          isOpen={tooltipOpen}
          placement="bottom"
          target={`project-${projectId}-import-tooltip`}
          toggle={toggle}
        >
          {i18n.t(`${i18nBase}.importDuration`, {
            duration: moment.duration(importStats.duration * 1000).humanize()
          })}
        </Tooltip>
      )}
    </CardSubtitle>
  )
}

ImportStatus.propTypes = {
  failureReason: PropTypes.string,
  importStats: PropTypes.shape({
    duration: PropTypes.number
  }),
  projectId: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired
}

ImportStatus.defaultProps = {
  failureReason: null,
  importStats: null
}

export default ImportStatus
