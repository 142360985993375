import React from 'react'
import PropTypes from 'prop-types'

const StatusRunningIcon = ({ size }) => (
  <svg
    fill="rgb(89, 167, 230)"
    height={`${size}em`}
    width={`${size}em`}
    viewBox="0 0 14 14"
  >
    <g fillRule="evenodd">
      <path d="M0 7a7 7 0 1 1 14 0A7 7 0 0 1 0 7z" />
      <path d="M13 7A6 6 0 1 0 1 7a6 6 0 0 0 12 0z" fill="var(--svg-status-bg, #fff)" />
      <path d="M7 3c2.2 0 4 1.8 4 4s-1.8 4-4 4c-1.3 0-2.5-.7-3.3-1.7L7 7V3" />
    </g>
  </svg>
)

StatusRunningIcon.propTypes = {
  size: PropTypes.number
}

StatusRunningIcon.defaultProps = {
  size: 1
}

export default StatusRunningIcon
