import projectUnusedStringsConstants from './constants'
import projectUnusedStringsServices from './services'

const checkIfAny = (slug) => {
  const request = () => ({
    type: projectUnusedStringsConstants.CHECK_IF_ANY_REQUEST
  })
  const success = (data) => ({
    data,
    type: projectUnusedStringsConstants.CHECK_IF_ANY_SUCCESS
  })
  const failure = (error) => ({
    error,
    type: projectUnusedStringsConstants.CHECK_IF_ANY_FAILURE
  })

  return (dispatch) => {
    dispatch(request())

    projectUnusedStringsServices.checkIfAny(slug)
      .then((data) => dispatch(success(data)))
      .catch((error) => dispatch(failure(error)))
  }
}

const destroy = (projectId, language, id) => {
  const request = () => ({
    id,
    type: projectUnusedStringsConstants.DESTROY_REQUEST
  })
  const success = (data) => ({
    data,
    id,
    type: projectUnusedStringsConstants.DESTROY_SUCCESS
  })
  const failure = (error) => ({
    error,
    type: projectUnusedStringsConstants.DESTROY_FAILURE
  })

  return (dispatch) => {
    dispatch(request())

    projectUnusedStringsServices.destroy(projectId, language, id)
      .then((data) => dispatch(success(data)))
      .catch((error) => dispatch(failure(error)))
  }
}

const fetchAll = (slug, page = null) => {
  const request = () => ({
    type: projectUnusedStringsConstants.FETCH_ALL_REQUEST
  })
  const success = (unusedStrings) => ({
    unusedStrings,
    type: projectUnusedStringsConstants.FETCH_ALL_SUCCESS
  })
  const failure = (error) => ({
    error,
    type: projectUnusedStringsConstants.FETCH_ALL_FAILURE
  })

  return (dispatch) => {
    dispatch(request())

    projectUnusedStringsServices.fetchAll(slug, page)
      .then((unusedStrings) => dispatch(success(unusedStrings)))
      .catch((error) => dispatch(failure(error)))
  }
}

export default {
  checkIfAny,
  destroy,
  fetchAll
}
